<template>
	<div class="item">
		<label class="head">
            <div class="title">
                <span>{{item.label}}</span>
                <span v-if="item.required"> *</span>
            </div>
            <div class="selected">
                <template v-for="(item, i) in selectedItems" :key="i">
                    <article class="item" @click="removeFromSelected(i)">
                        <span>{{ item.name }}</span>
                        <span class="remove" />
                    </article>
                </template>
            </div>
		</label>
		<div class="select" v-click-outside="handleOutside">
			<div class="sel" v-if="!down" @click="down = true">
				<span v-if="!Object.keys(selectedItem).length">Select a {{item.label}}</span>
				<div class="selItem" v-else>
					<figure v-if="selectedItem.image">
						<img :src="selectedItem.image" alt="">
					</figure>
					<span>{{selectedItem.name}}</span>
				</div>
			</div>
			<input v-else class="chosen-value" type="text" v-model="searchKey" placeholder="Type to filter">
			<transition name="slide-down">
				<ul class="value-list" v-if="down">
					<li v-if="Object.keys(selectedItem).length && !searchKey.length" @click="handleRemove">
						<span class="rem">Remove Selected</span>
					</li>
					<template v-for="(opt, n) in filteredOptions" :key="n">
						<li @click="handleSelect(opt)">
							<figure v-if="opt.image">
								<img :src="opt.image" alt="">
							</figure>
							<span>{{opt.name}}</span>
						</li>
					</template>
				</ul>
			</transition>
		</div>
	</div>
    <Select v-if="Object.keys(selectedItem).length && item.relate && finalItem.relate.options.length" :item="finalItem.relate" @data="handleData"/>
</template>

<script>
import Select from './Select.vue'
import api from "@/mixins/optionsApi";
	export default {
		props: {
			item: {
				type: Object
			}
		},

		data() {
			return {
				down: false,
				options: [],
				filteredOptions: [],
				searchKey: '',
				selectedItem: {},
                relate_api: this.item.relate.options,
                finalItem: this.item,
                selectedItems: []
			}
		},

        emits: ['data'],

        mixins: [api],

        components: {
            Select
        },

		watch: {
			options(val) {
				this.filteredOptions = val
			},
			searchKey(val) {
				if(val.length) this.filteredOptions = this.options.filter(el => el.name.toLowerCase().includes(val.toLowerCase()))
				else this.filteredOptions = this.options
			},
			selectedItems: {
				deep: true,
				handler(val) {
					this.$emit('data', [this.item.name, val])
				}
			},
            item: {
				deep: true,
				handler() {
                    this.optionsResponse()
                }
            }
		},

		methods: {
			optionsResponse() {
				const isPromise = (promise) => {
					return !!promise && typeof promise.then === "function";
				};

				if (isPromise(this.item.options)) {
					Promise.resolve(this.item.options).then((value) => {
						this.options = value;
					});
				} else this.options = this.item.options;
			},

			handleOutside() {
				this.down = false
			},

			handleSelect(item) {
				this.selectedItem = item
				this.down = false

				setTimeout(() => {
					this.searchKey = ''
				}, 200);

                this.finalItem = {}
                this.finalItem = this.item

                if (this.item.relate) {
                    this.finalItem.relate.options = this.api(this.relate_api, [`${this.item.name}Id`,item.id])

                    const isPromise = (promise) => {
                        return !!promise && typeof promise.then === "function";
                    };

                    if (isPromise(this.finalItem.relate.options)) {
                        Promise.resolve(this.finalItem.relate.options).then((value) => {
                            this.finalItem.relate.options = value;
                        });
                    }
                }
			},

			handleRemove() {
				this.down = false
				this.selectedItem = {}
			},

            handleData(data) {
                if (Object.keys(data[1]).length) {
                    this.selectedItems.push(data[1])
                }
                // console.log(this.selectedItems)
				// this.$emit('data', [data[0], this.selectedItems])
            },

            removeFromSelected(index) {
                this.selectedItems.splice(index, 1)
            },
		},

		mounted() {
			this.optionsResponse()
		}
	}
</script>

<style lang="scss" scoped>
	.slide-down-enter-active {
	animation: slide-down 0.5s;
	}
	.slide-down-leave-active {
	animation: slide-down 0.5s reverse;
	}
	label {
		font-weight: 500;
        font-size: 1rem;
		color: var(--primary-color);
	}

    .head {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .selected {
            display: flex;
            align-items: center;
            max-width: 60%;
            overflow-x: auto;

            article {
                color: $grey;
                font-size: 12px;
                padding: 4px 16px 4px 4px;
                border: solid 1px $grey;
                user-select: none;
                position: relative;
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-left: 8px;

                &:first-of-type {
                    margin-left: 0;
                }

                .remove {
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    right: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &::before, &::after {
                        content: '';
                        position: absolute;
                        width: 8px;
                        height: 1px;
                        background: $red;
                    }

                    &::before {
                        transform: rotate(-45deg);
                    }
                    &::after {
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }

	.select {
		width: 100%;
		display: flex;
		flex-direction: column;
        margin-top: 8px;
		.sel, input {
			font-weight: 500;
			border: solid 1px #cad6e2;
			border-radius: 2px;
			padding: 10px 15px;
			user-select: none;
		}

		.sel {
			cursor: pointer;

			.selItem {
				display: flex;
				align-items: center;
				gap: 10px;
				figure {
					width: 20px;
					height: 20px;
					border-radius: 50%;
					overflow: hidden;
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}

		ul {
			height: 100%;
			max-height: 200px;
			overflow: auto;
			border: solid 1px #cad6e2;
			border-top: none;
            
			li {
				padding: 10px;
				user-select: none;
				cursor: pointer;
				border-bottom: 1px solid #eeeeee;
				display: flex;
				align-items: center;
				gap: 10px;
				figure {
					width: 30px;
					height: 30px;
					border-radius: 50%;
					overflow: hidden;
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						height: 100%;
						object-fit: cover;
					}
				}


				.rem {
					color: $red;
				}
			}
		}
	}

@keyframes slide-down {
  0% {
    opacity: 0;
    height: 0px;
    overflow: hidden;
  }
  100% {
    opacity: 1;
    overflow: hidden;
  }
}

</style>