export default[
    {
        path: "/company-settings",
        component: () => import(/* webpackChunkName: "Inbox" */ "./pages/Index.vue"),
        meta: {
          auth: true,
          layout: 'app-layout',
          organization: true,
          additionalbar : false
        }
    },
]
