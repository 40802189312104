import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

export default{
    namespaced: true,
    state(){
        return{
            id: '',
            avatar: '',
            name: '',
            email: '',
            phone: '',
            language: '',
            user_type: '',
            role: {},
            site: null
        }
    },
    mutations: mutations,
    getters: getters,
    actions: actions,
}