import getters from "./getters"
import mutations from "./mutations"

export default{
    namespaced: true,
    state(){
        return{
            path:[],
        }
    },
    mutations: mutations,
    getters: getters,
}