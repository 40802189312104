export default [
    {
        path: "/organizations",
        component: () => import(/* webpackChunkName: "Organizations-list" */ "./pages/OrganizationsMenu.vue"),
        meta: {
          auth: true,
          layout: 'auth-layout'
        }
    },
    {
      path: "/clients",
      component: () => import(/* webpackChunkName: "Clients-list" */ "./pages/ClientsMenu.vue"),
      meta: {
        auth: true,
        layout: 'auth-layout'
      }
    },
]