export default[

    {
      path: "/data-structure",
      component: () => import(/* webpackChunkName: "Sites" */ "./pages/Users/Index.vue"),
        meta: {
          auth: true,
          layout: 'app-layout',
          organization: true,
          additionalbar : true,
          barlayout: 'main-sidebar',
          main: 'data-structure'
        }
    },
    {
        path: "/data-structure/sites",
        component: () => import(/* webpackChunkName: "Sites" */ "./pages/Sites/Index.vue"),
        meta: {
          auth: true,
          layout: 'app-layout',
          organization: true,
          additionalbar : true,
          barlayout: 'main-sidebar',
          main: 'data-structure'
        }
    },
    {
      path: "/data-structure/users",
      component: () => import(/* webpackChunkName: "Users" */ "./pages/Users/Index.vue"),
      meta: {
        auth: true,
        layout: 'app-layout',
        organization: true,
        additionalbar : true,
        barlayout: 'main-sidebar',
        main: 'data-structure'
      }
  },
  {
    path: "/data-structure/users/create",
    component: () => import(/* webpackChunkName: "Clients" */ "./pages/Users/New/Index.vue"),
    meta: {
      auth: true,
      layout: 'app-layout',
      organization: true,
      additionalbar : false,
      main: 'data-structure'
    }
  },
  {
    path: "/data-structure/users/:userid",
    component: () => import(/* webpackChunkName: "Clients" */ "./pages/Users/Edit/Index.vue"),
    meta: {
      auth: true,
      layout: 'app-layout',
      organization: true,
      additionalbar : false,
      main: 'data-structure'
    }
  },
  {
    path: "/data-structure/clients",
    component: () => import(/* webpackChunkName: "Clients" */ "./pages/Clients/Index.vue"),
    meta: {
      auth: true,
      layout: 'app-layout',
      organization: true,
      additionalbar : true,
      barlayout: 'main-sidebar',
      main: 'data-structure'
    }
  },
  {
    path: "/data-structure/clients/create",
    component: () => import(/* webpackChunkName: "Clients" */ "./pages/Clients/New/Index.vue"),
    meta: {
      auth: true,
      layout: 'app-layout',
      organization: true,
      additionalbar : false,
      main: 'data-structure'
    }
  },
  {
    name:"client",
    path: "/data-structure/client/:clientid",
    component: () => import(/* webpackChunkName: "Clients" */ "./pages/Clients/Info/Index.vue"),
    props: route => ({ client: route.params.client }),
    meta: {
      auth: true,
      layout: 'app-layout',
      organization: true,
      additionalbar : true,
      barlayout: 'main-sidebar',
      main: 'data-structure'
    }
  },
  {
    path: "/data-structure/products",
    component: () => import(/* webpackChunkName: "Product" */ "./pages/Products/Index.vue"),
    meta: {
      auth: true,
      layout: 'app-layout',
      organization: true,
      additionalbar : true,
      barlayout: 'main-sidebar',
      main: 'data-structure'
    }
},
{
  path: "/data-structure/categories",
  component: () => import(/* webpackChunkName: "Product" */ "./pages/Categories/Index.vue"),
  meta: {
    auth: true,
    layout: 'app-layout',
    organization: true,
    additionalbar : true,
    barlayout: 'main-sidebar',
    main: 'data-structure'
  }
},
]