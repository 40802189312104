<template>
    <section>
        <figure>
            <img v-if="org.logo" :src="org.logo.includes('data:image') ? org.logo : imgURL + org.logo" alt="organization_logo">
            <span v-else class="no-logo">{{org.name.charAt(0)}}</span>
        </figure>
        <div class="data">
            <span class="organization-name"  @click="changeOrg = !changeOrg">{{org.name}}</span>
            <span v-if="Object.keys(client).length" class="client-name"  @click="changeOrg = !changeOrg">{{client.name}}</span>
            <change-org v-if="changeOrg" v-click-outside="disableOrgNav" />
        </div>
        
    </section>
</template>

<script>
import ChangeOrg from "@/components/Ui/General/ChangeOrg.vue";
export default {
    data() {
        return {
            changeOrg: false,
            imgURL: process.env.VUE_APP_DO_SPACES,
            org: localStorage.getItem("organization") ? JSON.parse(localStorage.getItem("organization")): {},
            client: localStorage.getItem("client") ? JSON.parse(localStorage.getItem("client")) : {},
        }
    },

    components: {
        ChangeOrg
    },

    methods: {
        disableOrgNav() {
            this.changeOrg = false
        }
    }
}
</script>

<style lang="scss" scoped>
section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 1.5rem;

    span {
        color: white;
    }

    figure {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 50%;
        overflow: hidden;
        user-select: none;

        @media only screen and (max-width: 768px) {
            width: 3rem;
            height: 3rem;
        }

        img, .no-logo {
            height: 100%;
            width: 100%;
        }

        img {
            object-fit: cover;
        }

        .no-logo {
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--primary-color);
            font-weight: 600;
            font-size: 1.2rem;
        }
    }

    .data {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.2rem;
        user-select: none;
        cursor: pointer;
        position: relative;

        .organization-name {
            font-size: 0.6rem;
            text-align: center;
        }

        .client-name {
            font-size: 0.5rem;
            text-align: center;
        }
    }
    
}
</style>