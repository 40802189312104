export default{
    getDisabled(state){
        return state.notChat
    },
    getCallStatus(state){
        return state.callStarted
    },
    getCallId(state){
        return state.callId
    },
    getTicketId(state){
        return  state.ticketId
    },
    getUsersInCall(state){
        return  state.usersInCall
    },
    getCallInfo(state){
        return  state.callInfo
    },
}