export default [
    {
      path: "/user-register",
      component: () => import(/* webpackChunkName: "Sites" */ "./pages/Index.vue"),
      meta: {
        auth: false,
        layout: 'register-layout'
      }
    },
    {
      path: "/register-user-client",
      component: () => import(/* webpackChunkName: "Sites" */ "./pages/Index.vue"),
      meta: {
        auth: false,
        layout: 'register-layout'
      }
    },
    {
      path: "/register-client",
      component: () => import(/* webpackChunkName: "Sites" */ "./pages/Index.vue"),
      meta: {
        layout: 'register-layout'
      }
    },
]