<template>
    <main @click="redirectTo">
        <figure>
            <img src="/assets/images/timer.svg" alt="clock_icon">
        </figure>
        <div class="info">
            <span class="sub">Timer Running on:</span>
            <span :title="`${data.title} #${data.id}`">{{ data.title }}</span>
        </div>
    </main>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },

    methods: {
        redirectTo() {
            this.$router.push(`/remote-support/tickets/${this.data.id}`)
            this.$emit('close')
        }
    },
}
</script>

<style lang="scss" scoped>
    main {
        display: flex;
        align-items: center;
        background: rgba($grey, 0.5);
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        user-select: none;

        figure {
            width: 20px;
            height: 20px;

            img {
                width: 100%;
            }
        }

        .sub {
            font-size: 10px;
            color: black;
            color: var(--primary-color);
        }

        span {
            margin-left: 8px;
            max-width: 10rem;
            white-space: nowrap;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 500;
        }
    }
</style>