<template>
    <main class="mobile">
        <div class="slide-btn" v-if="addSidebar" :class="{opened: isOpen}" @click="$emit('switchOpen', !isOpen)">
            <div class="hamburguer" :class="{opened: isOpen}">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
        <template v-for="(item, i) in staticItems" :key="i">
            <div class="item" :class="{active: currentRoute.includes(item.desktop_endpoint)}" @click="goTo(item.desktop_endpoint)">
                <figure>
                    <img :src="item.desktop_icon" alt="module_icon">
                </figure>
            </div>
        </template>
    </main>
</template>

<script>
import staticItems from '@/assets/json/sidebar.json'

export default {

    props: {
        isOpen: {
            type: Boolean,
            default: () => true
        }
    },
    
    data() {
        return {
            currentRoute: undefined,
            staticItems,
            addSidebar: false
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler(val) {
                this.currentRoute = val.path
                this.addSidebar = val.meta?.additionalbar || false
            }
        },
    },

    methods: {
        goTo(data) {
            this.$router.push(data)
        }
    }

}
</script>

<style lang="scss" scoped>
    .mobile {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 5rem;
        background: $bar;
        z-index: 100;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;

            &.active {
                background: var(--primary-color);

                &:hover {
                    background: var(--primary-color);
                    animation: none;
                }
            }
        }

        .slide-btn {
            position: fixed;
            top: 30px;
            left: 15px;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: white;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 50%;
            cursor: pointer;
            z-index: 100;
            transition: 0.1s;

            &.opened {
                left: calc(300px + 15px);
            }

            @media only screen and (max-width: 1200px) {
                background: none;
                box-shadow: unset;
            }

            .hamburguer {
                display: flex;
                flex-direction: column;
                gap: .25rem;

                &.opened {
                    span {
                        &:first-of-type {
                            width: 20px;
                        }
                    }
                }

                span {
                    width: 20px;
                    height: 2px;
                    background: black;
                    transition: .2s;

                    &:first-of-type {
                        width: 15px;
                    }
                }
            }
        }
    }
</style>