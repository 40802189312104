<template>
    <template v-if="isDraw">
        <div class="color" :class="{clicked: picker}" @click="picker = !picker">
            <img src="/assets/images/ar/color-picker.png" alt="color picker" title="Color Picker">
        </div>
        <color-picker v-if="picker" :initColor="color" @newColor="handleNewColor" />
    </template>
    <template v-else>
        <div class="draw"></div>
    </template>
</template>

<script>
import ColorPicker from './Colorpicker.vue'
export default {
    props: {
        room: {
            required: false,
            type: String,
        },
        isDraw: {
            required: false,
            type: Boolean,
        },
    },

    data() {
        return {
            isDown: {},
            color: '#fff',
            picker: false,
            main: undefined
        }
    },

    watch: {
        isDraw(val) {
            if(!val) this.picker = false
        },
        main: {
            immediate: true,
            handler(val) {
                if(val)this.handleDraws()
            }
        },

    },

    components: {
        ColorPicker
    },

    methods: {
        handleNewColor(data) {
            this.color = data
        },
        draw(data) {
            const coords = data.coords || false
            const clear = data.toClear || false
            const color = data.color || 'white'
            const canvas = document.getElementById(`${data.stream_id}`)
            const ctx = canvas.getContext("2d");

            if(coords) {
                const relativeCoords = {
                    prevX: (canvas.width * coords.prevX)/coords.relativeTo.width,
                    prevY: (canvas.height * coords.prevY)/coords.relativeTo.height,
                    currX: (canvas.width * coords.currX)/coords.relativeTo.width,
                    currY: (canvas.height * coords.currY)/coords.relativeTo.height,
                }
                ctx.beginPath();
                ctx.lineCap = "round";
                ctx.strokeStyle = color;
                ctx.lineWidth = 5;
                ctx.moveTo(relativeCoords.prevX, relativeCoords.prevY);
                ctx.lineTo(relativeCoords.currX, relativeCoords.currY);
                ctx.stroke();
                ctx.closePath();
            }

            if(clear) {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
            }
        },
        getMain() {
            if (document.querySelector('.main')) {
                this.main = document.querySelector('.main').children[1]
            }
            else {
                setTimeout(() => {
                    this.getMain()
                }, 200);

            }
        },

        handleDraws() {
            const canvas = document.querySelector('.main').children[1]

            canvas.addEventListener('mousedown', (e) => {
                this.isDown = {
                    prevX: 0,
                    prevY: 0,
                    currX: e.offsetX,
                    currY: e.offsetY,
                    relativeTo: {
                        width: canvas.width,
                        height: canvas.height
                    }
                }
            })

            canvas.addEventListener('mousemove', (e) => {
                if(Object.keys(this.isDown).length && this.isDraw) {
                    const canvas = document.querySelector('.main').children[1]
                    this.isDown.prevX = this.isDown.currX;
                    this.isDown.prevY = this.isDown.currY;
                    this.isDown.currX = e.offsetX;
                    this.isDown.currY = e.offsetY;

                    const id = canvas.getAttribute('id')

                    const params = {
                        room: this.room,
                        coords: this.isDown,
                        stream_id: id,
                        color: this.color
                    }

                    this.emitter.emit("live-draw", params);
                }
            })

            window.addEventListener('mouseup', () => {
                this.isDown = {}
            })
        }
    },

    mounted() {
        this.getMain()
        this.emitter.on("receive-live-draw", this.draw);

    }
}
</script>

<style lang="scss" scoped>
.color {
    z-index: 4;
    position: absolute;
    top: 20px;
    background: rgba(0,0,0,0.3);
    width: 150px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
    cursor: pointer;
    &.clicked {
        width: 300px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    img {
        width: 18px;
        height: 18px;
        filter: invert(1);
    }
}
</style>