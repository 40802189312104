export default[
    {
        path: "/login", 
        component: () => import(/* webpackChunkName: "Login" */ "./pages/Login.vue"), 
        meta: { 
        auth: false, 
        layout: 'auth-layout' 
        }
    },
    {
        path: "/clientregister/:userid", 
        component: () => import(/* webpackChunkName: "Clientregister" */ "./pages/Clientregister.vue"), 
        meta: { 
        auth: false, 
        layout: 'auth-layout' 
        }
    },
    {
        path: "/congrat/:userid", 
        component: () => import(/* webpackChunkName: "Congratulation" */ "./pages/Congratulation.vue"), 
        meta: { 
        auth: false, 
        layout: 'auth-layout' 
        }
    },
    {
        path: "/reset-password/:userid", 
        component: () => import(/* webpackChunkName: "ResetPassword" */ "./pages/ResetPassword.vue"), 
        meta: { 
        auth: false, 
        layout: 'auth-layout' 
        }
    },
    {
        path: "/forgot-password", 
        component: () => import(/* webpackChunkName: "ForgotPassword" */ "./pages/ForgotPassword.vue"), 
        meta: { 
        auth: false, 
        layout: 'auth-layout' 
        }
    }
]