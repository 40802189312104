import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

export default {
    namespaced: true,
    state(){
        return{
            token: '',
            expiresIn: '',
            autoLogout: false,
        }
    },
    mutations: mutations,
    getters: getters,
    actions: actions,
}