import axiosInstance from "@/services/AxiosTokenInstance";
import {
    CREATE_USER_ACTION,
    GET_USER_DETAILS_ACTION,
    GET_USER_ROLES,
    SEARCH_USERS_BY_KEY,
    UPDATE_USER_DETAILS
} from "@/store/storeconstants";

export default {
    namespaced: true,
    state: {
      users: [],
    },

    getters: {
        getUsers(state){
            return state.users
        },
    },

    mutations: {
        setUsers(state, payload){
            state.users = payload
        },
    },

    actions: {
        async fetchUsers({commit}) {
            let fullUsers =[]
            let orgId = JSON.parse(localStorage.getItem('organization')).id;
            let response = await axiosInstance.get(`org-user-list/${orgId}`);
            const responseData = response.data.data;
            fullUsers.push(...responseData.users.data)
            if(responseData.users.next_page_url) {
                const pages = responseData.users.last_page
                for (let i = 2; i <= pages; i++) {
                    let res = await axiosInstance.get(`org-user-list/${orgId}?page=${i}`);
                    fullUsers.push(...res.data.data.users.data)
                }
            }
            commit('setUsers', fullUsers)
        },
        async [GET_USER_ROLES](){
            let response = '';
            try{
                response = await axiosInstance.get('org-role-list');
                if(response.status == 200){
                    let roles = response.data.data.organization_role;
                    return roles;
                }
            }catch(error){
                throw error.response.status;
            }
        },
        async [CREATE_USER_ACTION](_, payload){
            let orgId = JSON.parse(localStorage.getItem('organization')).id;
            let bodyFormData = new FormData();
            bodyFormData.append('image', payload.file);
            bodyFormData.append('name', payload.name);
            bodyFormData.append('email', payload.email);
            bodyFormData.append('phone', payload.phone);
            bodyFormData.append('language', payload.language);
            bodyFormData.append('role', payload.role);
            bodyFormData.append('id_organization', orgId);
            try{
                let response = '';
                response = await axiosInstance.post('add-user', bodyFormData,{
                    headers : {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if(response.status == 200){
                    return 'The user has been created successfully!!';
                }
            }catch(error){
                throw 'Something went wrong, please try again later';
            }
        },
        async [UPDATE_USER_DETAILS](_, payload){
            let orgId = JSON.parse(localStorage.getItem('organization')).id;
            let bodyFormData = new FormData();
            bodyFormData.append('user_id', payload.id);
            bodyFormData.append('image', payload.file);
            bodyFormData.append('name', payload.name);
            bodyFormData.append('email', payload.email);
            bodyFormData.append('phone', payload.phone);
            bodyFormData.append('language', payload.language);
            bodyFormData.append('role', payload.role);
            bodyFormData.append('id_organization', orgId);
            try{
                let response = '';
                response = await axiosInstance.post('edit-user', bodyFormData,{
                    headers : {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if(response.status == 200){
                    return 'The user has been updated successfully!!';
                }
            }catch(error){
                throw 'Something went wrong, please try again later';
            }
        },
        async [GET_USER_DETAILS_ACTION](_,payload){
            let response = '';
            try{
                response = await axiosInstance.get(`getuserdetail/${payload.user_id}`);
                if(response.status == 200){
                    return response.data.data;
                }
            }catch(error){
                throw error.response.status;
            }
        },
        async [SEARCH_USERS_BY_KEY](_,payload){
            let response = '';
            try{
                response = await axiosInstance.get(`user-search/${payload.search_key}`);
                //console.log("response_data",response.data.data.user);
                if(response.status == 200){
                    return response.data.data.user;
                }
                return null;
            }catch(error){
                throw error.response.status;
            }
        }
    },
}