<template>
    <div class="devices">
        <main v-click-outside="handleOutside">
            <section>
                <span>You need at least audio permissions to join a call.</span>
                <div class="submit" @click="$emit('close')">
                    Close
                </div>
            </section>
        </main>
    </div>
</template>

<script>
export default {
    methods: {
        handleOutside() {
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss" scoped>
    .devices {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.4);
        top: 0;
        left: 0;
        z-index: 200;
        display: flex;
        justify-content: center;
        align-items: center;

        main {
            width: 90%;
            max-width: 500px;
            background: white;
            display: flex;
            border-radius: 4px;
            overflow: hidden;

            section {
                width: 100%;
                padding: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 1rem;

                span {
                    color: $red;
                }

                .submit {
                    background: $red;
                    color: white;
                    padding: 5px 20px;
                    width: fit-content;
                    user-select: none;
                    cursor: pointer;
                }
            }
        }
        
    }
</style>