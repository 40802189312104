export default[
  {
      path: "/chat",
      component: () => import(/* webpackChunkName: "Chat" */ "./Index.vue"),
      meta: {
        auth: true,
        layout: 'app-layout',
        organization: true,
      }
  }
]
