import { createStore } from 'vuex'

import auth from './modules/auth';
import account from './modules/account';
import organization from './modules/organization';
import user from './modules/user';
import ticket from './modules/ticket';
import chat from './modules/chat';
import site from './modules/site';
import product from './modules/product';
import document from './modules/document';
import category from './modules/category';
import troubleshoot from './modules/troubleshoot';
import faq from './modules/faq';
import client from './modules/client';
import pathing from './modules/pathing';
import call from './modules/call';
import analytics from './modules/analytics';
import {
  LOADING_SPINNER_SHOW_MUTATION
} from './storeconstants';

export default createStore({
  modules: {
    auth,
    account,
    organization,
    user,
    site,
    ticket,
    chat,
    product,
    document,
    troubleshoot,
    category,
    faq,
    client,
    pathing,
    call,
    analytics
  },
  state(){
    return{
      showLoading: false,
    }
  },
  mutations: {
    [LOADING_SPINNER_SHOW_MUTATION](state, payload){
      state.showLoading = payload;
    }
  }
})
