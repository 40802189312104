<template>
    <template v-if="isDraw">
        <div class="color" :style="{background: color}" @click="picker = !picker">
            <img src="/assets/images/ar/color-picker.png" alt="color picker" title="Color Picker">
        </div>
        <color-picker v-if="picker" :initColor="color" @newColor="handleNewColor" />
    </template>
    <template v-else>
        <div class="draw"></div>
    </template>
</template>

<script>
import ColorPicker from './Colorpicker.vue'
export default {
    props: {
        room: {
            required: false,
            type: String,
        },
        isDraw: {
            required: false,
            type: Boolean
        },
        main: {
            type: String
        }
    },

    data() {
        return {
            isDown: [],
            picker: false,
            touchCount: 0,
            color: '#ffffff'
        }
    },

    components: {
        ColorPicker
    },

    watch: {
        isDraw(val) {
            if(!val) this.picker = false
        },
        main: {
            immediate: true,
            handler() {
                setTimeout(() => {
                    this.handleDraws()
                }, 200);
            }
        },
    },

    methods: {
        hexToColor(color) {
            const final = color.substr(4, 2) + color.substr(2, 2) + color.substr(0, 2);
            return parseInt(final, 16);
        },

        handleNewColor(data) {
            this.color = data
        },

        handleDraws() {
            const videoFeeds = [...document.querySelector('.video-container').children]
            let canvas = videoFeeds.map(element => {
                if (!element.classList.contains('sided')) return element
            }).filter(el => el)

            if (canvas.length) canvas = canvas[0].children[1]
            else canvas = videoFeeds[1].children[1]

            canvas.addEventListener('mousedown', (e) => {
                this.isDown = [
                    {
                        x: e.offsetX,
                        y: e.offsetY,
                        drawingColor: parseInt(this.color.replace('#', ''), 16),
                        relativeTo: {
                            width: canvas.width,
                            height: canvas.height
                        },
                        action: 0
                    }
                ]

                const params = {
                    room: this.room,
                    coordinates: this.isDown
                }

                this.emitter.emit("ar-draw", params);
            })

            canvas.addEventListener('mousemove', (e) => {
                if(Object.keys(this.isDown).length && this.isDraw) {
                    this.isDown[0].x = e.offsetX;
                    this.isDown[0].y = e.offsetY;
                    this.isDown[0].action = 2

                    const params = {
                        room: this.room,
                        coordinates: this.isDown
                    }
                    
                    this.emitter.emit("ar-draw", params);
                }
            })

            window.addEventListener('mouseup', () => {
                if (this.isDown.length) {
                    this.isDown[0].action = 1

                    const params = {
                        room: this.room,
                        coordinates: this.isDown
                    }

                    this.emitter.emit("ar-draw", params);

                        
                    this.isDown = []
                }
                
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.color {
    z-index: 410;
    position: absolute;
    bottom: 20px;
    left: 10px;
    background: rgba(0,0,0,0.3);
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
    cursor: pointer;

    img {
        width: 18px;
        height: 18px;
        filter: contrast(0.5);
    }
}
</style>