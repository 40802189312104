import axiosInstance from "@/services/AxiosTokenInstance";
import {
    CREATE_SITE_ACTION,
    ADD_USERS_TO_SITE_ACTION,
    GET_SITE_DETAILS_ACTION,
   UPDATE_SITE_ACTION
} from "@/store/storeconstants";

export default {
    namespaced: true,
    state(){
        return{
            sites: []
        }
    },

    getters: {
        getSites(state){
            return state.sites
        },
    },

    mutations: {
        setSites(state, payload){
            state.sites = payload
        },
    },

    actions: {
        async fetchSites({commit}) {
            let fullSites =[]
            let orgId = JSON.parse(localStorage.getItem('organization')).id;
            let response = await axiosInstance.get(`projectlist/${orgId}`);
            const responseData = response.data.data;
            fullSites.push(...responseData.projects.data)
            if(responseData.next_page_url) {
                const pages = responseData.last_page
                for (let i = 2; i <= pages.length; i++) {
                    let res = await axiosInstance.get(`productlist/${orgId}?page=${i}`);
                    fullSites.push(...res.data.data.projects.data)
                }
            }
            commit('setSites', fullSites)
        },

        async [CREATE_SITE_ACTION](_,payload){
            let orgId = JSON.parse(localStorage.getItem('organization')).id;
            let bodyFormData = {
                'name': payload.name,
                'description': payload.description,
                'organization_id': orgId
            };
            try{
                let response = '';
                response = await axiosInstance.post('addproject', bodyFormData);
                if(response.status == 200){
                    return response.data.data.project_id;
                }
            }catch(error){
                throw 'Something went wrong,site already exist.';
            }
        },
        async [ADD_USERS_TO_SITE_ACTION](_,payload){
            let arrayObj = payload.users;
            arrayObj = arrayObj.map(item => {
                return {
                    id_user: item.id,
                };
                });
            let bodyFormData = {
                'users': [...arrayObj],
                'id_project': payload.project_id
            };
            
            try{
                let response = '';
                response = await axiosInstance.post('addprojectuser', bodyFormData);

                if(response.status == 200){
                    //The site or project id should return here
                    return 'Site and user added successfully!!';
                }
            }catch(error){
                throw 'Something went wrong, please try again later';
            }
        },
        async [UPDATE_SITE_ACTION](_,payload){
            let response = '';
            let orgId = JSON.parse(localStorage.getItem('organization')).id;
            let bodyFormData = {
                'name': payload.project_name,
                'description': payload.project_desription,
                'organization_id': orgId,
                'id_project':payload.id
            };
            
            try{
                response = await axiosInstance.post(`editproject`, bodyFormData);
                if(response.status == 200){
                    return response.data.data;
                }
            }catch(error){
                throw error.response.status;
            }
        },
        async [GET_SITE_DETAILS_ACTION](_,payload){
            let response = '';
            try{
                response = await axiosInstance.get(`projectdetails/${payload.site_id}`);
               // console.log("response now",response.data.data)
                if(response.status == 200){
                    return response.data.data;
                }
            }catch(error){
                throw error.response.status;
            }
        },
    }
}