import axiosInstance from "@/services/AxiosTokenInstance";
import {
    CREATE_FAQ_ACTION,
    GET_FAQ_DETAILS_ACTION,
    UPDATE_FAQ_ACTION
} from "@/store/storeconstants";

export default{
    async [CREATE_FAQ_ACTION](_,payload){
        let orgId = JSON.parse(localStorage.getItem('organization')).id;
        let bodyFormData = {
            'title': payload.title,
            'description': payload.description,
            'id_category': payload.category,
            'id_product':1,
            'id_organization': orgId,
            'status':payload.status,
        };
        try{
            let response = '';
            response = await axiosInstance.post('addfaq', bodyFormData);
            if(response.status == 200){
                return response.data.data.category_id;
            }
        }catch(error){
            throw 'Something went wrong, please try again later';
        }
    },

    // async [ADD_USERS_TO_SITE_ACTION](_,payload){
    //     let arrayObj = payload.users;
    //     arrayObj = arrayObj.map(item => {
    //         return {
    //             id_user: item.id,
    //         };
    //         });
    //     let bodyFormData = {
    //         'users': [...arrayObj],
    //         'id_project': payload.project_id
    //     };
    //     try{
    //         let response = '';
    //         response = await axiosInstance.post('addprojectuser', bodyFormData);
    //         if(response.status == 200){
    //             //The site or project id should return here
    //             return 'The site has been created successfully!!';
    //         }
    //     }catch(error){
    //         throw 'Something went wrong, please try again later';
    //     }
    // },
    async [UPDATE_FAQ_ACTION](_,payload){
        let response = '';
        let orgId = JSON.parse(localStorage.getItem('organization')).id;
        let bodyFormData = {
            'id':payload.id,
            'title': payload.name,
            'description': payload.description,
            'sequence': payload.sequence,
            'id_organization':orgId,
            'id_product':1,
            'id_category': payload.category,
            'status':payload.status,
        };
        try{
            response = await axiosInstance.post(`editfaq`, bodyFormData);
            if(response.status == 200){
                return response.data.data;
            }
        }catch(error){
            throw error.response.status;
        }
    },
    async [GET_FAQ_DETAILS_ACTION](_,payload){
        let response = '';
       // let orgId = JSON.parse(localStorage.getItem('organization')).id;
        try{
            response = await axiosInstance.get(`faq-details/${payload.faq_id}`);
            if(response.status == 200){
                return response.data.data;
            }
        }catch(error){
            throw error.response.status;
        }
    },
}