import axiosInstance from "@/services/AxiosTokenInstance";
import {
    GET_STEP_DETAILS_ACTION,CREATE_TROUBLE_ACTION,CREATE_STEP_ACTION,GET_STEPIMAGE_DETAILS_ACTION,UPDATE_STEPIMAGE_ACTION,
    CREATE_STEP_DOCUMENT_ACTION,CREATE_STEP_IMAGE_ACTION,GET_DETAIL_STEPS_ACTION,UPDATE_STEP_ACTION
} from "@/store/storeconstants";

export default{
    async [CREATE_TROUBLE_ACTION](_,payload){
        let orgId = JSON.parse(localStorage.getItem('organization')).id;
        let bodyFormData = {
            'title': payload.title,
            'description': payload.description,
            'id_organization': orgId,
            'id_product':1,
            'id_category':payload.category,

        };
        
        try{
            let response = '';
            response = await axiosInstance.post('addtroubleshoot', bodyFormData);
            if(response.status == 200){
                return response.data.data.product_id;
            }
        }catch(error){
            throw 'Something went wrong, please try again later';
        }
    },
    async [CREATE_STEP_ACTION](_,payload){
        //let orgId = JSON.parse(localStorage.getItem('organization')).id;
        let bodyFormData = {
            'title': payload.title,
            'description': payload.description,
            'id_troubleshoot': payload.guideid,
        };
        
        try{
            let response = '';
            response = await axiosInstance.post('addstep', bodyFormData);
            if(response.status == 200){
                return response.data.data;
            }
        }catch(error){
            throw 'Something went wrong, please try again later';
        }
    },
    async [CREATE_STEP_DOCUMENT_ACTION](_,payload){
        let orgId = JSON.parse(localStorage.getItem('organization')).id;
        let bodyFormData = new FormData();
        bodyFormData.append('document', payload.file);
        bodyFormData.append('name', payload.name);
        bodyFormData.append('id_step', payload.step_id);

        bodyFormData.append('id_organization', orgId);
        
        try{
            let response = '';
            response = await axiosInstance.post('addstepdocument', bodyFormData,{
                headers : {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if(response.status == 200){
                return 'The file uploaded successfully successfully!!';
            }
        }catch(error){
            throw 'Something went wrong, please try again later';
        }
    },
    async [CREATE_STEP_IMAGE_ACTION](_,payload){
        let orgId = JSON.parse(localStorage.getItem('organization')).id;
        let bodyFormData = new FormData();
        bodyFormData.append('image', payload.file);
        bodyFormData.append('name', payload.name);
        bodyFormData.append('id_step', payload.step_id);

        bodyFormData.append('id_organization', orgId);
        
        try{
            let response = '';
            response = await axiosInstance.post('addstepimage', bodyFormData,{
                headers : {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if(response.status == 200){
                return 'The file uploaded successfully successfully!!';
            }
        }catch(error){
            throw 'Something went wrong, please try again later';
        }
    },
    async [UPDATE_STEPIMAGE_ACTION](_,payload){
        //let orgId = JSON.parse(localStorage.getItem('organization')).id;
        let bodyFormData = new FormData();
        bodyFormData.append('image', payload.image);
        bodyFormData.append('name', payload.name);
        bodyFormData.append('id', payload.id);
        //bodyFormData.append('state', payload.state);

        //bodyFormData.append('id_organization', orgId);
        
        try{
            let response = '';
            response = await axiosInstance.post('updatestepimage', bodyFormData,{
                headers : {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if(response.status == 200){
                return 'The file uploaded successfully successfully!!';
            }
        }catch(error){
            throw 'Something went wrong, please try again later';
        }
    },
    async [UPDATE_STEP_ACTION](_,payload){
        let response = '';
        //let orgId = JSON.parse(localStorage.getItem('organization')).id;
        let bodyFormData = {
            'title': payload.title,
            'description': payload.description,
            'sequence': payload.sequence,
            'id':payload.id,
            'id_troubleshoot':payload.id_troubleshoot
        };
        
        try{
            response = await axiosInstance.post(`updatestep`, bodyFormData);
            if(response.status == 200){
                return response.data.data;
            }
        }catch(error){
            throw error.response.status;
        }
    },
    /*Individual Step Details*/
    async [GET_DETAIL_STEPS_ACTION](_,payload){
        let response = '';
        //let orgId = JSON.parse(localStorage.getItem('organization')).id;
        try{
            response = await axiosInstance.get(`step-details/${payload.step_id}`);
            
            if(response.status == 200){
                return response.data.data;
            }
        }catch(error){
            throw error.response.status;
        }
    },
    /*Individual StepImage Details*/
    async [GET_STEPIMAGE_DETAILS_ACTION](_,payload){
        let response = '';
        //let orgId = JSON.parse(localStorage.getItem('organization')).id;
        try{
            response = await axiosInstance.get(`step-img-details/${payload.img_id}`);
            
            if(response.status == 200){
                return response.data.data;
            }
        }catch(error){
            throw error.response.status;
        }
    },


    /*list of Step*/
    async [GET_STEP_DETAILS_ACTION](_,payload){
        let response = '';
        //let orgId = JSON.parse(localStorage.getItem('organization')).id;
        try{
            response = await axiosInstance.get(`show-step/${payload.trouble_id}`);
            
            if(response.status == 200){
                return response.data.data;
            }
        }catch(error){
            throw error.response.status;
        }
    },
}