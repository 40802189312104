import axiosInstance from "@/services/AxiosTokenInstance";

const api = {
        async get(path, data = {}, headers = {}) {
        return this.call(path, data, headers, 'get');
        },
    
        async post(path, data = {}, headers = {}) {
        return this.call(path, data, headers, 'post');
        },
    
        async call(path, data = {}, headers = {}, method = 'get') {
        // Default headers
        const defaultHeaders = new Headers({
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data'
        });
    
        for (const [key, value] of Object.entries(headers)) {
            defaultHeaders.append(key, value);
        }

        let args = {
            headers: defaultHeaders
        };
    
        let queryParams = '';

        
        const formData = new FormData();
        
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, value);
        }
    
        switch (method) {
            case 'get': {
        
                queryParams = `?${new URLSearchParams(formData).toString()}`;
                break;
            }
            case 'post': {
                args = formData
                break;
        }
        }

        const response = await axiosInstance[method](
            `${process.env.VUE_APP_API_IP}${path}${queryParams}`,
            args
        ).catch(exception => {
            console.error(exception);
            this.emit('error', {
                exception,
                path,
            });
        });
  
        return new Promise((resolve, reject) => {
            const result = response.data
            /* eslint-disable prefer-promise-reject-errors */
            ~result.code
                ? resolve(result.data)
                : reject(result.errors ? { errors: result.errors } : null);
        });
    },
  };
  
  export default api;
  