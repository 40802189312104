<template>
<div ref="stream" class="streamManager" @click="$emit('update', id)" :class="{sided: (!setMain && isMain !== id), main: (!setMain && isMain === id), default: setMain, hide: (!fs && !popup) && (!setMain && isMain !== id)}" v-if="statedStream && Object.keys(statedStream).length">
	<Video :stream-manager="statedStream" @mounted="handleMounted"/>
	<canvas :id="id"></canvas>
	<div class="name">
        <p>{{ clientData }}</p>
        <figure class="muted" v-if="!streamManager.stream.audioActive">
            <img src='/assets/images/mic-button-mute.png' alt="muted">
        </figure>
    </div>
	<div class="initial" v-if="!streamManager.stream.videoActive">
        <span>{{ clientData.charAt(0) }}</span>
    </div>
</div>
</template>

<script>
import Video from './Video';
export default {
	name: 'UserVideo',
	components: {
		Video,
	},
	props: {
		streamManager:{
			required: true,
			type: Object,
		},
		videoShow:{
			required: false,
			type: Boolean,
			default: () => true,
		},
		myStream:{
			required: true,
			type: Object,
		},
		sidebar:{
			required: false,
			type: String,
			default: '',
		},
		isMain: {
			required: false,
			type: String,
			default: '',
		},
		setMain: {
			required: false,
			type: Boolean
		},
		fs: {
			required: false,
			type: Boolean,
			default: () => false,
		},
		popup: {
			required: false,
			type: Boolean
		}
	},

	data(){
		return{
			statedStream: this.streamManager,
			id: ''
		}
	},

	watch: {
		streamManager:{
			immediate: true,
			handler(val) {
				if(val) {
                    if (!val.stream.videoActive) this.handleMounted()
					this.statedStream = val
					setTimeout(() => {
						this.id = this.statedStream.stream.connection.connectionId.replace('con_', '')
					}, 2000);
				}
			}
		},
		
		fs: {
			immediate: true,
			handler() {
				this.canvasSize()
			}
		},

		isMain: {
			immediate: true,
			handler() {
				this.canvasSize()
			}
		},
	},

	computed: {
		clientData () {
			const { clientData } = this.getConnectionData();
			return clientData;
		},
	},
	methods: {
		getConnectionData () {
			const { connection } = this.streamManager.stream;
			return JSON.parse(connection.data.split('%')[0]);
		},

		canvasSize() {
			setTimeout(() => {
				const video = this.$refs.stream?.children?.[0]
				const canvas = this.$refs.stream?.children?.[1]

                if(!video || !canvas) return
				canvas.width = video.clientWidth
				canvas.height = video.clientHeight
			}, 500);
		},

		handleMounted() {
			this.$emit('mounted')
			this.canvasSize()
		}
	},

	mounted() {
		window.addEventListener('resize', () => {
			this.canvasSize()
		})
		setTimeout(() => {
			this.canvasSize()
		}, 5000);
	}
};
</script>

<style lang="scss" scoped>
.streamManager {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	border-radius: 5px;
	overflow: hidden;
	background: rgb(31, 30, 30);

	&.hide {
		display: none;
	}

	&.main {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	&.sided {
		width: 10rem;
        aspect-ratio: 16/9;
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		cursor: pointer;
		z-index: 50;
        box-shadow: 0px 0px 12px 0px rgba(255, 255, 255, 0.3);

		.name {
			width: 100%;
			text-align: center;
		}

		.initial {
			span {
                width: 50px;
                height: 50px;
                font-size: 2rem;
            }
		}

	}

	canvas {
		position: absolute;
		z-index: 3;
	}
	.name {
		position: absolute;
		top: 0;
		left: 0;
		background: rgba(0,0,0,0.3);
		color: white;
		padding: 10px;
		border-bottom-right-radius: 5px;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        height: 50px;
        z-index: 1;

        .muted {
            width: 30px;
            height: 30px;
            filter: grayscale(1);
            opacity: 0.5;
            img {
                width: 100%;
                object-fit: contain;
            }
        }
	}

	.initial {
		width: 100%;
		height: 100%;
        background: var(--bar);
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 0;

        span {
            width: 120px;
            height: 120px;
            color: white;
            background: var(--primary-color);
            border-radius: 50%;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 2.5rem;
        }
	}


}
</style>