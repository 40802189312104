<template>
    <li v-click-outside="handleOutside">
        <div class="user" :class="{opened: dropdown}" @click="dropdown = !dropdown">
            <figure>
                <img :src="user.avatar" alt="user_avatar">
            </figure>
            <div class="info">
                <div class="top">
                    <span class="name">{{user.name || 'loading'}}</span>
                    <span class="arrow" :class="{opened: dropdown}">
                        <img src="/assets/images/left-arrow.svg" alt="open">
                    </span>
                </div>
                <span class="org">{{org.name}}</span>
            </div>
        </div>
        <div v-if="dropdown" class="dropdown">
            <div class="main">
                <template v-for="(item, i) in items" :key="i">
                    <span @click="handleAction(item.action)">{{item.label}}</span>
                </template>
            </div>
            <div class="logout" @click=";($emit('logout')), (dropdown = false)">
                <span @click="logout">Logout</span>
            </div>
        </div>
    </li>
    <Modal
        v-if="modal.length"
        :title="title"
        :items="modal"
        :response="response"
        @data="handleData"
        @close=";(modal = []), (current = '')"
    />
    <QrLogin
        v-if="loginWithQr"
        @data="handleData"
        @close="loginWithQr = false"
    />
</template>

<script>
import items from './items.json'
import Modal from '../Create/Index.vue'
import QrLogin from '@/components/Ui/QrCode/Login.vue'
import { mapActions } from 'vuex'
import { UPDATE_USER_PROFILE_ACTION } from "@/store/storeconstants";
import axiosInstance from '@/services/AxiosTokenInstance'
export default {
    props: {
        user: {
            type: Object,
            required: false,
            default: () => {}
        }
    },

    data() {
        return {
            org: localStorage.getItem("organization") ? JSON.parse(localStorage.getItem("organization")) : {},
            dropdown: false,
            items,
            modal: [],
            title: '',
            response: [],
            current: '',
            loginWithQr: false
        }
    },

    emits: ['logout'],

    components: {
        Modal,
        QrLogin
    },

    computed: {
        editProfile() {
            return [
                {
                    type: "image",
                    name: 'image',
                    label: "Image",
                    value: [{link: this.user.avatar}],
                    required: false,
                },
                {
                    type: "text",
                    name: 'name',
                    label: "Name",
                    value: this.user.name,
                    required: false,
                },
                {
                    type: "email",
                    name: 'email',
                    label: "Email",
                    value: this.user.email,
                    required: false,
                },
                {
                    type: "number",
                    name: 'phone',
                    label: "Phone Number",
                    value: this.user.phone,
                    required: false,
                },
                {
                    type: "select",
                    name: 'language',
                    label: "Language",
                    options: [
                        { id: 'pt', name: "Portuguese" },
                        { id: 'en', name: "English" },
                    ],
                    value: {
                        id: this.user.language,
                        name:this.user.language === 'en' ? "English" : "Portuguese",
                    },
                    required: false,
                },
            ]
        },

        changePassword() {
            return [
                {
                    type: "password",
                    name: 'current_pw',
                    label: "Current Password",
                    required: true,
                },
                {
                    type: "password",
                    name: 'new_pw',
                    label: "New Password",
                    required: true,
                },
                {
                    type: "password",
                    name: 'repeat_pw',
                    label: "Confirm New Password",
                    required: true,
                },
            ]
        },
    },

    methods: {
            ...mapActions("account", {
            updateUserData: UPDATE_USER_PROFILE_ACTION,
            }),

            handleAction(action) {
                this.current = action
                switch (action) {
                    case 'editProfile':
                        this.modal = this.editProfile
                        this.title = 'Edit Profile'
                        this.dropdown = false
                        break;
                    case 'changePw':
                        this.modal = this.changePassword
                        this.title = 'Change Password'
                        this.dropdown = false
                        break;
                    case 'loginQr':
                        this.loginWithQr = true
                    break;
                }
            },

            handleData(data) {

                switch (this.current) {
                    case 'editProfile':
                        this.editProfileData(data)
                        break;
                    case 'changePw':
                        this.editPassword(data)
                    break;
                }

            },

            async editProfileData(data) {
                let toSend = {
                    name: data.name || this.user.name,
                    image: data.image ? data.image[0].originalFile : null,
                    email: data.email || this.user.email,
                    phone: data.phone || this.user.phone,
                    language: data.language ? data.language.id : this.user.language
                }

                await this.updateUserData(toSend).finally(() => {
                    this.response = [true, true];
                    location.reload()
                })
            },

            async editPassword(data) {
                let params = {
                    old_pass: data.current_pw,
                    new_pass: data.new_pw,
                    confirm_pass: data.repeat_pw,
                };
                try {
                    const response = await axiosInstance.post('change-password', params);
                    if (response.status === 200) {
                        this.response = [true, true]
                        this.emitter.emit('alert', response.data.message)
                    }
                    else {
                        this.response = [true, false]
                        this.emitter.emit('alert', response.data.message)
                    }
                } catch (error) {
                    this.response = [true, false];
                    throw error.response.data.errors;
                }
            },

            handleOutside() {
                this.dropdown = false
            }
    },
}
</script>

<style lang="scss" scoped>
    .user {
        position: relative;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;
        user-select: none;
        padding: 10px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        border-bottom: solid 1px transparent;
        z-index: 2;
        
        &.opened {
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            background: white;
            border-bottom: solid 1px #f2f5f8;
        }

        figure {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            overflow: hidden;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            .top {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                .name {
                    font-weight: 500;
                }

                .arrow {
                    width: 0.5rem;
                    transform: rotate(270deg);
                    transition: 0.2s;

                    &.opened {
                        transform: rotate(90deg);
                    }
                }
            }

            .org {
                text-transform: uppercase;
                font-weight: 300;
                opacity: 0.7;
                font-size: 0.5rem;
            }
        }
    }

    .dropdown {
        position: absolute;
        width: 100%;
        background: white;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        z-index: 5;

        .main {
            display: flex;
            flex-direction: column;
            border-bottom: solid 1px #f2f5f8;

            span {
                padding: 10px;
                font-size: 0.8rem;
                user-select: none;
                cursor: pointer;
                transition: 0.2s;

                &:hover {
                    background: #f2f5f8;
                }
            }
        }

        .logout {
            padding: 10px;
            font-size: 0.8rem;
            user-select: none;
            cursor: pointer;
            transition: 0.2s;

            &:hover {
                background: #f2f5f8;
            }
        }
    }
</style>