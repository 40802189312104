<template>
    <div class="item" @drop="drop" @dragenter.prevent @dragover.prevent>
        <label>
            <span>{{item.label}}</span>
			<span v-if="item.required"> *</span>
        </label>
        <div v-if="files.length && item.type === 'file'" class="files">
            <div
                class="file"
                v-for="(file, index) in files"
                :key="index"
            >
                <template v-if="item.type === 'file'">
                    <main>
                        <div class="icon" @click="openFile(index)">
                            <img src="/assets/images/doc-icon.svg" alt="" />
                        </div>
                        <div class="info" @click="openFile(index)">
                            <div class="name">{{ file.name }}</div>
                            <div class="more">
                            <span>{{ file.pages }} pages</span>
                            <span>{{ file.size }}</span>
                            <span>{{ file.extension }}</span>
                            </div>
                        </div>
                    </main>
                    <div class="delete" @click="removeFile(index)">X</div>
                </template>
            </div>
        </div>
        <div class="import">
            <label v-if="item.type === 'file'">
                <input
                    ref="importFile"
                    type="file"
                    accept="application/pdf,application/vnd.ms-excel, text/plain"
                    multiple="multiple"
                    @change="importFiles"
                />
                Import File
            </label>
            <label v-else-if="item.type === 'image'" :class="{'w-image' : files.length}">
                <input
                    ref="importImage"
                    type="file"
                    accept="image/png, image/jpeg"
                    @change="importImages"
                />
                <span v-if="!files.length">Import Image</span>
                <figure v-else>
                    <img :src="files[0].link" alt="">
                </figure>
            </label>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object
        }
    },

    data() {
        return {
            files: this.item.value || []
        }
    },

    watch: {
        files: {
            deep: true,
            handler(val) {
                this.$emit('data', [this.item.name, val])
            }
        }
    },

    methods: {
        bytesToSize(bytes) {
            const sizes = ["bytes", "kb", "mb", "gb", "tb"];
            if (bytes == 0) return "0 Byte";
            const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
        },

        importFiles(e) {
            e.target.files.forEach((file) => {
                let filePages = 0;
                const today = new Date();
                const date =
                today.getDate() +
                "/" +
                (today.getMonth() + 1) +
                "/" +
                today.getFullYear();

                const reader = new FileReader();
                reader.readAsBinaryString(file);
                reader.onloadend = () => {
                    filePages = reader.result.match(/\/Type[\s]*\/Page[^s]/g)
                        ? reader.result.match(/\/Type[\s]*\/Page[^s]/g).length
                        : "?";
                    reader.readAsDataURL(file);
                    reader.onloadend = () => {
                        if (file.size < 10485760) {
                            this.files.push({
                                name: file.name,
                                pages: filePages,
                                size: this.bytesToSize(file.size),
                                extension: file.name.split(".")[1].toUpperCase(),
                                imported_at: date,
                                link: reader.result,
                                og_doc: file,
                            });
                        } else this.error = "File is too big";

                        this.$refs.importFile.value = "";
                    };
                };
            });
        },

        importImages(event) {
            const file = event.target.files[0];

            if (FileReader && file) {
                const fr = new FileReader();
                fr.readAsDataURL(file);
                fr.onload = () => {
                this.files= [{
                    name: file.name,
                    link: fr.result,
                    originalFile: file,
                }];

                this.$refs.importImage.value = "";
                };
            }
        },

        drop(e) {
            e.stopPropagation();
            e.preventDefault();
            const files = e.dataTransfer.files;

            const toImport = {
                target: {
                    files,
                },
            };
            this.importFiles(toImport);
        },

        openFile(e) {
            let fileWindow = window.open("");
            fileWindow.document.write(`
            <html>
                <head>
                    <title>
                        ${this.files[e].name}
                    </title>
                </head>
                <body style="margin: 0 auto;">
                    <embed width='100%' height='100%' src="${this.files[e].link}" />
                </body>
            </html>`);
        },

        removeFile(e) {
            this.files.splice(e, 1);
        },
    }
}
</script>

<style lang="scss" scoped>
    label {
        font-weight: 500;
        font-size: 1rem;
		color: var(--primary-color);
        margin: 0;
    }
    input {
        border: solid 1px #cad6e2;
        border-radius: 2px;
        padding: 10px 15px;
    }

    .item {
        gap: 1rem;
    }

    .files {
        width: 100%;
        display: flex;
        flex-direction: column;
        .file {
            border-left: solid 1px var(--primary-color);
            padding: 5px 10px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            user-select: none;
            cursor: pointer;
            border-top: 1px solid #eeeeee;

            &:last-of-type {
                border-bottom: 1px solid #eeeeee;
            }
            main {
                display: flex;
                align-items: center;
                gap: 1rem;
            }
            .delete {
                transition: 0.2s;

                &:hover {
                    color: $red;
                }
            }
        }
    }

    .import {
        width: 100%;
        display: flex;
        justify-content: center;
        label {
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 10px 0;
            border: solid 1px var(--primary-color);
            border-radius: 2px;
            cursor: pointer;
            transition: 0.2s;

            &.w-image {
                width: 10rem;
                height: 10rem;
                padding: 0;
                border: solid 2px var(--primary-color);
                border-radius: 50%;
                overflow: hidden;
                figure {
                    width: 100%;
                    height: 100%;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: 0.2s;
                        &:hover {
                            opacity: 0.9;
                        }
                    }
                }
            }

            &:hover {
                color: white;
                background: var(--primary-color);
            }

            input {
                display: none;
            }
        }
    }
</style>