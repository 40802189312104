<template>
    <div class="arAlerts">
        <div class="ar" v-if="data.ar.arEnable">
            <span>{{data.ar.arEnabledUserName}} has AR enabled</span>
        </div>
        <div class="draw" v-if="data.draw.drawing">
            <span>{{data.draw.drawingUserName}} is drawing</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object
        }
    },
}
</script>

<style lang="scss" scoped>
    .arAlerts {
        position: absolute;
        top: 1rem;
        color: white;
        width: 100%;
        z-index: 3;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.2rem;
        div {
            opacity: 0.4;
            width: 80%;
            max-width: 300px;
            background: #0515246b;
            display: flex;
            justify-content: center;
            padding: 0.3rem 0;
            border-radius: 1rem;
            user-select: none;
            &:hover {
                opacity: 0.7;
            }
        }
    }
</style>