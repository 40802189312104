<template>
    <main>
        <section>
            <header>
                <h2>Attention</h2>
            </header>
            <div class="content">
                <span>You left the timer running on ticket "{{ data.title }}#{{ data.id }}""</span>
                <span>Do you want to keep it running or cancel it?</span>
            </div>
            <footer>
                <button @click="$emit('close', 'continue')">Keep it</button>
                <button class="cancel" @click="stopTimer">Stop Timer</button>
            </footer>
        </section>
    </main>
</template>

<script>
import axiosInstance from "@/services/AxiosTokenInstance";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },

    data() {
        return {
            org: localStorage.organization ? JSON.parse(localStorage.organization) : {},
        }
    },

    methods: {
        async stopTimer() {
            const data = {
                org_id: this.org.id
            }
            await axiosInstance.post(`ticket-timer/${this.data.id}`, data)

            this.emitter.emit('alert', `Stoped Timer on Ticket "${this.data.title}#${this.data.id}"`)
            this.$emit('close', 'stop')
        }
    }
}
</script>

<style lang="scss" scoped>
    main {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.2);
        backdrop-filter: blur(2px);
        z-index: 150;
        display: flex;
        justify-content: center;
        align-items: center;

        section {
            width: 95%;
            max-width: 600px;
            height: 95%;
            max-height: 200px;
            background: white;
            border-radius: 4px;

            header, footer {
                padding: 0 20px;
                height: 4rem;
                display: flex;
                align-items: center;
            }

            header {
                h2 {
                    margin: 0;
                    color: #fb9a4b;
                    font-weight: 600;
                }
                border-bottom: solid 1px $grey;
            }

            .content {
                padding: 0 20px;
                height: calc(100% - 8rem);
                display: flex;
                flex-direction: column;
                justify-content: center;
                span {
                    font-weight: 500;
                    margin-top: 4px;
                    user-select: none;

                    &:first-of-type{
                        margin-top: 0px;
                    }
                }
            }

            footer {
                justify-content: space-between;
                button {
                    width: 49%;
                    background: var(--primary-color);
                    color: white;
                    padding: 10px;
                    cursor: pointer;
                    transition: 0.2s;

                    &:hover {
                        background: var(--primary-hover);
                    }

                    &.cancel {
                        background: #babec3;
                        &:hover {
                            background: #a0a2a5;
                        }
                    }
                }
            }
        }
    }
</style>