export default{
    setDisabled(state, payload){
        state.notChat = payload
    },
    setCallStatus(state, payload){
        state.callStarted = payload
    },
    setCallId(state, payload){
        state.callId = payload
    },
    setTicketId(state, payload){
        state.ticketId = payload
    },
    setUsersInCall(state, payload){
        if (!payload) state.usersInCall = []
        else {
            const currUsers = state.usersInCall.map(el => el.id)
            if (currUsers.includes(payload.id)) {
                const i = currUsers.indexOf(payload.id)
                state.usersInCall.splice(i, 1)
            }
            else state.usersInCall.push(payload)
        }
    },
    setCallInfo(state, payload) {
        state.callInfo = payload
    },
}