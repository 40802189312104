import axios from "axios";
import store from '@/store'
import { GET_USER_TOKEN_GETTER } from "@/store/storeconstants";

const nodeServiceInstance = axios.create({});
nodeServiceInstance.interceptors.request.use((config) => {
    let token = store.getters[`auth/${GET_USER_TOKEN_GETTER}`];
    config.headers = {
        'Authorization': 'Bearer '+ token,
        'time-offset': -330
    }
    let url = `${process.env.VUE_APP_NODE_IP}/api/v1/`;
    config.baseURL = url;
    return config;
});
export default nodeServiceInstance;
