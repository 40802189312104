const notification = (data) => {
    if (!data.room) return ''

    const mentionedIds = data.mentionedUsers.map(el => el.id)
    const user = localStorage['U_P'] ? JSON.parse(localStorage['U_P']) : {}

    if (data.room.ticketId && (mentionedIds.includes(user.id) || mentionedIds.includes(0))) return `${data.sentBy.name} mentioned you on ticket "${data.room.ticketAdditionalDetails.title}#${data.room.ticketId}" ${data.room.type === 'ticket' ? 'main' : data.room.type} chat.`
  
    if (data.room.ticketId) return `${data.sentBy.name} sent a message on ticket "${data.room.ticketAdditionalDetails.title}#${data.room.ticketId}" ${data.room.type === 'ticket' ? 'main' : data.room.type} chat.`

    if (mentionedIds.includes(user.id) || mentionedIds.includes(0)) return `${data.sentBy.name} mentioned you on a message.`

    return `${data.sentBy.name} sent you a message.`
};
  
export default notification;