// import { i18n } from '../i18n';
import clearMessageFormat from './formatMessage';

const notification = {
  data() {
    return {
      imgURL: process.env.VUE_APP_DO_SPACES,
    }
  },

  mixins: [clearMessageFormat],

  methods: {
    setNotification(data) {
      let url = ''
      if (data.tag === 'new-message' && !data.room.ticketId) url = `/chat?roomId=${data.room._id}`
      else if (data.tag === 'new-message' && data.room.ticketId) url = `/remote-support/tickets/${data.room.ticketId}`
      
      navigator.serviceWorker.ready.then(async ready => {
        ready.showNotification(data.sentBy.name, {
          actions: [
            {
              action: 'view',
              title: 'See in Browser',
            },
            {
              action: 'mark_as_read',
              title: 'Close',
            },
          ],
          body: this.clearMessageFormat(data.message),
          badge: this.imgURL + data.sentBy.avatar,
          icon: this.imgURL + data.sentBy.avatar,
          tag: `${data.tag}-${data.sentBy.name}`,
          renotify: true,
          data: {url: url}
        });
      });
    },
  },
};

export default notification;
