import HomeNavbar from './layouts/home/navbar.json'
import ViewsHome from './views/home/home.json';
import Modules from './views/modules/index.json';
import ViewLogin from './views/login/login.json';
import ViewForgetPassword from './views/login/forget.json';
import ViewOrganization from './views/organization/organization.json'
import CommonSidebar from './common/sidebar.json';
import CommonHeader from './common/header.json';
import CommonModal from './common/modals.json';
export default{
    ...HomeNavbar,
    ...ViewsHome,
    ...ViewLogin,
    ...ViewForgetPassword,
    ...ViewOrganization,
    ...CommonSidebar,
    ...CommonHeader,
    ...CommonModal,
    ...Modules
}