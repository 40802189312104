<template>
    <div class="freeze-popup" @mouseup="isDown = []">
        <div class="freeze-container">
            <div class="title">
                <span>Draw on Image</span>
                <span class="close" @click="$emit('close')">x</span>
            </div>
            <div class="content">
                <div ref="wrap" class="wrap" :class="{erase: eraser}">
                    <img ref="cvSize" :src="image" alt="">
                    <div ref="circles" class="canvas circles" @mousedown="handleMouseDown" @mousemove="handleMouseMove">
                        <template v-for="(circle, i) in circles" :key="i">
                            <span :style="{left: `${circle.position[0] - (circle.size / 2)}px`, top: `${circle.position[1] - (circle.size / 2)}px`, width: `${circle.size}px`, height: `${circle.size}px`,  border: `solid 5px ${circle.color}`}"></span>
                        </template>
                    </div>
                    <div ref="squares" class="canvas squares" @mousedown="handleMouseDown" @mousemove="handleMouseMove">
                        <template v-for="(square, i) in squares" :key="i">
                            <span :style="{left: `${square.position[0]}px`, top: `${square.position[1]}px`, width: `${square.width}px`, height: `${square.height}px`,  border: `solid 5px ${square.color}`}"></span>
                        </template>
                    </div>
                    <canvas ref="canvas" class="canvas" @mousedown="handleMouseDown" @mousemove="handleMouseMove"></canvas>
                    <div class="menu" v-if="show">
                        <div class="item" :class="{active: freehand }" @click="freehand = !freehand">
                            <img src="/assets/images/ar/freehand.png" alt="freehand" title="Free Hand">
                        </div>
                        <div class="item" :class="{active: square }" @click="square = !square">
                            <img src="/assets/images/ar/square.png" alt="square" title="Square">
                        </div>
                        <div class="item" :class="{active: circle }" @click="circle = !circle">
                            <img src="/assets/images/ar/dry-clean.png" alt="circle" title="Circle">
                        </div>
                        <div class="item eraser" :class="{active: eraser }" @click="eraser = !eraser">
                            <img src="/assets/images/eraser.svg" alt="eraser" title="eraser">
                        </div>
                    </div>
                </div>
                <colorpicker :freeze="true" :initColor="color" @newColor="handleNewColor" />
                <div class="message">
                    <textarea v-model="message" ref="comment" placeholder="Add a comment (optional)"/>
                    <div class="send">
                        <span class="icon" v-if="!loader" @click="sendFiles">
                            <img src="/assets/images/send_i.svg" alt="">
                        </span>
                        <span v-else class="loading">
                            <span class="loader"></span>
                        </span>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import html2canvas from 'html2canvas';
import Colorpicker from './Colorpicker.vue'
export default {
    props: {
        image: {
            required: true,
            type: String,
        },
        loading: {
            type: Boolean,
            default: () => false
        }
    },

    data() {
        return {
            colorpicker: false,
            color: '#fff',
            freehand: false,
            square: false,
            circle: false,
            isDown: [],
            show: true,
            message: '',
            circles: [],
            squares: [],
            freeHand: [],
            loader: false,
            eraser: false
        }
    },

    watch: {
        freehand(val) {
            if(val) {
                this.square = false
                this.circle = false
                this.eraser = false
            }
        },
        square(val) {
            if(val) {
                this.freehand = false
                this.circle = false
                this.eraser = false
            }
        },
        circle(val) {
            if(val) {
                this.square = false
                this.freehand = false
                this.eraser = false
            }
        },
        eraser(val) {
            if(val) {
                this.square = false
                this.freehand = false
                this.circle = false
            }
        },
        loading(val) {
            this.loader = val
        }
    },

    components: {
        Colorpicker
    },

    methods: {
        handleNewColor(data) {
            this.color = data
        },

        setCanvasSize() {
            setTimeout(() => {
                const img = this.$refs.cvSize
                const canvas = this.$refs.canvas
                canvas.width = img.offsetWidth
                canvas.height = img.offsetHeight
            }, 200);
        },

        handleMouseDown(e) {
            if (this.freehand || this.circle || this.square || this.eraser) {
                const canvas = this.$refs.canvas
                this.isDown = {
                    prevX: 0,
                    prevY: 0,
                    currX: (e.offsetX - canvas.offsetLeft),
                    currY: (e.offsetY - canvas.offsetTop)
                }
            }

            if (this.circle) {
                this.circles.push({
                    position: [this.isDown.currX, this.isDown.currY],
                    size: 30,
                    color: this.color
                })
            }

            if (this.square) {
                this.squares.push({
                    position: [this.isDown.currX, this.isDown.currY],
                    width: 30,
                    height: 30,
                    color: this.color
                })
            }

        },

        handleMouseMove(event) {
            if(Object.keys(this.isDown).length) {
                if(this.freehand) this.draw(event)
                if(this.square) this.rect(event)
                if(this.circle) this.arc(event)
                if(this.eraser) this.erase(event)
            }
        },

        draw(e) {
            const canvas = this.$refs.canvas
            const ctx = canvas.getContext("2d");
            this.isDown.prevX = this.isDown.currX;
            this.isDown.prevY = this.isDown.currY;
            this.isDown.currX = (e.offsetX - canvas.offsetLeft) + 10;
            this.isDown.currY = (e.offsetY - canvas.offsetTop) + 10;

            ctx.beginPath();
            ctx.lineCap = "round";
            ctx.strokeStyle = this.color;
            ctx.lineWidth = 5;
            ctx.moveTo(this.isDown.prevX, this.isDown.prevY);
            ctx.lineTo(this.isDown.currX, this.isDown.currY);
            ctx.stroke();
            ctx.closePath();
        },

        rect(e) {
            const initX = this.squares[this.squares.length - 1].position[0]
            const countX = e.offsetX - initX
            const width = countX < 30 ? 30 : countX
            this.squares[this.squares.length - 1].width = width

            const initY = this.squares[this.squares.length - 1].position[1]
            const countY = e.offsetY - initY
            const height = countY < 30 ? 30 : countY
            this.squares[this.squares.length - 1].height = height
            

        },

        arc(e) {
            const init = this.circles[this.circles.length - 1].position[0]
            const count = e.offsetX - init
            const size = count < 30 ? 30 : count
            this.circles[this.circles.length - 1].size = size
        },

        erase(e) {
            const canvas = this.$refs.canvas
            const ctx = canvas.getContext("2d");

            const x = e.offsetX - canvas.offsetLeft - 7
            const y = e.offsetY - canvas.offsetTop

            const squares = Array.from(this.$refs.squares.children).map(el => {
                return {
                    startX: el.offsetLeft,
                    endX: el.offsetLeft + el.clientWidth,
                    startY: el.offsetTop,
                    endY: el.offsetTop + el.clientHeight
                }
            })

            const circles = Array.from(this.$refs.circles.children).map(el => {
                return {
                    startX: el.offsetLeft,
                    endX: el.offsetLeft + el.clientWidth,
                    startY: el.offsetTop,
                    endY: el.offsetTop + el.clientHeight
                }
            })

            squares.forEach((el, i) => {
                if (x < el.endX && x > el.startX && y > el.startY && y < el.endY) this.squares.splice(i, 1)
            })

            circles.forEach((el, i) => {
                if (x < el.endX && x > el.startX && y > el.startY && y < el.endY) this.circles.splice(i, 1)
            })

            ctx.clearRect(x, y, 25, 25)
        },

        sendFiles() {
            this.show = false
            this.loader = true
            setTimeout(() => {
                html2canvas(this.$refs.wrap).then((canvas) => {
                    const dataURL = canvas.toDataURL()
                    var blobBin = atob(dataURL.split(',')[1]);
                    var array = [];
                    for(var i = 0; i < blobBin.length; i++) {
                        array.push(blobBin.charCodeAt(i));
                    }
                    const myBlob = new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
                    myBlob.name = 'generated image'
                    const file = new File([myBlob], "image.jpeg", {
                        type: myBlob.type,
                    });
                    this.$emit('message', {image: {original: file}, text: this.message})
                })
            }, 100);

        }
    },

    mounted() {
        this.setCanvasSize()
        window.addEventListener('keyup', (e) => {
            if(e.keyCode === 27) this.$emit('close')
        })
    }
}
</script>

<style lang="scss" scoped>
.freeze-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    background: rgba(0,0,0,0.3);
    backdrop-filter: blur(5px);

    .freeze-container {
        height: 80vh;
        background: white;
        .title {
            height: 10%;
            box-shadow: 0px 1px 6px #CAD6E280;
            border-bottom: solid 1px #F2F5F8;
            padding: 20px;
            text-transform: uppercase;
            color: black;
            font-weight: bold;
            font-size: 1.2rem;
            display: flex;
            justify-content: space-between;

            .close {
                cursor: pointer;
                user-select: none;
            }
        }
        .content {
            height: 90%;
            padding: 10px;
            overflow: auto;
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;
            .wrap {
                display: flex;
                justify-content: center;
                position: relative;

                &.erase {
                    cursor: url('/assets/images/eraser.svg'),auto;
                }

                .canvas {
                    position: absolute;
                    top: 0px;
                    left: 0px;

                    &.circles {
                        width: 100%;
                        height: 100%;
                        span {
                            position: absolute;
                            border-radius: 50%;
                        }
                    }

                    &.squares {
                        width: 100%;
                        height: 100%;
                        span {
                            position: absolute;
                        }
                    }
                }
                .menu {
                    position: absolute;
                    top: 20px;
                    background: rgba(0,0,0,0.3);
                    display: flex;
                    justify-content: center;
                    border-radius: 15px;
                    overflow: hidden;
                    width: 150px;
                    transition: 0.2s;
                    &.color {
                        width: 300px;
                        border-bottom-left-radius: 0px;
                        border-bottom-right-radius: 0px;
                    }
                    .item {
                        width: 30px;
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: 0.2s;
                        cursor: pointer;

                        &:hover {
                            background: rgba(0,0,0,0.5);
                        }
                        &.active {
                            background: rgba(0,0,0,0.5);
                        }

                        &.eraser {
                            img {
                                filter: invert(0);
                            }
                        }

                        img {
                            width: 60%;
                            height: 60%;
                            filter: invert(1);
                        }
                    }
                }
            }

            .message {
                width: 100%;
                background: white;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 10px;
                border: solid 1px rgba(0,0,0,0.3);
                position: relative;
                textarea {
                    width: 100%;
                    height: 70px !important;
                    resize: none;
                    padding: 10px 70px 10px 10px;
                    &:focus {
                        outline: none;
                    }
                }

                .send {
                    position: absolute;
                    right: 10px;
                    bottom: 10px;
                    width: 30px;
                    height: 30px;
                    cursor: pointer;

                    .icon {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: var(--primary-color);
                        border-radius: 50%;

                        img {
                            width: 15px;
                            height: 15px;
                            transform: translateY(1px);
                        }
                    }

                    .loading {
                        width: 100%;
                        height: 100%;
                        background: var(--primary-color);
                        border-radius: 50%;

                        .loader {
                            width: 20px;
                            height: 20px;
                            border: 3px solid #f3f3f3;
                            border-top: 3px solid var(--primary-color);
                        }
                    }
                }
            }
        }
    }
}
</style>