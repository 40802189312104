<template>
    <main>
        <section>
            <header>
                <div class="title">
                    <span>{{title}}</span>
                </div>
                <div class="out" @click="$emit('close')">X</div>
            </header>
            <form id="form" @submit.prevent="create">
                <template v-for="(item, index) in items" :key="index">
                    <Normal v-if="item.type === 'text' || item.type === 'email' || item.type === 'number'" :item="item" @data="handleData"/>
                    <Password v-else-if="item.type === 'password'" :item="item" @data="handleData"/>
                    <Textarea v-else-if="item.type === 'textarea'" :item="item" @data="handleData"/>
                    <Select v-else-if="item.type === 'select'" :item="item" @data="handleData"/>
                    <File v-else-if="item.type === 'file' || item.type === 'image'" :item="item" @data="handleData"/>
                    <Boolean v-else-if="item.type === 'boolean'" :item="item" @data="handleData"/>
                    <BooleanMessage v-else-if="item.type === 'boolean-message'" :item="item" @data="handleData"/>
                    <Message v-else-if="item.type === 'message'" :item="item" @data="handleData"/>
                    <multiple-select v-else-if="item.type === 'multiple-select'" :item="item" @data="handleData"/>
                    <select-related v-else-if="item.type === 'select-related' || item.template === 'select-related'" :item="item" @data="handleData"/>
                    <multiple-select-related v-else-if="item.template === 'multiple-select-related'" :item="item" @data="handleData"/>
                    <text-editor v-else-if="item.type === 'texteditor'" :item="item" @data="handleData"/>
                    <ticket-times v-else-if="item.type === 'ticket-times'" :item="item"/>
                </template>
            </form>
            <footer :class="{err: errors.length}">
                <div v-show="errors.length" class="errors">
                    <span>{{errors.join(', ').replace(/, ([^,]*)$/, ' and $1')}} missing</span>
                </div>
                <button type="submit" form="form" :disabled="load">
                    <span v-if="!load">submit</span>
                    <div v-else class="loading">
                        <span class="loader"></span>
                    </div>
                </button>
            </footer>
        </section>
    </main>
</template>

<script>
import Normal from './Templates/Normal.vue'
import Password from './Templates/Password.vue'
import Select from './Templates/Select.vue'
import File from './Templates/File.vue'
import Boolean from './Templates/Boolean.vue'
import BooleanMessage from './Templates/BooleanMessage.vue'
import Message from './Templates/Message.vue'
import Textarea from './Templates/Textarea.vue'
import MultipleSelect from './Templates/MultipleSelect.vue'
import TextEditor from './Templates/TextEditor.vue'
import SelectRelated from './Templates/SelectRelated.vue'
import MultipleSelectRelated from './Templates/MultipleSelectRelated.vue'
import TicketTimes from './Templates/TicketTimes.vue'

export default {
    props: {
        items: {
            type: Array
        },
        response: {
            type: Array
        },
        title: {
            type: String,
            required: false,
            default: () => 'Create'
        }
    },

    components: {
        Normal,
        Select,
        MultipleSelect,
        TextEditor,
        File,
        Boolean,
        Textarea,
        BooleanMessage,
        Message,
        SelectRelated,
        MultipleSelectRelated,
        TicketTimes,
        Password
    },

    data() {
        return {
            finalData: {},
            errors: [],
            load: false
        }
    },

    watch: {
        response(val) {
            this.load = false

            if(val[1]) this.$emit('close')
        }
    },

    methods: {

        handleData(data) {
            if (Array.isArray(data[1])) {
                if (!data[1].length) delete this.finalData[data[0]];
                else this.finalData[data[0]] = data[1]
            }
            else if (typeof data[1] === 'object' && !Array.isArray(data[1])) {
                if (!Object.keys(data[1]).length) delete this.finalData[data[0]];
                else this.finalData[data[0]] = data[1]
            }
            else this.finalData[data[0]] = data[1]
        },

        create() {
            this.errors = []
            const required = this.items.filter(el => el.required).map(el => el.name)

            required.forEach(el => {
                if(!this.finalData[el]) this.errors.push(el)
            })

            if(this.errors.length) return console.log('stop', this.errors)

            this.load = true
            this.$emit('data', this.finalData)
        }
    },
}
</script>

<style lang="scss" scoped>
main {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(100,100,100,0.4);
    z-index: 150;
    display: flex;
    justify-content: center;
    align-items: center;

    section {
        background: white;
        width: 95%;
        max-width: 550px;
        height: 95%;
        max-height: 800px;
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: relative;

        header, form, footer {
            padding: 20px;
        }

        header {
            height: 10%;
            box-shadow: 0px 1px 6px #e0e0e0;
            border-bottom: 1px solid #eeeeee;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: sticky;
            top: 0;

            div {
                font-weight: 600;
                font-size: 1.3rem;
            }

            .out {
                user-select: none;
                cursor: pointer;
                transition: 0.2s;

                &:hover {
                    color: grey;
                }
            }
        }

        form {
            height: 80%;
            overflow: auto;
            display: flex;
            flex-direction: column;
            gap: 20px;
            
            .item {
                display: flex;
                flex-direction: column;
                gap: 0.25rem;
            }
        }

        footer {
            height: 10%;
            box-shadow: 0px 1px 6px #e0e0e0;
            border-top: 1px solid #eeeeee;
            position: absolute;
            bottom: 0;
            display: flex;
            width: 100%;
            justify-content: flex-end;
            align-items: center;

            &.err {
                justify-content: space-between;
            }

            .errors {
                width: 70%;
                color: $red;
                text-transform: capitalize;
                overflow-wrap: break-word;
            }

            button {
                color: white;
                padding: 10px 25px;
                text-transform: capitalize;
                background: var(--primary-color);
                user-select: none;
                cursor: pointer;
                transition: 0.2s;
                min-width: 10rem;
                border: none;

                &:focus {
                    outline: none;
                }

                &:hover {
                    background: var(--primary-hover);
                }

                .loading {
                    height: unset;
                    background: transparent;

                    .loader {
                        width: 20px;
                        height: 20px;
                        border: 2px solid #f3f3f3;
                        border-top: 2px solid var(--primary-color);
                    }
                }
            }
        }
    }
}
</style>