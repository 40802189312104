export default[
    {
        path: "/analytics",
        component: () => import(/* webpackChunkName: "Performance" */ "./pages/Dashboard.vue"),
        meta: {
          auth: true,
          layout: 'app-layout',
          organization: true,
          additionalbar : false
        }
    }
]