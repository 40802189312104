<template>
	<div class="container">
		<template v-if="!session && !loaded">
			<teleport :disabled="tp" :to="tp ? '#header' : '#videochat'">
				<div class="loading" :class="{popup: getDisabled}">
					<img src="/assets/images/ajax-loader.gif" alt="">
					<span>connecting...</span>
				</div>
			</teleport>
		</template>

		<template v-else-if="session">
			<teleport :disabled="tp" :to="tp ? '#header' : '#videochat'">
				<div class="loading" :class="{popup: getDisabled}" v-if="!loaded">
					<img src="/assets/images/ajax-loader.gif" alt="">
					<span>connecting...</span>
				</div>
				<div ref="chat" class="live-chat" :class="{popup: getDisabled, fullscreen: fs}" :style="{ left: (moveChat.left && getDisabled && !fs) ? moveChat.left + 'px' : '', top: (moveChat.top && getDisabled && !fs) ? moveChat.top + 'px' : '', width: (resizer.new_width && getDisabled && !fs) ? resizer.new_width + 'px' : '', height: (resizer.new_height && getDisabled && !fs) ? resizer.new_height + 'px' : ''}">
					<div v-if="getDisabled && !fs" class="dragable" @mousedown="handleOffset" @mousemove="drag ? dragChat($event) : ''" @mouseup="drag = false" ></div>
					<div v-if="getDisabled && !fs" class='resizable'>
						<div ref="res" class='resizers'>
							<div class='resizer top-left' @mousedown="handleDown($event, 'top-left')" @mouseup="handleUp" ></div>
							<div class='resizer top-right' @mousedown="handleDown($event, 'top-right')" @mouseup="handleUp" ></div>
							<div class='resizer bottom-left' @mousedown="handleDown($event, 'bottom-left')" @mouseup="handleUp" ></div>
							<div class='resizer bottom-right' @mousedown="handleDown($event, 'bottom-right')" @mouseup="handleUp" ></div>
						</div>
					</div>
					<div class="main-video" :class="{popup: getDisabled && !fs}">
						<ar-menu v-if="loaded && (fs || !getDisabled)" :room="room" :main="main"  @isDrawing="handleDraw"/>
						<div class="options" v-if="loaded">
							<div class="button" @click="muteAudio">
								<img :src="audioEnabled ? '/assets/images/mic-button.png' : '/assets/images/mic-button-mute.png'" alt="" />
							</div>
							<div class="button" @click="muteVideo">
								<img :src="publishVideo && videoEnabled ? '/assets/images/video-button.png' : '/assets/images/video-button-mute.png'" alt="" />
							</div>
							<div class="button" @click="leaveSession">
								<img src="/assets/images/call-button.png" alt="" />
							</div>
							<div class="button fs" @click="fullscreenVideo">
								<img :src="fs ? '/assets/images/fs_close.svg' : '/assets/images/fs.svg'" alt="" />
							</div>
							<div class="button fs devices" @click="showDevices = true">
								<img src="/assets/images/icons/settings.svg" alt="settings" />
							</div>
						</div>
						<div class="video-container" :class="{draw: isDrawing}">
							<user-video :stream-manager="mainStreamManager" :myStream="myStream" :isMain="main" :setMain="setMain" :popup="!getDisabled" :videoShow="videoEnabled" @mounted="handleVideoMounted" @update="updateMainVideoStreamManager"/>
							<template v-for="(sub, index) in subscribers" :key="index">
								<user-video :stream-manager="sub" :myStream="myStream" :isMain="main" :setMain="setMain" :popup="!getDisabled" :fs="fs" :videoShow="videoEnabled" @update="updateMainVideoStreamManager"/>
							</template>
						</div>
					</div>
				</div>
			</teleport>
		</template>
        <DevicesComponent v-if="showDevices" :current-devices="currentDevices" @close="showDevices = false" @changeDevices="handleChangeDevices" />
	</div>
</template>

<script>
import { OpenVidu } from 'openvidu-browser';
import UserVideo from './UserVideo.vue';
import ArMenu from '../AR/Menu.vue';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { CREATE_CHAT_SESSION } from '@/store/storeconstants';
import DevicesComponent from '@/components/Ui/Chat/Devices.vue';

export default {
    props: {
        room: {
            required: false,
            type: String,
        },
    },

	components: {
		UserVideo,
		ArMenu,
        DevicesComponent
	},

	data () {
		return {
			OV: undefined,
			session: undefined,
			mainStreamManager: undefined,
			publisher: undefined,
			subscribers: [],
			mySessionId: '',
			videoEnabled: true,
			audioEnabled: true,
            user: localStorage.getItem("U_P") ?  JSON.parse(localStorage.getItem("U_P")) : {},
            org: localStorage.organization ? JSON.parse(localStorage.organization) : {},
			moveChat: {},
			drag: false,
			onCall: false,
			offset: [],
			isResizing: false,
			resizer: {
				min_size: 200,
				og_width: 0,
				og_height: 0,
				og_x: 0,
				og_y: 0,
				og_mouse_x: 0,
				og_mouse_y: 0,
				new_width: undefined,
				new_height: undefined,
			},
			target: '',
			loaded: false,
			myStream: {},
			othersLogged: 0,
			isDrawing: false,
			tp: true,
			main: '',
			setMain: false,
			fs: false,
            publishVideo: false,
            publishAudio: false,
            currentDevices: {
                audio: undefined,
                video: undefined
            },
            showDevices: false
		}
	},

	watch: {
		getDisabled: {
			immediate: true,
			handler(val) {
				this.tp = val
			}

		},
		main: {
			immediate: true,
			handler(val) {
				if(!val.length) {
					this.setMain = true
				}
				else this.setMain = false
			}
		},

		mySessionId: {
			immediate: true,
			handler(val) {
				if (val.length) {
					this.emitter.emit('ArConfiguration', {
						roomId: this.room,
						sessionId: val
					})
				}
			}
		}
	},

    computed: {
		...mapGetters({
			getDisabled: 'call/getDisabled',
			getCallId: 'call/getCallId',
			getTicketId: 'call/getTicketId',
		}),
        myUserName() {
            if (this.user) return this.user.name
            return 'Guest' + Math.floor(Math.random() * 100)
        }
    },

	methods: {
        ...mapActions("chat", {
            createChatSession: CREATE_CHAT_SESSION,
            // leaveChatSession: LEAVE_CHAT_SESSION,
        }),


        ...mapActions({
            leaveChatSession: "chat/leaveConnection",
            storeAnalytics: "analytics/storeAnalytics",
        }),

		...mapMutations({
			setCallStatus: 'call/setCallStatus',
			setCallId: 'call/setCallId',
			setTicketId: 'call/setTicketId',
            setUsersInCall: 'call/setUsersInCall',
            setCallInfo: 'call/setCallInfo',
		}),

        handleVideoMounted() {
            this.loaded = true
            setTimeout(() => {
                this.emitter.emit('handle-video', this.videoEnabled && this.publishVideo)
            }, 1000);
        },

        async createNewSession() {
            let params = {room_id: this.room};
            const data = await this.createChatSession(params)

            this.mySessionId = data.sessionId;
            this.sessionToken = data.token;
            this.sessionName = data.sessionName;


            if(!this.mySessionId) return
            
            this.joinSession();
            
            this.emitter.emit('setConfigForCall', {
                roomId: this.room,
                sessionId: data.sessionId,
                arStreamerId: '',
                arEnable: false,
                arEnableUserId: 0,
                arEnabledUserName: '',
                drawing: false,
                drawingUserId: 0,
                drawingUserName: '',
                drawingColor: '',
                mainScreenUserId: 0,
                mainScreenUserName: '',
            })
        },

		async joinSession () {
			this.OV = new OpenVidu();

            const devices = await this.OV.getDevices()

			//check Video Devices
            const videoDevices = devices.filter(device => device.kind === 'videoinput' && device.deviceId !== '');
            const videoSource = videoDevices && videoDevices.length ? videoDevices[0] : false

            if (!videoSource) this.publishVideo = false
            else this.publishVideo = true

            //chgeck Audio Devices
            const audioDevices = devices.filter(device => device.kind === 'audioinput' && device.deviceId !== '');
            const audioSource = audioDevices && audioDevices.length ? undefined : false
            
            if (audioSource === false) this.publishAudio = false
            else this.publishAudio = true

            if (!this.publishAudio && !this.publishVideo) {
                this.handleNoPermissions()
                return
            }

			this.session = this.OV.initSession();

			this.session.on('streamCreated', ({ stream }) => {
				const subscriber = this.session.subscribe(stream);
				this.subscribers.push(subscriber);
                this.emitter.emit('get-audio-configuration', {
                    roomId: this.room,
                    sessionId: this.mySessionId
                })

                let user = {}
                if (stream.connection.data.split('%/%')[1]) {
                    user = JSON.parse(stream.connection.data.split('%/%')[1])
                    user.id = user.userId
                    this.setUsersInCall(user)
                }
                

				if(this.main === '') this.main = subscriber.stream.connection.connectionId.replace('con_', '')
			});

			this.session.on('streamDestroyed', ({ stream }) => {
                this.emitter.emit('get-audio-configuration', {
                    roomId: this.room,
                    sessionId: this.mySessionId
                })
                let user = {}
                if (stream.connection.data.split('%/%')[1]) {
                    user = JSON.parse(stream.connection.data.split('%/%')[1])
                    user.id = user.userId
                    this.setUsersInCall(user)
                }

				const index = this.subscribers.indexOf(stream.streamManager, 0) || false;
				if (index >= 0) {
					this.subscribers.splice(index, 1);

				}
				this.main = ''
			});

			this.session.on('publisherStartSpeaking', (event) => {
				this.emitter.emit("single-start-speaking", event.connection.data)
			});

			this.session.on('publisherStopSpeaking', (event) => {
				this.emitter.emit("single-stop-speaking", event.connection.data)
			});
            

			this.session.on('exception', ({ exception }) => {
				console.warn(exception);
			});

            await this.session.connect(this.sessionToken, { clientData: this.myUserName })

            //call properties
            const properties = {
                audioSource: audioSource,
                videoSource: videoSource,
                publishAudio: this.publishAudio && this.audioEnabled,
                publishVideo: this.publishVideo && this.videoEnabled,
                resolution: '640x480',
                frameRate: 30,
                insertMode: 'APPEND',
                mirror: false
            }

            //publish data
            this.OV.initPublisherAsync(undefined, properties).then(async (publisher) => {
                this.publisher = publisher
    
                this.emitter.emit("session-started", {room: this.room})

                this.mainStreamManager = this.publisher;
                this.myStream = this.publisher;

                this.session.publish(this.publisher);
                this.setUsersInCall(this.user)
            })
		},

        handleNoPermissions() {
            const params = {
                room: this.room,
                text: null,
            };

            const messageParams = {
                roomId: this.room,
                text: 'This user tried to join the call but has no Audio/Video permissions on his browser to access it.',
                parentId: null,
            }

            this.emitter.emit("send-message", messageParams)
            this.emitter.emit("call-disconnected", params);
            this.emitter.emit("no-permissions");
            this.leaveSession()
        },

        async handleChangeDevices(data) {
            this.showDevices = false
            if (!data.video && !data.audio) return

            const devices = await this.OV.getDevices()

            //chgeck Video Devices
            const videoDevices = devices.filter(device => device.kind === 'videoinput' && device.deviceId !== '');
            const videoSource = data.video ? data.video : videoDevices && videoDevices.length ? videoDevices[0] : false

            if (!videoSource) this.publishVideo = false
            else this.publishVideo = true

            //chgeck Audio Devices
            const audioDevices = devices.filter(device => device.kind === 'audioinput' && device.deviceId !== '');
            const audioSource = data.audio ? data.audio :audioDevices && audioDevices.length ? undefined : false
            
            if (audioSource === false) this.publishAudio = false
            else this.publishAudio = true


            const properties = {
                videoSource: videoSource,
                audioSource: audioSource,
                publishAudio: this.publishAudio,
                publishVideo: this.publishVideo,
                resolution: '640x480',
                frameRate: 30,
                insertMode: 'APPEND',
                mirror: false
            }

            const newPublisher = await this.OV.initPublisherAsync(undefined, properties)

            await this.session.unpublish(this.publisher)

            this.publisher = newPublisher

            this.mainStreamManager = newPublisher;
            this.myStream = newPublisher;

            this.session.publish(newPublisher);
            this.setUsersInCall(this.user)
        },

        muteVideo(){
			this.videoEnabled = !this.videoEnabled;
			this.emitter.emit('handle-video', this.videoEnabled && this.publishVideo)
			this.publisher.publishVideo(this.videoEnabled && this.publishVideo)
            this.emitter.emit('set-audio-configuration', { 
                roomId: this.room,
                sessionId:  this.session.sessionId,
                streamerId: this.myStream.stream.connection.connectionId,
                videoEnabled: this.videoEnabled && this.publishVideo,
                type: 'video'
            })
		},

		muteAudio(){
			this.audioEnabled = !this.audioEnabled;
			this.emitter.emit('handle-audio', this.audioEnabled)
			this.publisher.publishAudio(this.audioEnabled)
            this.emitter.emit('set-audio-configuration', { 
                roomId: this.room,
                sessionId:  this.session.sessionId,
                streamerId: this.myStream.stream.connection.connectionId,
                muteEnabled: !this.audioEnabled,
                type: 'mic'
            })
		},

		fullscreenVideo() {
			this.fs = !this.fs
		},

        endCallAnalytics() {
            if (this.getTicketId) {
                return {
                    action: "end-ticket-call",
                    environment: process.env.VUE_APP_ENVIRONMENT,
                    org_id: this.org.id.toString(),
                    ticket_id: this.getTicketId.toString(),
                    user_id: this.user.id.toString(),
                    users_in_call: [],
                    session_id: this.mySessionId
                }
            }

            return {
                action: "end-chat-call",
                environment: process.env.VUE_APP_ENVIRONMENT,
                org_id: this.org.id,
                session_id: this.mySessionId,
                user_id: this.user.id,
                users_in_call: [],
            }
        },

		async leaveSession () {
			this.othersLogged = 0

            this.storeAnalytics(this.endCallAnalytics())

			this.setCallStatus(false)
			this.setCallId(undefined)
			this.setTicketId(undefined)
			this.setUsersInCall(0)
			this.setCallInfo({})
            
            this.session.disconnect();

			this.session = undefined;
			this.mainStreamManager = undefined;
			this.publisher = undefined;
			this.subscribers = [];
			this.OV = undefined;
			this.callEnded = true;

			// leave the session on node server
			const params = {
				room_id: this.room,
				token: this.sessionToken,
			};

			await this.leaveChatSession(params)

            this.mySessionId = '';
            this.sessionToken = '';
            this.sessionName = '';

			this.emitter.emit("call-disconnected", {
				room: this.room,
				text: '',
			});

			window.removeEventListener('beforeunload', this.leaveSession);
			this.$emit('close')
		},

		updateMainVideoStreamManager (id) {
			this.main = id
		},

		handleDown(e, position) {
			const rect = this.$refs.res.getBoundingClientRect();
			this.resizer.og_width = rect.width
			this.resizer.og_height = rect.height
			this.resizer.og_x = rect.left
			this.resizer.og_y = rect.top
			this.resizer.og_mouse_x = e.pageX
			this.resizer.og_mouse_y = e.pageY
			this.isResizing = true
			this.target = position
			window.addEventListener('mousemove', this.resizeCall)
		},

		handleUp() {
			this.isResizing = false
			window.removeEventListener('mousemove', this.resizeCall)
		},

		handleDraw(data) {
			this.isDrawing = data
		},

		resizeCall(e) {
            if(!this.isResizing) return

            const position = this.target
            switch (position) {
                case 'bottom-right':
                    if (this.resizer.og_width + (e.pageX - this.resizer.og_mouse_x) > this.resizer.min_size) this.resizer.new_width = this.resizer.og_width + (e.pageX - this.resizer.og_mouse_x);
                    if (this.resizer.og_height + (e.pageY - this.resizer.og_mouse_y) > this.resizer.min_size) this.resizer.new_height = this.resizer.og_height + (e.pageY - this.resizer.og_mouse_y)
                    break;
                case 'bottom-left':
                    if (this.resizer.og_width - (e.pageX - this.resizer.og_mouse_x) > this.resizer.min_size) {
                    this.resizer.new_width = this.resizer.og_width - (e.pageX - this.resizer.og_mouse_x)
                    this.moveChat.left = this.resizer.og_x + (e.pageX - this.resizer.og_mouse_x)
                    }
                    if (this.resizer.og_height + (e.pageY - this.resizer.og_mouse_y) > this.resizer.min_size) this.resizer.new_height = this.resizer.og_height + (e.pageY - this.resizer.og_mouse_y)
                    break;
                case 'top-right':
                    if (this.resizer.og_width + (e.pageX - this.resizer.og_mouse_x)) this.resizer.new_width = this.resizer.og_width + (e.pageX - this.resizer.og_mouse_x);
                    if (this.resizer.og_height - (e.pageY - this.resizer.og_mouse_y) > this.resizer.min_size) {
                        this.resizer.new_height = this.resizer.og_height - (e.pageY - this.resizer.og_mouse_y)
                        this.moveChat.top = this.resizer.og_y + (e.pageY - this.resizer.og_mouse_y)
                    }
                    break;
                case 'top-left':
                    if (this.resizer.og_width - (e.pageX - this.resizer.og_mouse_x)) {
                        this.resizer.new_width = this.resizer.og_width - (e.pageX - this.resizer.og_mouse_x)
                        this.moveChat.left = this.resizer.og_x + (e.pageX - this.resizer.og_mouse_x)
                    }
                    if (this.resizer.og_height - (e.pageY - this.resizer.og_mouse_y) > this.resizer.min_size) {
                        this.resizer.new_height = this.resizer.og_height - (e.pageY - this.resizer.og_mouse_y)
                        this.moveChat.top = this.resizer.og_y + (e.pageY - this.resizer.og_mouse_y)
                    }
                    break;
            }
		},

		handleOffset(e) {
			const chat = this.$refs.chat
			this.offset = [
				chat.offsetLeft - e.clientX,
				chat.offsetTop - e.clientY
			]
			this.drag = true
		},

		dragChat(e) {
			if(this.getDisabled) {
				this.moveChat = {
					left: e.clientX + this.offset[0],
					top: e.clientY + this.offset[1]
				}
			}
		},
	},

	created() {
        this.createNewSession()

        window.addEventListener('beforeunload', this.leaveSession)

        //events
		this.emitter.on("handle-video", (value) => {
            this.videoEnabled = value
            if (this.publishVideo) this.publisher?.publishVideo(this.videoEnabled && this.publishVideo);
            this.emitter.emit('set-audio-configuration', { 
                roomId: this.room,
                sessionId:  this.mySessionId,
                streamerId: this.myStream.stream.connection.connectionId,
                videoEnabled: this.videoEnabled && this.publishVideo,
                type: 'video'
            })
        })

        this.emitter.on("handle-audio-pop", (value) => {
            this.audioEnabled = value
            this.publisher?.publishAudio(this.audioEnabled);
            this.emitter.emit('set-audio-configuration', { 
                roomId: this.room,
                sessionId:  this.mySessionId,
                streamerId: this.myStream.stream.connection.connectionId,
                muteEnabled: !this.audioEnabled,
                type: 'mic'
            })
        }),

        this.emitter.on("set-mic-status", (data) => {
            this.subscribers.filter(el => el.stream.connection.connectionId === data.streamerId)?.map(el => {
                el.stream.audioActive = !data.muteEnabled
                el.stream.videoActive = data.videoEnabled
            })
        })

		this.emitter.on("init-set-mic-status", (data) => {
            const users = data.users
            if (!users) return
            this.subscribers.map(el => {
                const index = users.findIndex(e => e.streamerId === el.stream.connection.connectionId)
                if (index >= 0) {
                     el.stream.audioActive = !users[index].muteEnabled
                     el.stream.videoActive = users[index].videoEnabled
                }
            })
        })

		this.emitter.on("destroy-call", () => {
            this.leaveSession();
        })

		this.emitter.on("create-ticket-call", (data) => {
            const analyticsData = {
                action: "create-ticket-call",
                environment: process.env.VUE_APP_ENVIRONMENT,
                org_id: this.org.id.toString(),
                ticket_id: data.ticketId.toString(),
                user_id: this.user.id.toString(),
                users_in_call: [],
                session_id: this.mySessionId
            }
            this.storeAnalytics(analyticsData)
        })

		this.emitter.on("create-chat-call", () => {
            const analyticsData = {
                action: "create-chat-call",
                environment: process.env.VUE_APP_ENVIRONMENT,
                org_id: this.org.id.toString(),
                session_id: this.mySessionId,
                user_id: this.user.id.toString(),
                users_in_call: JSON.stringify([]),
            }
            this.storeAnalytics(analyticsData)
        })
	}
}
</script>

<style lang="scss" scoped>
.loading {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	&.popup {
		width: 200px;
		height: 200px;
		background: black;
	}
	img {
		width: 20%;
		object-fit: contain;
	}
	span {
		color: white;
		text-transform: capitalize;
	}
}
.live-chat {
	position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

	&.fullscreen {
		position: fixed !important;
		width: calc(100% - 8rem) !important;
		height: 100% !important;
		top: 0;
		left: 8rem;
		z-index: 100;
		background: grey;

		.streamManager {
			border-radius: 0px;
		}
	}

	&.popup {
		width: auto;
		height: 200px;
		position: absolute;
		z-index: 10;
		align-items: center;
		background: black;
		overflow: hidden;

		.dragable {
			width: 95%;
			height: 95%;
			z-index: 9;
			position: absolute;
			cursor: move;
		}
		.resizable {
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 8;
			.resizers{
				width: 100%;
				height: 100%;
				box-sizing: border-box;

				.resizer{
					width: 30px;
					height: 30px;
					position: absolute;
					opacity: 0;
					user-select: none;

					&.top-left {
						left: -15px;
						top: -15px;
						cursor: nwse-resize; /*resizer cursor*/
					}

					&.top-right {
						right: -15px;
						top: -15px;
						cursor: nesw-resize;
					}

					&.bottom-left {
						left: -15px;
						bottom: -15px;
						cursor: nesw-resize;
					}

					&.bottom-right {
						right: -15px;
						bottom: -15px;
						cursor: nwse-resize;
					}
				}
			}
		}
	}
	.main-video {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 420px;

		&.popup {
			min-height: 200px;
			min-width: 200px;
			.options {
				width: unset;
				z-index: 10;
				.button {
					width: 2rem;
					&.fs {
						width: 2rem;
						height: 2rem;
					}
				}
			}
		}
		.options {
			position: absolute;
			bottom: 0;
			display: flex;
			width: 100%;
			justify-content: center;
			align-items: center;
			z-index: 4;
			padding: 10px;
			&.draw {
				z-index: 2;
			}
			.button {
				cursor: pointer;
				&.fs {
					width: 50px;
					height: 50px;
					background: rgba(100,100,100,0.3);
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					img {
						width: 40%;
					}
				}
                &.devices {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 8px;
                    
                    img {
                        filter: invert(1)
                    }
                }
			}
		}
		.video-container {
			padding: 5px;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			margin: 0;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			gap: 5px;
			&.draw {
				z-index: 2;
			}
		}
	}
}
</style>