import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

export default{
    namespaced: true,
    state(){
        return{
            organizationList:[],
            //clientList:[],
        }
    },
    mutations: mutations,
    getters: getters,
    actions: actions,
}