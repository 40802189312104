import chatInstance from "@/services/NodeServiceinstance";
import {
    CHAT_ROOM_USERS_LIST,
    CREATE_CHAT_SESSION,
    DELETE_CHAT_CONVERSATION_ACTION,
    DISABLE_CHAT_NOTIFICATION_ACTION,
    GET_CHATS_LIST,
    GET_CHAT_MEDIA_ACTION,
    GROUP_CONVERSATION_LIST_ACTION,
    LEAVE_CHAT_SESSION,
    SEARCH_CHAT_USERS_ACTION,
    SINGLE_CONVERSATION_USERS_LIST_ACTION,
    UPLOAD_CHAT_ATTACHMENT
} from "@/store/storeconstants";

export default{
    async [GET_CHATS_LIST](_,payload){
        try{
            let response = '';
            let paramData = '';
            if(payload.room_id){
                paramData = `room=${payload.room_id}`;
            }else{
                paramData = `ticketId=${payload.ticket_id}`;
            }
            if(payload.page_id){
                paramData = `${paramData}&page=${payload.page_id}`;
            }
            if(payload.search_data){
                paramData = `${paramData}&searchData=${payload.search_data}`;
            }
            if(payload.chatId){
                paramData = `${paramData}&chatId=${payload.chatId}`;
            }
            if(payload.scrollType){
                paramData = `${paramData}&scrollType=${payload.scrollType}`;
            }
            response = await chatInstance.get(`chats/list?limit=10&${paramData}`);
            if(response.status == 200){
                return response;
            }
        }catch(error){
            throw 'Something went wrong, please try again later';
        }
    },
    async [UPLOAD_CHAT_ATTACHMENT](_,payload){
        let bodyFormData = new FormData();
        payload.attachment.forEach(element => {
            bodyFormData.append(`attachments`, element.original);
        });
        bodyFormData.append('room', payload.room);
        if(payload.text) bodyFormData.append('text', payload.text);
        try{
            let response = '';
            response = await chatInstance.post('chats/uploadAttachments', bodyFormData,{
                headers : {
                    'Content-Type': 'multipart/form-data',
                }
            });
            if(response.status == 200){
                return response;
            }
        }catch(error){
            throw 'Something went wrong, please try again later';
        }
    },
    async [CREATE_CHAT_SESSION](_,payload){
        let bodyFormData = {
            'room': payload.room_id,
        };
        try{
            let response = '';
            response = await chatInstance.post(`sessions/createSession`, bodyFormData);
            if(response.status == 200){
                return response.data;
            }
        }catch(error){
            throw 'Something went wrong, please try again later';
        }
    },

    async leaveConnection(_, payload) {
        const bodyFormData = {
            'room': payload.room_id,
            'token': payload.token
        };
        
        try{
            const response = await chatInstance.post(`sessions/leaveConnection`, bodyFormData);
            if(response.status == 200) return response.data;
        }catch(error){
            throw 'Something went wrong, please try again later';
        }
    },

    async getRoomCallStatus(_, payload) {
        try{
            const response = await chatInstance.post(`sessions/getSession`, payload);
            if(response.status === 200) return response.data.data;
        }catch(error){
            throw 'Something went wrong, please try again later';
        }
    },
    
    async [LEAVE_CHAT_SESSION](_,payload){
        let bodyFormData = {
            'room': payload.room_id,
            'token': payload.token
        };
        try{
            const response = await chatInstance.post(`sessions/leaveConnection`, bodyFormData);
            if(response.status == 200){
                return response.data;
            }
        }catch(error){
            throw 'Something went wrong, please try again later';
        }
    },
    async [CHAT_ROOM_USERS_LIST](_,payload){
        try{
            let response = '';
            let roomId = payload.room_id;
            response = await chatInstance.get(`chats/roomDetails/${roomId}`);
            if(response.status == 200){
                return response;
            }
        }catch(error){
            throw 'Something went wrong, please try again later';
        }
    },
    async [SEARCH_CHAT_USERS_ACTION](_,payload){
        try{
            let response = '';
            let paramData = `organizationId=${payload.org_id}`;
            if(payload.search_data){
                paramData = `${paramData}&searchData=${payload.search_data}`;
            }
            if(payload.room_id){
                paramData = `${paramData}&room=${payload.room_id}`;
            }
            response = await chatInstance.get(`users/list?${paramData}`);
            if(response.status == 200){
                return response.data;
            }
        }catch(error){
            throw 'Something went wrong, please try again later';
        }
    },
    async [SINGLE_CONVERSATION_USERS_LIST_ACTION](_,payload){
        try{
            let response = '';
            let paramData = `organizationId=${payload.org_id}`;
            if(payload.page_id){
                paramData = `${paramData}&page=${payload.page_id}`;
            }
            response = await chatInstance.get(`chats/users?limit=100&${paramData}`);
            if(response.status == 200){
                return response.data;
            }
        }catch(error){
            throw 'Something went wrong, please try again later';
        }
    },
    async [GROUP_CONVERSATION_LIST_ACTION](_,payload){
        try{
            let response = '';
            let paramData = `organizationId=${payload.org_id}`;
            if(payload.page_id){
                paramData = `${paramData}&page=${payload.page_id}`;
            }
            response = await chatInstance.get(`chats/rooms?limit=100&${paramData}`);
            if(response.status == 200){
                return response.data;
            }
        }catch(error){
            throw 'Something went wrong, please try again later';
        }
    },
    // -----
    async [DISABLE_CHAT_NOTIFICATION_ACTION](){
        let bodyFormData = {
            'room': 1,
            'token': 1
        };
        try{
            // let response = '';
            // response = await chatInstance.post(`sessions/leaveConnection`, bodyFormData);
            // if(response.status == 200){
            //     return response.data;
            // }
            if(bodyFormData){
                return true;
            }
        }catch(error){
            throw 'Something went wrong, please try again later';
        }
    },
    async [DELETE_CHAT_CONVERSATION_ACTION](){
        let bodyFormData = {
            'room': 1,
            'token': 1
        };
        try{
            // let response = '';
            // response = await chatInstance.post(`sessions/leaveConnection`, bodyFormData);
            // if(response.status == 200){
            //     return response.data;
            // }
            if(bodyFormData){
                return true;
            }
        }catch(error){
            throw 'Something went wrong, please try again later';
        }
    },
    async [GET_CHAT_MEDIA_ACTION](_,payload){
        try{
            let response = '';
            let paramData = `room=${payload.room_id}`;
            let limit = payload.limit ? payload.limit : 10;
            if(payload.page_id){
                paramData = `${paramData}&page=${payload.page_id}`;
            }
            paramData = `${paramData}&type=${payload.type}`;
            response = await chatInstance.get(`chats/attachments?limit=${limit}&${paramData}`);
            if(response.status == 200){
                return response.data;
            }
        }catch(error){
            throw 'Something went wrong, please try again later';
        }
    }
}