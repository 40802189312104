import axiosInstance from '@/services/AxiosTokenInstance';
import {
    GET_ORGANIZATIONS_ACTION,
    SET_ORGANIZATIONS_LIST_MUTATION,
} from "@/store/storeconstants";

export default{
    async [GET_ORGANIZATIONS_ACTION](context){
        let response = '';
        try{
            response = await axiosInstance.get('organizations-list');
        }catch(error){
            throw error.response;
        }
        if(response.status == 200){
            let orgResponse = response.data.data;
            context.commit(SET_ORGANIZATIONS_LIST_MUTATION, orgResponse);
        }
    }
}