const formatMessage = {
	methods: {
		formatMessage(message) {
			return message
			.replace(/<[^>]*>?/gm, '')
			.replace(/_((?:\w[^_]*\w)|\w)_/g, '<em>$1</em>')
			.replace(/\*((?:\w[^*]*\w)|\w)\*/g, '<b>$1</b>');
		},

		clearMessageFormat(message) {
			return message
			.replace(/<[^>]*>?/gm, '')
			.replace(/_((?:\w[^_]*\w)|\w)_/g, '$1')
			.replace(/\*((?:\w[^*]*\w)|\w)\*/g, '$1');
		},
	},
};

export default formatMessage;