<template>
	<div class="item">
		<label>
			<span>{{item.label}}</span>
			<span v-if="item.required"> *</span>
		</label>
		<div class="select" v-click-outside="handleOutside">
			<div class="sel" v-if="!down" @click="down = true">
				<span v-if="!Object.keys(selectedItem).length">Select a {{item.label}}</span>
				<div class="selItem" v-else>
					<figure v-if="selectedItem.image">
						<img :src="selectedItem.image" alt="">
					</figure>
					<span>{{selectedItem.name}}</span>
				</div>
			</div>
			<input v-else class="chosen-value" type="text" v-model="searchKey" placeholder="Type to filter">
			<transition name="slide-down">
				<ul class="value-list" v-if="down">
					<li v-if="Object.keys(selectedItem).length && !searchKey.length" @click="handleRemove">
						<span class="rem">Remove Selected</span>
					</li>
					<template v-for="(opt, n) in filteredOptions" :key="n">
						<li @click="handleSelect(opt)">
							<figure v-if="opt.image">
								<img :src="opt.image" alt="">
							</figure>
							<span>{{opt.name}}</span>
						</li>
					</template>
				</ul>
			</transition>
		</div>
	</div>
    <Select v-if="Object.keys(selectedItem).length && item.relate && finalItem.relate.options.length" :item="relatedItems" @data="handleData"/>
</template>

<script>
import Select from './Select.vue'
import api from "@/mixins/optionsApi";
	export default {
		props: {
			item: {
				type: Object
			}
		},

		data() {
			return {
				down: false,
				options: [],
				filteredOptions: [],
				searchKey: '',
				selectedItem: {},
                finalItem: { ...this.item },
                relatedItems: {}
			}
		},

        computed: {
            relate_api() {
                return this.item.relate.options
            }
        },

        emits: ['data'],

        mixins: [api],

        components: {
            Select
        },

		watch: {
			options(val) {
				this.filteredOptions = val
			},
			searchKey(val) {
				if(val.length) this.filteredOptions = this.options.filter(el => el.name.toLowerCase().includes(val.toLowerCase()))
				else this.filteredOptions = this.options
			},
			selectedItem: {
				deep: true,
				handler(val) {
					this.$emit('data', [this.item.name, val])
				}
			},
            item: {
				deep: true,
				handler() {
                    this.optionsResponse()
                }
            }
		},

		methods: {
			async optionsResponse() {
				const isPromise = (promise) => {
					return !!promise && typeof promise.then === "function";
				};

				if (isPromise(this.item.options)) {
                    const values = await this.item.options
                    this.options = values
				} else this.options = this.item.options;
			},

			handleOutside() {
				this.down = false
			},

			async handleSelect(item) {
				this.selectedItem = item
				this.down = false

				setTimeout(() => {
					this.searchKey = ''
				}, 200);

                this.relatedItems = {}

                if (this.item.relate) {
                    const values = await this.api(this.relate_api, [`${this.item.name}Id`,item.id])

                    for (const key in this.finalItem.relate) {
                        if (key === 'options') {
                            this.relatedItems[key] = values
                        }
                        else {
                            this.relatedItems[key] = this.finalItem.relate[key]
                        }
                    }
                }
			},

			handleRemove() {
				this.down = false
				this.selectedItem = {}
			},

            handleData(data) {
				this.$emit('data', [data[0], data[1]])
            }
		},

		mounted() {
			this.optionsResponse()
		},
	}
</script>

<style lang="scss" scoped>
	.slide-down-enter-active {
	animation: slide-down 0.5s;
	}
	.slide-down-leave-active {
	animation: slide-down 0.5s reverse;
	}
	label {
		font-weight: 500;
        font-size: 1rem;
		color: var(--primary-color);
	}

	.select {
		width: 100%;
		display: flex;
		flex-direction: column;
		.sel, input {
			font-weight: 500;
			border: solid 1px #cad6e2;
			border-radius: 2px;
			padding: 10px 15px;
			user-select: none;
		}

		.sel {
			cursor: pointer;

			.selItem {
				display: flex;
				align-items: center;
				gap: 10px;
				figure {
					width: 20px;
					height: 20px;
					border-radius: 50%;
					overflow: hidden;
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}

		ul {
			height: 100%;
			max-height: 200px;
			overflow: auto;
			border: solid 1px #cad6e2;
			border-top: none;
            
			li {
				padding: 10px;
				user-select: none;
				cursor: pointer;
				border-bottom: 1px solid #eeeeee;
				display: flex;
				align-items: center;
				gap: 10px;
				figure {
					width: 30px;
					height: 30px;
					border-radius: 50%;
					overflow: hidden;
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						height: 100%;
						object-fit: cover;
					}
				}


				.rem {
					color: $red;
				}
			}
		}
	}

@keyframes slide-down {
  0% {
    opacity: 0;
    height: 0px;
    overflow: hidden;
  }
  100% {
    opacity: 1;
    overflow: hidden;
  }
}

</style>