<template>
    <div class="wrap">
        <span class="info">this {{what}} has already been created.</span>
        <span class="back" @click="goToLogin">Press here to login</span>
    </div>
</template>

<script>
export default {
    props: {
        what: {
            required: true,
            type: String,
        },
    },

    methods: {
        goToLogin() {
            this.$router.push('/');
        }
    }
}
</script>

<style scoped>
    .wrap {
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 50px;
    }
    .wrap .info{
        font-size: 2rem;
        font-weight: bold;
        text-transform: uppercase;
    }
    .wrap .back{
        margin-top: 10px;
        background: var(--primary-color);
        color: white;
        border-radius: 30px;
        padding: 10px 80px;
        cursor: pointer;
        transition: 0.2s;
    }
    .wrap .back:hover{
        background: var(--primary-hover);
    }
</style>