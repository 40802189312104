import { createI18n } from 'vue-i18n';

import en from './lang/en';
import pt from './lang/pt';
import bn from './lang/bn';

export default createI18n({
    legacy: false,
    locale: localStorage.getItem('language') ? localStorage.getItem('language') : "en",
    fallbackLocale: "en",
    globalInjection: true,
    messages:{
        en: en,
        bn: bn,
        pt: pt,
    },
});