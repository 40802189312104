import axiosInstance from "@/services/AxiosTokenInstance";
import {
    CHANGE_TICKET_PRIORITY_ACTION,
    GET_TICKET_COUNT_WITH_PRIORITY,
    GET_TICKET_DETAILS_ACTION,
    GET_TICKET_LABELS_LIST_ACTION,
    GET_TICKET_OVERVIEW_LIST,
    GET_TICKET_PRIORITY_LIST_ACTION,
    GET_TICKET_STATUS_LIST_ACTION,
    UPDATE_TICKET_USERS_ASSIGNMENT_ACTION
} from "@/store/storeconstants";

export default{
    async [GET_TICKET_DETAILS_ACTION](_, payload){
        let response = '';
        try{
            response = await axiosInstance.get(`ticket-details/${payload.org_id}/${payload.titcket_id}`);
            if(response.status == 200){
                return response.data.data;
            }
        }catch(error){
            throw error.response.status;
        }
    },
    async [CHANGE_TICKET_PRIORITY_ACTION](_, payload){
        let bodyFormData = {
            id_ticket: payload.ticket_id,
            status: payload.status
        };
        try{
            let response = '';
            response = await axiosInstance.post(`ticket-priority-change`, bodyFormData);
            if(response.status == 200){
                return response.data.message;
            }
        }catch(error){
            throw 'Something went wrong, please try again later';
        }
    },
    async [UPDATE_TICKET_USERS_ASSIGNMENT_ACTION](_, payload){
        let bodyFormData = {
            id_ticket: payload.ticket_id,
            id_organization: payload.organization_id,
            users: payload.users
        };
        try{
            let response = '';
            response = await axiosInstance.post(`user-assign-ticket`, bodyFormData);
            if(response.status == 200){
                return response.data.message;
            }
        }catch(error){
            throw 'Something went wrong, please try again later';
        }
    },
    async [GET_TICKET_OVERVIEW_LIST](_, payload){
        let response = '';
        let additional_params = `${payload.org_id}`;
        if(payload.priority_id){
            additional_params = `${payload.org_id}/${payload.priority_id}`;
        }
        try{
            response = await axiosInstance.get(`ticket-overview/${additional_params}`);
            if(response.status == 200){
                return response.data.data;
            }
        }catch(error){
            throw error.response.status;
        }
    },
    async [GET_TICKET_COUNT_WITH_PRIORITY](_, payload){
        let response = '';
        try{
            response = await axiosInstance.get(`ticket-prstatus-change/${payload.org_id}`);
            if(response.status == 200){
                return response.data.data;
            }
        }catch(error){
            throw error.response.status;
        }
    },
    // Overview Section
    async [GET_TICKET_LABELS_LIST_ACTION](){
        let response = '';
        try{
            response = await axiosInstance.get(`label-list`);
            if(response.status == 200){
                return response.data.data;
            }
        }catch(error){
            throw error.response.status;
        }
    },
    async [GET_TICKET_STATUS_LIST_ACTION](){
        let response = '';
        try{
            response = await axiosInstance.get(`ticket-status-list`);
            if(response.status == 200){
                return response.data.data;
            }
        }catch(error){
            throw error.response.status;
        }
    },
    async [GET_TICKET_PRIORITY_LIST_ACTION](){
        let response = '';
        try{
            response = await axiosInstance.get(`priority-list`);
            if(response.status == 200){
                return response.data.data;
            }
        }catch(error){
            throw error.response.status;
        }
    },
}