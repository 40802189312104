import LoginValidation from "@/services/LoginValidation";
import {
    AUTH_ACTION,
    AUTH_AUTO_LOGIN_ACTION,
    AUTO_LOGOUT, LOGIN_USER,
    LOGOUT_USER,
    RESET_ORGANIZATIONS_LIST_MUTATION,
    SET_AUTO_LOGOUT_MUTATION,
    SET_USER_TOKEN_DATA,

} from "@/store/storeconstants";

let timer = '';

export default{
    [LOGOUT_USER](context){
        context.commit(SET_USER_TOKEN_DATA, {
            name: null,
            email: null,
            token: null,
        });
        context.commit(`organization/${RESET_ORGANIZATIONS_LIST_MUTATION}`, null, { root: true });
        localStorage.removeItem('userData');
        localStorage.removeItem('organization');
        localStorage.removeItem('client');
        localStorage.removeItem('U_P');
        if(timer){
            clearTimeout(timer);
        }
    },
    [AUTO_LOGOUT](context){
        context.dispatch(LOGOUT_USER);
        context.commit(SET_AUTO_LOGOUT_MUTATION);
    },
    async [LOGIN_USER](context, payload){
        return context.dispatch(AUTH_ACTION, {
            ...payload,
           url: `${process.env.VUE_APP_API_IP}login`,
        });
    },

    [AUTH_AUTO_LOGIN_ACTION](context){
        let userDataString = localStorage.getItem('userData');
        if(userDataString){
            let userData = JSON.parse(userDataString);
            // ToDO--- we have to work on it
            let expriationTime = 18000000;

            // if the token expire time is less then 10sec then we can refresh the token or make the user logout
            if(expriationTime < 10000){
                //doing auto logout
                context.dispatch(AUTO_LOGOUT);
            }else{
                setTimeout(() => {
                    context.dispatch(AUTO_LOGOUT);
                }, expriationTime);
            }
            context.commit(SET_USER_TOKEN_DATA, userData);
        }
    },

    async [AUTH_ACTION](context, payload){
        let response = payload;

        if(!response.data.errors){
            let userData = response.data.data;
            let expriationTime = +600000 * 1000;
            timer = setTimeout(() => {
                context.dispatch(AUTO_LOGOUT);
            }, expriationTime);
            let userInfo = {
                token: userData.token,
                user_type: userData.user_type,
                expiresIn: expriationTime,
            };
            localStorage.setItem('userData', JSON.stringify(userInfo));
            context.commit(SET_USER_TOKEN_DATA, userInfo);
        }else{
            let errorMessage = LoginValidation.getErrorMessageFromLogin(response.data.errors);
            throw errorMessage;
        }
    },

}
