<template>
    <div class="wrapper">
        <template v-if="register.length">
            <div class="registerWrapper">
                <div class="org-logo">
                    <img :src="logo" alt="logo">
                </div>
                <div class="title">
                    <span>Finally, make your registration</span>
                </div>
                <register :id="[user_id, client_id]" :register="register" :data="data"
                    @created="; (register = ''), (success = true)" />
            </div>
        </template>
        <template v-else-if="alreadyRegistered[0]">
            <registered :what="alreadyRegistered[1]" />
        </template>
        <template v-else-if="success">
            <successful />
        </template>
    </div>
</template>

<script>
import axiosInstance from '@/services/AxiosTokenInstance';
import Registered from '@/views/registration/pages/Registered.vue'
import Register from '@/views/registration/pages/Index.vue'
import Successful from '@/views/registration/pages/Success.vue'
export default {
    data() {
        return {
            user_id: this.$route.query.user_id ? this.$route.query.user_id : '',
            client_id: this.$route.query.client_id ? this.$route.query.client_id : '',
            org_id: this.$route.query.org_id ? this.$route.query.org_id : '',
            loc: this.$route.path.split('/')[1],
            register: '',
            alreadyRegistered: [false, ''],
            success: false,
            data: []
        }
    },


    components: {
        Registered,
        Register,
        Successful
    },

    computed: {
        logo() {
            let cssLink = document.createElement('link')
            cssLink.rel = 'stylesheet';

            if(['service.cheto.eu', 'serviceapp.cheto.eu', 'cheto.app.assistance.immera.io'].includes(window.location.hostname)) {
                cssLink.href = '/assets/css/_variables-cheto.css'
                document.head.appendChild(cssLink)
                const link = document.querySelector("link[rel~='icon']");
                link.href = window.location.origin + '/cheto.ico'
                return '/assets/images/home/logo_cheto.png'
            }
            else if(['remoteassistance.meoempresas.pt', 'meo.app.assistance.immera.io', 'remoteassistance.altice-empresas.pt'].includes(window.location.hostname)) {
                cssLink.href = '/assets/css/_variables-altice.css'
                document.head.appendChild(cssLink)
                const link = document.querySelector("link[rel~='icon']");
                link.href = window.location.origin + '/altice.ico'
                return '/assets/images/home/logo_altice.webp'
            }
            return '/assets/images/logo/icon.svg'
        }
    },

    methods: {
        async split() {
            this.data = []
            switch (this.loc) {
                case 'user-register':
                        // eslint-disable-next-line no-case-declarations
                        const fetchUser = await axiosInstance.post('check-user-registration', {user_id: this.user_id})
                        if (fetchUser.data.message === 'User already registered') this.alreadyRegistered = [true, 'user']
                        else {
                            this.data = fetchUser.data.data
                            this.register= 'user'
                        }
                    break;

                case 'register-client':
                        // eslint-disable-next-line no-case-declarations
                        const fetchClient = await axiosInstance.post('check-client-registration', {client_id: this.client_id, org_id: this.org_id})
                        if (fetchClient.data.message !== 'Client has not registered yet') this.alreadyRegistered = [true, 'client']
                        else {
                            this.data = fetchClient.data.data
                            this.register = 'client'
                        }
                    break;

                case 'register-user-client':
                        // eslint-disable-next-line no-case-declarations
                        const fullUser = await axiosInstance.post('check-user-registration', {user_id: this.user_id})
                        if (fullUser.data.message === 'User already registered') this.alreadyRegistered = [true, 'user']
                        else {
                            this.data.push(fullUser.data.data)
                        }
                        // eslint-disable-next-line no-case-declarations
                        const fullClient = await axiosInstance.post('check-client-registration', {client_id: this.client_id, org_id: this.org_id})
                        if (fullClient.data.message !== 'Client has not registered yet') {
                            if(this.alreadyRegistered[0] === true && this.alreadyRegistered[1] === 'user') this.alreadyRegistered = [true, 'both']
                            else this.alreadyRegistered = [true, 'client']
                        }
                        else {
                            this.data.push(fullClient.data.data)
                            this.register = 'both'
                        }
                    break;

                default:
                    break;
            }
        }
    },

    mounted() {
        this.split()
    }
};
</script>

<style lang="scss" scoped>
.registerWrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.registerWrapper .org-logo {
    max-width: 15rem;
    max-height: 3rem;
    overflow: hidden;
}

.registerWrapper .org-logo img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.registerWrapper .title {
    font-weight: bold;
    font-size: 2rem;
    color: #051524;
    text-align: center;

    @media only screen and (max-width: 768px) {
        font-size: 1.5rem;
    }
}</style>
