<template>
    <div class="alerts">
        <div class="item" v-for="(item, index) in alerts" :key="index">
            <span class="message">{{item}}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        alerts: {
            required: false,
            type: Array,
        },
    },
}
</script>

<style lang="scss" scoped>
.alerts{
    position: fixed;
    bottom: 2vh;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
    gap: 10px;
    .item {
        width: 90%;
        max-width: 500px;
        height: 60px;
        background: white;
        border: solid 1px #CAD6E2;
        border-radius: 5px;
        display: flex;
        align-items: center;
        border-bottom: solid 2px var(--primary-color);
        .message {
            width: 100%;
            padding: 10px;
        }
    }
}
</style>