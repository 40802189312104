export default[
    {
        path: "/remote-support",
        component: () => import(/* webpackChunkName: "Inbox" */ "./pages/Inbox/Index.vue"),
        meta: {
          auth: true,
          layout: 'app-layout',
          organization: true,
          additionalbar : true,
          barlayout: 'main-sidebar',
          main: 'remote-support'
        }
    },
    {
        path: "/remote-support/tickets",
        name: "inbox",
        component: () => import(/* webpackChunkName: "Inbox" */ "./pages/Inbox/Index.vue"),
        meta: {
          auth: true,
          layout: 'app-layout',
          organization: true,
          additionalbar : true,
          barlayout: 'main-sidebar',
          main: 'remote-support'
        }
    },
    {
        path: "/remote-support/tickets/:ticketid",
        component: () => import("./pages/Ticket/Index.vue"),
        meta: {
          auth: true,
          layout: 'app-layout',
          organization: true,
          additionalbar : true,
          barlayout: 'main-sidebar',
          main: 'remote-support'
        }
    },
    {
      path: "/remote-support/overview",
      component: () => import(/* webpackChunkName: "Overview" */ "./pages/Overview/Index.vue"),
      meta: {
        auth: true,
        layout: 'app-layout',
        organization: true,
        additionalbar : true,
        barlayout: 'main-sidebar',
        main: 'remote-support'
      }
  },
  {
      path: "/remote-support/my-tickets",
      component: () => import(/* webpackChunkName: "MyTickets" */ "./pages/MyTickets/Index.vue"),
      meta: {
        auth: true,
        layout: 'app-layout',
        organization: true,
        additionalbar : true,
        barlayout: 'main-sidebar',
        main: 'remote-support'
      }
  },
  {
      path: "/remote-support/history",
      component: () => import(/* webpackChunkName: "History" */ "./pages/History/Index.vue"),
      meta: {
        auth: true,
        layout: 'app-layout',
        organization: true,
        additionalbar : true,
        barlayout: 'main-sidebar',
        main: 'remote-support'
      }
  },
  {
      path: "/remote-support/history/:ticketid",
      component: () => import(/* webpackChunkName: "History" */ "./pages/History/Info/Index.vue"),
      meta: {
        auth: true,
        layout: 'app-layout',
        organization: true,
        additionalbar : true,
        barlayout: 'main-sidebar',
        main: 'remote-support'
      }
  },
  {
    path: "/remote-support/ticket-forms",
    component: () => import(/* webpackChunkName: "History" */ "./pages/Forms/Index.vue"),
    meta: {
      auth: true,
      layout: 'app-layout',
      organization: true,
      additionalbar : true,
      barlayout: 'main-sidebar',
      main: 'remote-support'
    }
  },
  {
    path: "/remote-support/ticket-types",
    component: () => import(/* webpackChunkName: "History" */ "./pages/TicketTypes/Index.vue"),
    meta: {
      auth: true,
      layout: 'app-layout',
      organization: true,
      additionalbar : true,
      barlayout: 'main-sidebar',
      main: 'remote-support'
    }
  },
  {
    path: "/remote-support/assistance-types",
    component: () => import(/* webpackChunkName: "History" */ "./pages/AssistanceTypes/Index.vue"),
    meta: {
      auth: true,
      layout: 'app-layout',
      organization: true,
      additionalbar : true,
      barlayout: 'main-sidebar',
      main: 'remote-support'
    }
  },
]
