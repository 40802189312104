import Validations from "./Validations";

export default class LoginValidation{
    constructor(email, password){
        this.email = email;
        this.password = password;
    }

    checkVelidations(){
        let errors = [];

        // Email validation
        if(!Validations.checkEmail(this.email)){
            errors['email'] = 'provide-valid-email';
        }

        // Password validation
        if(!Validations.minLength(this.password, 1)){
            errors['password'] = 'provide-password';
        }

        return errors;
    }

    static getErrorMessageFromCode(errorCode, errors){
        let errorsObj = {};
        if(errorCode == 422){
            if(errors.email){
                errorsObj.email = errors.email[0];
            }
        }
        return errorsObj;
    }

    static getErrorMessageFromLogin(errorCode){
        let errorm = '';
        if(errorCode){
            errorm = 'server-login-error';
        }
        return errorm;
    }

    static getErrorMessageFromProfile(errorCode){
        let errorm = '';
        if(errorCode){
            errorm = 'Something went wrong, please try again later';
        }
        return errorm;
    }
}