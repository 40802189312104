// Auth related consts
export const LOGIN_USER = '[auth >> actions] login user';
export const AUTH_ACTION = '[auth >> actions] do user auth';
export const AUTH_AUTO_LOGIN_ACTION = '[auth >> action] auth auto login';
export const AUTO_LOGOUT = '[auth >> action] auto logout';
export const LOGOUT_USER = '[auth >> actions] logout user';
export const SET_USER_TOKEN_DATA = '[auth >> Mutations] set user token data';
export const SET_AUTO_LOGOUT_MUTATION = '[auth >> Mutations] set auto logout ';
export const LOADING_SPINNER_SHOW_MUTATION = '[auth >> Mutations] show loading spinner';
export const GET_USER_TOKEN_GETTER = '[auth >> Getters] save auth user token';
export const IS_USER_AUTHENCATED = '[auth >> Getters] check user authencation';


// Account related consts
export const GET_USER_PROFILE_ACTION = '[account >> actions] get user profile details';
export const SET_USER_PROFILE_MUTATION = '[account >> Mutations] set user profile details';
export const GET_USER_PROFILE_GETTER = '[account >> Getters] get user profile details';
export const UPDATE_USER_PROFILE_ACTION = '[account >> actions] update user profile details';
export const CHANGE_USER_PASSWORD = '[account >> actions] change user password';
// Organization related consts
export const GET_ORGANIZATIONS_GETTER = '[organization >> Getters] get organizations list';
export const GET_ORGANIZATIONS_ACTION = '[organization >> actions] get organizations list';
export const SET_ORGANIZATIONS_LIST_MUTATION = '[organization >> Mutations] set organizations list';
export const RESET_ORGANIZATIONS_LIST_MUTATION = '[organization >> Mutations] reset organizations list';
// export const GET_CLIENTS_GETTER = '[organization >> Getters] get clients list';
// export const GET_CLIENTS_ACTION = '[organization >> actions] get organizations list';
// export const SET_CLIENTS_LIST_MUTATION = '[organization >> Mutations] set client list';
// export const RESET_CLIENTS_LIST_MUTATION = '[organization >> Mutations] reset client list';


// Site related consts
export const CREATE_SITE_ACTION = '[site >> actions] create site';
export const ADD_USERS_TO_SITE_ACTION = '[site >> actions] add users to site';
export const GET_SITE_DETAILS_ACTION = '[site >> actions] get site details';
export const UPDATE_SITE_ACTION = '[site >> actions] update site details';
export const DELETE_PROJECT_ACTION = '[site >> actions] delete project';
// User related consts
export const SEARCH_USERS_BY_KEY = '[user >> actions] search users by name or email';
export const GET_USER_ROLES = '[user >> actions] roles list';
export const GET_USER_DETAILS_ACTION = '[user >> actions] get user details';
export const CREATE_USER_ACTION = '[user >> actions] create user';
export const UPDATE_USER_DETAILS = '[user >> actions] update details user';
export const GET_USERS_LIST_ACTION = '[user >> actions] get users list specific to organization';
// Ticket related consts
export const GET_TICKETS_ACTION = '[ticket >> actions] tickets list';
export const GET_MY_TICKETS_ACTION = '[ticket >> actions] tickets list';
export const GET_TICKET_DETAILS_ACTION = '[ticket >> actions] get details of a ticket';
export const CHANGE_TICKET_PRIORITY_ACTION = '[ticket >> actions] change ticket priority';
export const UPDATE_TICKET_USERS_ASSIGNMENT_ACTION = '[ticket >> actions] update user assignment on a ticket';
// Overview related consts
export const GET_TICKET_OVERVIEW_LIST = '[ticket >> actions] tickets overview list';
export const GET_TICKET_COUNT_WITH_PRIORITY = '[ticket >> actions] get various ticket count with priority';
export const GET_TICKET_LABELS_LIST_ACTION = '[ticket >> actions] get ticket labels list';
export const GET_TICKET_STATUS_LIST_ACTION = '[ticket >> actions] get ticket status list';
export const GET_TICKET_PRIORITY_LIST_ACTION = '[ticket >> actions] get ticket priority list';
// Chat related consts
export const GET_CHATS_LIST = '[chat >> actions] chats list';
export const UPLOAD_CHAT_ATTACHMENT = '[chat >> actions] upload files';
export const CREATE_CHAT_SESSION = '[chat >> actions] create new session';
export const LEAVE_CHAT_SESSION = '[chat >> actions] leave chat session';
export const CHAT_ROOM_USERS_LIST = '[chat >> actions] leave chat session';
export const SEARCH_CHAT_USERS_ACTION = '[chat >> actions] Search chat users';
export const SINGLE_CONVERSATION_USERS_LIST_ACTION = '[chat >> actions] Search one to one chat conversation users';
export const GROUP_CONVERSATION_LIST_ACTION = '[chat >> actions] Search group chat list';
export const DISABLE_CHAT_NOTIFICATION_ACTION = '[chat >> actions] Disable chat notification for a single chat or group chat';
export const DELETE_CHAT_CONVERSATION_ACTION = '[chat >> actions] Delete a existing chat conversation';
export const GET_CHAT_MEDIA_ACTION = '[chat >> actions] Get all the media related to chats';
//Product related consts
export const CREATE_PRODUCT_ACTION = '[product >> actions] create site';
export const CREATE_IMAGE_ACTION ='[product >> actions] create image';
export const GET_PRODUCT_DETAILS_ACTION = '[product >> actions] get product details';
export const UPDATE_PRODUCT_ACTION = '[product >> actions] update product details';
export const GET_IMAGE_DETAILS_ACTION = '[product >> actions] get product image details';
export const UPDATE_IMAGE_ACTION = '[product >> actions] update product image details';
export const CREATE_PROD_PROJ_ACTION = '[product >> actions] create project in product';
export const GET_PROJ_DETAILS_ACTION = '[product >> actions] get details on product in project';
export const UPDATE_PROJ_ACTION = '[product >> actions] update details on project in product';

//Document related consts
export const CREATE_DOCUMENT_ACTION = '[document >> actions] create document';
export const GET_DOCUMENT_DETAILS_ACTION = '[document >> actions] get document details';
export const UPDATE_DOCUMENT_ACTION = '[document >> actions] update document details';
//TroubleShoot related
export const GET_STEP_DETAILS_ACTION = '[troubleshoot >> actions] get step details';
export const CREATE_TROUBLE_ACTION = '[troubleshoot >> actions] create guide';
export const CREATE_STEP_ACTION = '[troubleshoot >> actions] create step';
export const CREATE_STEP_DOCUMENT_ACTION = '[troubleshoot >> actions] create step document';
export const CREATE_STEP_IMAGE_ACTION = '[troubleshoot >> actions] create step image';
export const GET_DETAIL_STEPS_ACTION = '[troubleshoot >> actions] create step image';
export const UPDATE_STEP_ACTION = '[troubleshoot >> actions] update step action ';
export const UPDATE_STEPIMAGE_ACTION = '[troubleshoot >> actions] update step image action ';
export const GET_STEPIMAGE_DETAILS_ACTION = '[troubleshoot >> actions] get stepimage action ';
//Category Related
export const CREATE_CATEGORY_ACTION = '[category >> actions] create category';
export const GET_CATEGORY_DETAILS_ACTION = '[category >> actions] get category details';
export const UPDATE_CATEGORY_ACTION = '[category >> actions] update category';
export const DELETE_CATEGORY_ACTION = '[category >> actions] delete category';
//FAQ related
export const CREATE_FAQ_ACTION = '[faq >> actions] create faq';
export const GET_FAQ_DETAILS_ACTION = '[faq >> actions] get faq details';
export const DELETE_FAQ_ACTION = '[faq >> actions] delete faq details';
export const UPDATE_FAQ_ACTION = '[faq >> actions] delete faq details';
// Client related consts
export const CREATE_CLIENT_ACTION = '[client >> actions] create client';
export const GET_CLIENT_DETAILS_ACTION = '[client >> actions] get client details';
export const UPDATE_CLIENT_ACTION = '[client >> actions] update client details';
export const DELETE_CLIENT_ACTION = '[client >> actions] delete client';
export const CLIENT_REGISTER = '[client >> actions] register client';
export const CREATE_CLIENT_USER_ACTION = '[client >> actions] add client user';
export const GET_CLIENTUSER_DETAILS_ACTION = '[client >> actions] show client user';


