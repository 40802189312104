import { SET_AUTO_LOGOUT_MUTATION, SET_USER_TOKEN_DATA } from "@/store/storeconstants";

export default{
    [SET_USER_TOKEN_DATA](state, payload){
        state.email = payload.email;
        state.name = payload.name;
        state.token = payload.token;
        state.autoLogout = false;
    },

    [SET_AUTO_LOGOUT_MUTATION](state){
        state.autoLogout = true;
    }
}