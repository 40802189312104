<template>
    <main class="desk" v-if="!isMobile()">
        <div class="slide-btn" v-if="addSidebar" :class="{opened: isOpen}" @click="isOpen = !isOpen">
            <span class="arrow" :class="{opened: isOpen}" />
        </div>
        <Organization />
        <Modules :unreads="unreads" />
        <Settings v-if="[4, 6].includes(getUserProfile.role?.id)" />
    </main>
    <template v-else>
        <Mobile :is-open="isOpen" @switchOpen="handleOpen"/>
    </template>
    <additionalSidebar v-if="addSidebar" :data="from" :isOpened="isOpen"/>
</template>

<script>
import Modules from './Desk/Modules.vue'
import Organization from './Desk/Organization.vue'
import Settings from './Desk/Settings.vue'
import { mapGetters } from 'vuex'
import { GET_USER_PROFILE_GETTER } from "@/store/storeconstants";
import isMobile from '@/mixins/isMobile'
import Mobile from './Mobile/Index.vue'
import nodeInstance from '@/services/NodeServiceinstance';
import additionalSidebar from '@/layouts/app/additional_sidebar/Sidebar.vue'

export default {
    components: {
        Modules,
        Organization,
        Settings,
        Mobile,
        additionalSidebar
    },

    mixins: [isMobile],

    data() {
        return {
            org: localStorage.organization ? JSON.parse(localStorage.organization) : {},
            unreads: {},
            user: localStorage['U_P'] ? JSON.parse(localStorage['U_P']) : {},
            from: this.$route.meta.main,
            addSidebar: false,
            isOpen: true
        }
    },

    computed: {
        ...mapGetters("account", {
            getUserProfile: GET_USER_PROFILE_GETTER,
        }),
    },

    watch: {
        $route: {
            immediate: true,
            handler(val) {
                this.from = val.meta.main
                this.addSidebar = val.meta?.additionalbar || false

                if (!this.addSidebar) {
                    this.isOpen = false
                }
            }
        },

        isOpen: {
            immediate: true,
            handler(val) {
                this.emitter.emit('opensidebar', val)
            }
        },
    },

    mounted() {
        this.getChatNotifications()
        this.emitter.on("new-message", (data) => {
            const receivers = data.receiverUsers.map(el => el.userId)
            if (receivers.includes(this.user.id)) {
                this.getChatNotifications()
            }
        });
        this.emitter.on("read-message", () => this.getChatNotifications());
        
    },

    methods: {
        async getChatNotifications() {
            const response = await nodeInstance.get(`/chats/unreadCounts?organizationId=${this.org.id}`)
            if (response.status === 200) this.unreads = response.data
        },

        handleOpen(val) {
            this.isOpen = val
        }
    }
}
</script>

<style lang="scss" scoped>
    .desk {
        width: 8rem;
        position: sticky;
        height: 100vh;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        z-index: 150;
        background: var(--bar);

        @media only screen and (max-width: 768px) {
            width: 4rem;
            justify-content: space-between;
            padding: 20px 0;
        }
    }

    .slide-btn {
        position: fixed;
        top: 30px;
        left: calc(8rem - 15px);
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        cursor: pointer;
        z-index: 100;
        transition: 0.1s;

        &.opened {
            left: calc(300px + 8rem - 15px);
        }

        @media only screen and (max-width: 1200px) {
            background: none;
            box-shadow: unset;
        }

        .arrow {
            width: 10px;
            height: 10px;
            position: relative;

            &.opened {
                &::before {
                    transform: rotate(-135deg);
                    left: 2.5px;
                }
            }

            &::before {
                position: absolute;
                left: -2.5px;
                content: '';
                width: 10px;
                height: 10px;
                border: solid 2px;
                border-color: black black transparent transparent;
                transform: rotate(45deg);
                transform: .2s;
            }
        }
    }
</style>