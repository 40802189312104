import axiosInstance from "@/services/AxiosTokenInstance";
import { 
    CREATE_CLIENT_ACTION,
    CREATE_CLIENT_USER_ACTION,
    CLIENT_REGISTER,  
    GET_CLIENT_DETAILS_ACTION,
    UPDATE_CLIENT_ACTION,
    GET_CLIENTUSER_DETAILS_ACTION
} from "@/store/storeconstants";

export default{
    async [CREATE_CLIENT_ACTION](_,payload){
        let orgId = JSON.parse(localStorage.getItem('organization')).id;        
        let bodyFormData = {
            'name': payload.name,
            'email': payload.email,
            'id_organization': orgId,
            'address':payload.address,
            'active':0
        };
        try{
            let response = '';
            response = await axiosInstance.post('addclient', bodyFormData);  
            if(response.status == 200){ 
                return response.data.data.client_id;
            }
        }catch(error){
            throw 'Something went wrong.';
        }
    },
    async [CREATE_CLIENT_USER_ACTION](_,payload){
        let clientId = JSON.parse(localStorage.getItem('organization')).id;        
        let bodyFormData = {
            'name': payload.name,
            'email': payload.email,
            'id_client': clientId,           
            'active':1
        };
        try{
            let response = '';
            response = await axiosInstance.post('adduserclient', bodyFormData);  
            if(response.status == 200){ 
                return response.data.data.client_id;
            }
        }catch(error){
            throw 'Something went wrong.';
        }
    },
    async [CLIENT_REGISTER](_,payload){
        let orgId = JSON.parse(localStorage.getItem('organization')).id;        
        let bodyFormData = {
            'id_user': payload.id_user,
            'name': payload.name,
            'password': payload.password,
            'id_organization': orgId,
            'address': payload.address,
            'postcode': payload.postcode,
            'active':1
        };
        try{
            let response = '';
            response = await axiosInstance.post('client-register', bodyFormData);  
            if(response.status == 200){ 
                return response.data.data.client_id;
            }
        }catch(error){
            throw 'Something went wrong.';
        }
    },
    
    async [UPDATE_CLIENT_ACTION](_,payload){
        let response = '';
        let orgId = JSON.parse(localStorage.getItem('organization')).id;        
        let bodyFormData = {
            'name': payload.project_name,
            'description': payload.project_desription,
            'organization_id': orgId,
            'id_project':payload.id
        };
        try{
            response = await axiosInstance.post(`editproject`, bodyFormData);
            if(response.status == 200){
                return response.data.data;
            }
        }catch(error){
            throw error.response.status;
        }
    },
    async [GET_CLIENT_DETAILS_ACTION](_,payload){
        let response = '';
        try{
            response = await axiosInstance.get(`projectdetails/${payload.site_id}`);
           // console.log("response now",response.data.data)
            if(response.status == 200){
                return response.data.data;
            }
        }catch(error){
            throw error.response.status;
        }
    },
    async [GET_CLIENTUSER_DETAILS_ACTION](_,payload){
        let response = '';
        try{
            response = await axiosInstance.get(`client-user-detail/${payload.user_id}`);
           // console.log("response now",response.data.data)
            if(response.status == 200){
                return response.data.data;
            }
        }catch(error){
            throw error.response.status;
        }
    },
}