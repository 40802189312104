<template>
    <form @submit.prevent="sendData" id="user-form" class="user-form">
        <div class="single">
            <div class="profile-pic">
                <div class="import-image" ref="impBtn">
                    <label>
                        <input type="file" accept="image/png, image/jpeg" @change="importImage">

                        <template v-if="!userImg">
                            <img src="/assets/images/photo-icon.png" alt="">
                        </template>
                        <template v-else>
                            <img :src="userImg" class="importedImg">
                        </template>
                    </label>
                </div>
            </div>
        </div>
        <div class="double">
            <div class="item">
                <label for="first">First Name</label>
                <input type="text" v-model="formData['firstName']" placeholder="Your first name" required>
            </div>
            <div class="item">
                <label for="last">Last Name</label>
                <input type="text" v-model="formData['lastName']" placeholder="Your last name" required>
            </div>
        </div>
        <div class="double">
            <div class="item">
                <label for="pn">Phone Number</label>
                <input type="number" v-model="formData['phoneNumber']" placeholder="(+351) 999999999">
            </div>
            <div class="item">
                <label for="email">Email</label>
                <input type="email" v-model="formData['email']" class="email" readonly>
            </div>
        </div>
        <div class="row">
            <div class="item">
                <label for="countries">Country</label>
                <select name="countries" v-model="formData['country']" id="" required>
                    <option value="" selected disabled>Select your Country</option>
                    <option v-for="(country, index) in countries" :key="index" :value="country.name">{{ country.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="double">
            <div class="item">
                <label for="pw">Password</label>
                <input type="password" v-model="formData['pw']" placeholder="Your password" required>
            </div>
            <div class="item">
                <label for="cpw">Confirm Password</label>
                <input type="password" v-model="formData['c-pw']" placeholder="Your password" required>
            </div>
        </div>
    </form>
    <div class="submit">
        <button type="submit" form="user-form">confirm registration</button>
    </div>

    <div class="error" v-if="wrongPw" @click="wrongPw = false">
        <span>The inserted passwords do not match.</span>
    </div>

    <div class="error" v-if="error" @click="error = false">
        <span>Something went wrong.<br />We're sorry for the inconvenience.</span>
    </div>

    <div v-if="loader" class="loading">
        <span class="loader"></span>
    </div>
</template>

<script>
import axiosInstance from '@/services/AxiosTokenInstance';

export default {
    props: {
        user_id: {
            required: true,
            type: Number,
        },
        user_data: {
            required: true,
            type: Array,
        }
    },

    data() {
        return {
            formData: {country: '', language: '', image: [], firstName: this.user_data.name.split(' ')[0], lastName: this.user_data.name.split(' ')[1] ? this.user_data.name.split(' ')[1] : '', email: this.user_data.email},
            error: false,
            userImg: '',
            countries: [],
            loader: false,
            wrongPw: false,
            responseStatus: 0,
        }
    },

    methods: {
        async sendData() {
            this.wrongPw = false
            this.error = false

            if (this.formData.pw !== this.formData['c-pw']) {
                this.wrongPw = true
            }
            else {
                this.loader = true
                let toSend = new FormData();

                toSend.append('avatar', this.formData.image.originalFile)
                toSend.append('user_id', this.user_id)
                toSend.append('name', `${this.formData.firstName} ${this.formData.lastName}`)
                toSend.append('password', this.formData.pw)
                toSend.append('phone', this.formData.phoneNumber)
                toSend.append('country', this.formData.country)

                await axiosInstance.post('org-user-registration-completion', toSend,{
					headers : {
						'Content-Type': 'multipart/form-data'
					}
				}).then(response => {
                    this.responseStatus = response.status
                }).finally(() => {
                    this.loader = false
                    if (this.responseStatus === 200) {
                        this.$emit('created')
                    }
                    else this.error = true
                })
            }

        },

        importImage() {
            const file = event.target.files[0]

				if (FileReader && file) {
					const fr = new FileReader();
					fr.readAsDataURL(file);
					fr.onload = () => {
						this.formData.image = {name: file.name, link: fr.result, originalFile: file}
                        this.userImg = fr.result
					}
				}
        }
    },

    async mounted() {
        const fetchCountries = await axiosInstance.get(`countries-list`);
        this.countries = fetchCountries.data.data
    }
}
</script>

<style lang="scss" scoped>
.user-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 25px;
}

.user-form .single {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.user-form .single .profile-pic .import-image {
    background: #F2F5F8;
    color: var(--primary-color);
    font-size: 3rem;
    transition: 0.2s;
    width: 100px;
    height: 100px;
    overflow: hidden;
    border: 1px solid #0515241F;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.user-form .single .profile-pic .import-image .importedImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.user-form .single .profile-pic .import-image label {
    margin: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-form .single .profile-pic .import-image label input {
    display: none;
}

.user-form .single .profile-pic .import-image:hover {
    background: var(--primary-color);
}

.user-form {
    .row {
        .item {
            display: flex;
            flex-direction: column;

            input,
            select {
                border: 1px solid #CAD6E2;
                border-radius: 2px;
                padding: 10px;

                &:focus {
                    outline: none;
                }

                &:required,
                &:invalid {
                    border-color: $red;

                    &:focus {
                        border-color: #CAD6E2;
                    }
                }

                &:valid {
                    border-color: #CAD6E2;
                }
            }
        }

    }
}

.user-form .double {
    display: flex;
    gap: 10px;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }

    .item {
        width: calc(50% - 5px);
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: 768px) {
            width: 100%;
        }

        input {
            .email {
                background: #CAD6E2;
                color: white;
            }
        }

    }
}

.user-form .double .item {

    input,
    select {
        border: 1px solid #CAD6E2;
        border-radius: 2px;
        padding: 10px;

        &:focus {
            outline: none;
        }

        &:required,
        &:invalid {
            border-color: $red;

            &:focus {
                border-color: #CAD6E2;
            }
        }

        &:valid {
            border-color: #CAD6E2;
        }
    }
}



.user-form .double .item input::placeholder {
    color: #CAD6E2;
}

.user-form .double .item input:focus,
.user-form .double .item select:focus {
    outline: none;
}

.submit {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.submit button {
    background: var(--primary-color);
    border-radius: 30px;
    padding: 10px 30px;
    color: white;
    text-transform: capitalize;
    user-select: none;
    cursor: pointer;
    transition: 0.2s;
}

.submit button:hover {
    background: var(--primary-hover);
}

.error {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    background: rgba(0, 0, 0, 0.4);
}

.error span {
    padding: 12px 40px;
    background: white;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: grey;
}

.loading {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
}

.loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid var(--primary-color);
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>