<template>
    <div class="item">
        <label>
            <span>{{item.label}}</span>
			<span v-if="item.required"> *</span>
        </label>
        <div class="editor" @keyup="handleData" @click="handleData">
            <QuillEditor ref="quill" theme="snow" toolbar="full" />
        </div>
    </div>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
export default {
    props: {
        item: {
            type: Object
        }
    },

    data() {
        return {
            data: this.item.value || ''
        }
    },

    watch: {
        data(val) {
            this.$emit('data', [this.item.name, val])
        }
    },

    components: {
        QuillEditor
    },

    methods: {
        handleData() {
            this.data = this.$refs.quill.getQuill().root.innerHTML
        }
    }
}
</script>

<style lang="scss" scoped>
    label {
        font-weight: 500;
        font-size: 1rem;
		color: var(--primary-color);
    }
    input {
        border: solid 1px #cad6e2;
        border-radius: 2px;
        padding: 10px 15px;
    }

    .editor {
        min-height: 300px;
    }
</style>