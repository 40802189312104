export default[
  {
      path: "/products",
      component: () => import(/* webpackChunkName: "Category" */ "./pages/Equipments/Index.vue"),
      meta: {
        auth: true,
        layout: 'app-layout',
        organization: true,
        additionalbar : true,
        barlayout: 'main-sidebar',
        main: 'products'
      }
  },
  {
      path: "/products/documents",
      component: () => import(/* webpackChunkName: "Document" */ "./pages/Documents/Index.vue"),
      meta: {
        auth: true,
        layout: 'app-layout',
        organization: true,
        additionalbar : true,
        barlayout: 'main-sidebar',
        main: 'products'
      }
  },
  {
      path: "/products/faqs",
      component: () => import(/* webpackChunkName: "FAQ" */ "./pages/FAQs/main/Index.vue"),
      meta: {
        auth: true,
        layout: 'app-layout',
        organization: true,
        additionalbar : true,
        barlayout: 'main-sidebar',
        main: 'products'
      }
  },
  {
    path: "/products/faq-list/:catid",
    component: () => import(/* webpackChunkName: "FAQlist" */ "./pages/FAQs/Index.vue"),
    meta: {
      auth: true,
      layout: 'app-layout',
      organization: true,
      additionalbar : true,
      barlayout: 'main-sidebar',
      main: 'products',
      pathing: true
    }
},
{
  path: "/products/guides/:catid/step-list/:guideid",
  component: () => import(/* webpackChunkName: "Steplist" */ "./pages/Guides/guides/steps/Index.vue"),
  meta: {
    auth: true,
    layout: 'app-layout',
    organization: true,
    additionalbar : true,
    barlayout: 'main-sidebar',
    main: 'products',
    pathing: true,
  }
},
  {
      path: "/products/guides/:catid",
      component: () => import(/* webpackChunkName: "Troubleshootings" */ "./pages/Guides/guides/Index.vue"),
      meta: {
        auth: true,
        layout: 'app-layout',
        organization: true,
        additionalbar : true,
        barlayout: 'main-sidebar',
        main: 'products',
        pathing: true
      }
  },
  {
    path: "/products/guides/:catid/addstep/:guideid",
    component: () => import(/* webpackChunkName: "AddStep" */ "./pages/Guides/guides/steps/NewStep.vue"),
    meta: {
      auth: true,
      layout: 'app-layout',
      organization: true,
      additionalbar : true,
      barlayout: 'main-sidebar',
      main: 'products',
      pathing: true
    },
    props: {
      isEdit: false
    }
},
{
  path: "/products/guides/:catid/editstep/:stepid",
  component: () => import(/* webpackChunkName: "EditStep" */ "./pages/Guides/guides/steps/EditStep.vue"),
  meta: {
    auth: true,
    layout: 'app-layout',
    organization: true,
    additionalbar : true,
    barlayout: 'main-sidebar',
    main: 'products',
    pathing: true
  },
  props: {
    isEdit: true
  }
},
  {
    path: "/products/guides",
    component: () => import(/* webpackChunkName: "Guides" */ "./pages/Guides/categories/Index.vue"),
    meta: {
      auth: true,
      layout: 'app-layout',
      organization: true,
      additionalbar : true,
      barlayout: 'main-sidebar',
      main: 'products'
    }
},
  {
    path: "/products/equipments",
    component: () => import(/* webpackChunkName: "Product" */ "./pages/Equipments/Index.vue"),
    meta: {
      auth: true,
      layout: 'app-layout',
      organization: true,
      additionalbar : true,
      barlayout: 'main-sidebar',
      main: 'products'
    }
  },
  {
    name: 'products',
    path: "/products/equipments/:prodid",
    component: () => import(/* webpackChunkName: "Product" */ "./pages/Equipments/Details/Index.vue"),
    props: route => ({ tickets: route.params.tickets }),
    meta: {
      auth: true,
      layout: 'app-layout',
      organization: true,
      additionalbar : true,
      barlayout: 'main-sidebar',
      main: 'products'
    }
  },
  {
    name: 'step_logs_guides',
    path: "/products/steps-logs",
    component: () => import(/* webpackChunkName: "Step Logs" */ "./pages/StepsLogs/Index.vue"),
    meta: {
      auth: true,
      layout: 'app-layout',
      organization: true,
      additionalbar : true,
      barlayout: 'main-sidebar',
      main: 'products'
    }
  },
  {
    name: 'step_logs_steps',
    path: "/products/steps-logs/:guide_id",
    component: () => import(/* webpackChunkName: "Step Logs" */ "./pages/StepsLogs/Step/Index.vue"),
    meta: {
      auth: true,
      layout: 'app-layout',
      organization: true,
      additionalbar : true,
      barlayout: 'main-sidebar',
      main: 'products'
    }
  },
  {
    name: 'step_logs_measurements',
    path: "/products/steps-logs/:guide_id/:stepid",
    component: () => import(/* webpackChunkName: "Step Logs" */ "./pages/StepsLogs/Measurements/Index.vue"),
    meta: {
      auth: true,
      layout: 'app-layout',
      organization: true,
      additionalbar : true,
      barlayout: 'main-sidebar',
      main: 'products'
    }
  },
  {
    name: 'step_logs_logs',
    path: "/products/steps-logs/:guide_id/:stepid/:measurement_id",
    component: () => import(/* webpackChunkName: "Step Logs" */ "./pages/StepsLogs/Logs/Index.vue"),
    meta: {
      auth: true,
      layout: 'app-layout',
      organization: true,
      additionalbar : true,
      barlayout: 'main-sidebar',
      main: 'products'
    }
  }
]