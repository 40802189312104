import axiosInstance from "@/services/AxiosTokenInstance";
import {
    CREATE_PRODUCT_ACTION,
    CREATE_PROD_PROJ_ACTION,
    CREATE_IMAGE_ACTION,
    GET_PRODUCT_DETAILS_ACTION,
    GET_IMAGE_DETAILS_ACTION,
    UPDATE_PRODUCT_ACTION,
    UPDATE_IMAGE_ACTION,
    UPDATE_PROJ_ACTION,
    GET_PROJ_DETAILS_ACTION
} from "@/store/storeconstants";

export default {
    namespaced: true,
    state: {
      products: [],
    },

    getters: {
        getProducts(state){
            return state.products
        },
    },

    mutations: {
        setProducts(state, payload){
            state.products = payload
        },
    },

    actions: {
        async fetchProducts({commit}) {
            let fullProducts =[]
            let orgId = JSON.parse(localStorage.getItem('organization')).id;
            let response = await axiosInstance.get(`new-product-list?org_id=${orgId}`);
            const responseData = response.data.data;
            fullProducts.push(...responseData.data)
            if(responseData.next_page_url) {
                const pages = responseData.last_page
                for (let i = 2; i <= pages.length; i++) {
                    let res = await axiosInstance.get(`new-product-list?org_id=${orgId}&page=${i}`);
                    fullProducts.push(...res.data.data.data)
                }
            }
            commit('setProducts', fullProducts)
        },
        async [CREATE_PRODUCT_ACTION](_,payload){
            let orgId = JSON.parse(localStorage.getItem('organization')).id;
            let bodyFormData = {
                'name': payload.name,
                'description': payload.description,
                'id_organization': orgId,
                //'reference_no':payload.ref_no,
                'make':payload.make,
                'id_category':payload.category,
                //'id_project':payload.project,
                'model':payload.model,
                'state':payload.state,
                'serial_no':payload.serial_no,
                'warranty_date':payload.warranty_date,
                'maintenance':payload.maintenance
            };
            try{
                let response = '';
                response = await axiosInstance.post('addproduct', bodyFormData);
                if(response.status == 200){
                    return response.data.data.product_id;

                }
            }catch(error){
                throw 'Something went wrong, please try again later';
            }
        },
        async [CREATE_PROD_PROJ_ACTION](_,payload){
            let orgId = JSON.parse(localStorage.getItem('organization')).id;
            let bodyFormData = {
                'id_project': payload.project,
                'id_organization': orgId,
                'reference_no':payload.reference_no,
                'id_product':payload.id_product,

            };
            try{
                let response = '';
                response = await axiosInstance.post('addprojtoprod', bodyFormData);
                if(response.status == 200){
                    return response.data.data.product_id;

                }
            }catch(error){
                throw 'Something went wrong, please try again later';
            }
        },

        async [UPDATE_PROJ_ACTION](_,payload){
            let response = '';
            let orgId = JSON.parse(localStorage.getItem('organization')).id;
            let bodyFormData = {
                'id':payload.id,
                'id_organization': orgId,
                'reference_no':payload.reference_no,
                'id_project':payload.project,
                'id_product':payload.id_product,
            };
            try{
                response = await axiosInstance.post(`edit-prod-proj`, bodyFormData);
                if(response.status == 200){
                    return response.data.data;
                }
            }catch(error){
                throw error.response.status;
            }
        },
        async [GET_PROJ_DETAILS_ACTION](_,payload){
            let response = '';
            //let orgId = JSON.parse(localStorage.getItem('organization')).id;
            try{
                response = await axiosInstance.get(`prod-proj-details/${payload.proj_id}`);
                
                if(response.status == 200){
                    return response.data.data;
                }
            }catch(error){
                throw error.response.status;
            }
        },
        async [UPDATE_PRODUCT_ACTION](_,payload){
            let response = '';
            let orgId = JSON.parse(localStorage.getItem('organization')).id;
            let bodyFormData = {
                'name': payload.name,
                'description': payload.description,
                'id_organization': orgId,
                'reference_no':payload.ref_no,
                'id':payload.id,
                'id_category':payload.category,
                'id_project':payload.project,
                'make':payload.make,
                'model':payload.model,
                'state':1,
                'serial_no':payload.serial_no,
                'warranty_date':payload.warranty_date,
                'maintenance':payload.maintenance
            };
            
            try{
                response = await axiosInstance.post(`edit-product`, bodyFormData);
                if(response.status == 200){
                    return response.data.data;
                }
            }catch(error){
                throw error.response.status;
            }
        },
        async [GET_PRODUCT_DETAILS_ACTION](_,payload){
            let response = '';
            let orgId = JSON.parse(localStorage.getItem('organization')).id;
            try{
                response = await axiosInstance.get(`productDetails/${orgId}/${payload.product_id}`);
                
                if(response.status == 200){
                    return response.data.data;
                }
            }catch(error){
                throw error.response.status;
            }
        },
        async [GET_IMAGE_DETAILS_ACTION](_,payload){
            let response = '';
            //let orgId = JSON.parse(localStorage.getItem('organization')).id;
            try{
                response = await axiosInstance.get(`showproductimage/${payload.img_id}`);
                
                if(response.status == 200){
                    return response.data.data;
                }
            }catch(error){
                throw error.response.status;
            }
        },
        async [CREATE_IMAGE_ACTION](_,payload){
            //let orgId = JSON.parse(localStorage.getItem('organization')).id;
            let bodyFormData = new FormData();
            if(payload.status==1){
                this.status=1;
            }
            bodyFormData.append('image', payload.file);
            bodyFormData.append('name', payload.name);
            bodyFormData.append('description', payload.description);
            bodyFormData.append('id_product', payload.product);
            bodyFormData.append('image_status', this.status);
            //bodyFormData.append('id_organization', orgId);
            
            try{
                let response = '';
                response = await axiosInstance.post('addproductimage', bodyFormData,{
                    headers : {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if(response.status == 200){
                    return 'The file uploaded successfully successfully!!';
                }
            }catch(error){
                throw 'Something went wrong, please try again later';
            }
        },

        async [UPDATE_IMAGE_ACTION](_,payload){
            //let orgId = JSON.parse(localStorage.getItem('organization')).id;
            let bodyFormData = new FormData();
            bodyFormData.append('image', payload.file);
            bodyFormData.append('id_img', payload.id);
            bodyFormData.append('name', payload.name);

            bodyFormData.append('state', payload.state);
            //bodyFormData.append('id_organization', orgId);
            
            try{
                let response = '';
                response = await axiosInstance.post('editproductimage', bodyFormData,{
                    headers : {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if(response.status == 200){
                    return 'The file uploaded successfully successfully!!';
                }
            }catch(error){
                throw 'Something went wrong, please try again later';
            }
        },
    },
  };