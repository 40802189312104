import { 
    RESET_ORGANIZATIONS_LIST_MUTATION, 
    SET_ORGANIZATIONS_LIST_MUTATION, //RESET_CLIENTS_LIST_MUTATION, SET_CLIENTS_LIST_MUTATION 
} from "@/store/storeconstants";

export default{
    [SET_ORGANIZATIONS_LIST_MUTATION](state, payload){
        //console.log(payload);
        state.organizationList = [];
        state.organizationList.push(...payload);
    },
    [RESET_ORGANIZATIONS_LIST_MUTATION](state){
        state.organizationList = [];
    },
    // [SET_CLIENTS_LIST_MUTATION](state, payload){
    //     console.log("client_list",payload);
    //     //state.clientList.push(...payload);
    // },
    // [RESET_CLIENTS_LIST_MUTATION](state){
    //     state.clientList = [];
    // }
}