import axiosInstance from "@/services/AxiosTokenInstance";
import { 
    CREATE_DOCUMENT_ACTION,
    GET_DOCUMENT_DETAILS_ACTION,
    UPDATE_DOCUMENT_ACTION
} from "@/store/storeconstants";

export default{
    async [CREATE_DOCUMENT_ACTION](_,payload){
        let orgId = JSON.parse(localStorage.getItem('organization')).id;        
        let bodyFormData = new FormData();
        bodyFormData.append('document', payload.file);
        bodyFormData.append('name', payload.name);
        bodyFormData.append('id_category', payload.category); 
        bodyFormData.append('id_product', payload.product);
        bodyFormData.append('state', payload.state);     
        bodyFormData.append('id_organization', orgId);
        try{
            let response = '';
            response = await axiosInstance.post('addproductreffile', bodyFormData,{
                headers : {
                    'Content-Type': 'multipart/form-data'
                }
            });  
            if(response.status == 200){            
                return 'The file uploaded successfully successfully!!';
            }          
        }catch(error){
            throw 'Something went wrong, please try again later';
        }
    },
    // async [ADD_USERS_TO_SITE_ACTION](_,payload){   
    //     let arrayObj = payload.users;
    //     arrayObj = arrayObj.map(item => {
    //         return {
    //             id_user: item.id,
    //         };
    //         });
    //     let bodyFormData = {
    //         'users': [...arrayObj],
    //         'id_project': payload.project_id
    //     };
    //     try{
    //         let response = '';
    //         response = await axiosInstance.post('addprojectuser', bodyFormData);  
    //         if(response.status == 200){     
    //             //The site or project id should return here       
    //             return 'The site has been created successfully!!';
    //         }
    //     }catch(error){
    //         throw 'Something went wrong, please try again later';
    //     }
    // },
    async [UPDATE_DOCUMENT_ACTION](_,payload){
        let orgId = JSON.parse(localStorage.getItem('organization')).id;        
        let bodyFormData = new FormData();
        bodyFormData.append('document', payload.file);
        bodyFormData.append('id_doc', payload.id);
        bodyFormData.append('name', payload.name);
        bodyFormData.append('id_category', payload.category); 
        bodyFormData.append('id_product', payload.product);
        bodyFormData.append('state', payload.state);     
        bodyFormData.append('id_organization', orgId);
        try{
            let response = '';
            response = await axiosInstance.post('editproductreffile', bodyFormData,{
                headers : {
                    'Content-Type': 'multipart/form-data'
                }
            });  
            if(response.status == 200){            
                return 'The file uploaded successfully successfully!!';
            }          
        }catch(error){
            throw 'Something went wrong, please try again later';
        }
    },
    async [GET_DOCUMENT_DETAILS_ACTION](_,payload){
        let response = '';
        //let orgId = JSON.parse(localStorage.getItem('organization')).id; 
        try{
            response = await axiosInstance.get(`product-docdetails/${payload.doc_id}`);
            
            if(response.status == 200){
                return response.data.data;
            }
        }catch(error){
            throw error.response.status;
        }
    },
}