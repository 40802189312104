<template>
    <div class="item">
        <div class="boolean">
            <label :for="item.label">{{ item.label }}</label>
            <label class="switch">
                <input
                    type="checkbox"
                    @change="showExtra = !showExtra"
                />
                <span class="slider round"></span>
            </label>
        </div>
        <div v-if="item.extra && showExtra" class="extra">
            <template
                v-for="(extra, extra_index) in item.extra"
                :key="extra_index"
            >
                <template v-if="extra.type === 'text'">
                    <Normal :item="extra" @data="handleData"/>
                </template>
                <template v-else-if="extra.type === 'date'">
                    <Date :item="extra" @data="handleData"/>
                </template>
                <template v-else-if="extra.type === 'select'">
                    <Select :item="extra" @data="handleData"/>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import Normal from './Normal.vue'
import Date from './Date.vue'
import Select from './Select.vue'
export default {
    props: {
        item: {
            type: Object
        }
    },

    components: {
        Normal,
        Date,
        Select
    },

    data() {
        return {
            showExtra: false,
            extraData: {}
        }
    },

    watch: {
        showExtra(val) {
            this.extraData[this.item.name] = val
        },
        extraData: {
            deep: true,
            handler(val) {
                this.$emit('data', [this.item.name, val])
            }
        }
    },

    methods: {
        handleData(data) {
            if (Array.isArray(data[1])) {
                if (!data[1].length) delete this.extraData[data[0]];
                else this.extraData[data[0]] = data[1]
            }
            else if (typeof data[1] === 'object' && !Array.isArray(data[1])) {
                if (!Object.keys(data[1]).length) delete this.extraData[data[0]];
                else this.extraData[data[0]] = data[1]
            }
            else this.extraData[data[0]] = data[1]
        },
    },
}
</script>

<style lang="scss" scoped>
    label {
        font-weight: 500;
        font-size: 1rem;
		color: var(--primary-color);
        margin: 0;
    }
    input {
        border: solid 1px #cad6e2;
        border-radius: 2px;
        padding: 10px 15px;
    }

    .item {
        gap: 1rem;
        .boolean {
            display: flex;
            align-items: center;
            gap: .5rem;
        }

        .extra {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 0 0 0 20px;
            border-left: solid 1px #cad6e2;
        }
    }
</style>