import axios from "axios";
import store from '@/store'
import { GET_USER_TOKEN_GETTER } from "@/store/storeconstants";

const axiosInstance = axios.create({});
axiosInstance.interceptors.request.use((config) => {
    let token = store.getters[`auth/${GET_USER_TOKEN_GETTER}`];
    
    config.headers = {
        'Authorization': 'Bearer '+token,
        organization : 'default'
    }

    let url = process.env.VUE_APP_API_IP;
    config.baseURL = url;

    return config;
});

export default axiosInstance;
