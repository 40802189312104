<template>
    <section :class="{opened: isOpened}">
        <div class="title">
            {{data?.replace('-', ' ')}}
        </div>
        <div class="addiMenuPan">
            <ul class="navbar-nav">
                <template v-for="(item,index) in newItems" :key="index">
                    <template v-if="item.roles.includes(getUserProfile.role?.id || 10)">
                        <li class="nav-item" :class="{active: isActive === index}" @click="isActive = index">
                            <router-link :to="item.link" class="nav-link">
                                <span class="menuIcon">
                                    <i v-if="item.icon" :class="item.icon"></i>
                                    <img v-if="item.image" :src="item.image" :alt="item.name" />
                                </span>
                                {{item.name.toLowerCase()}}
                            </router-link>
                        </li>
                    </template>
                </template>
            </ul>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import {
    GET_USER_PROFILE_GETTER,
} from '@/store/storeconstants';
import items from '@/assets/json/sidebars.json';
import isMobile from '@/mixins/isMobile'

export default {
    props: {
        data: {
            required: false,
            type: String,
        },

        isOpened: {
            required: false,
            type: Boolean,
            default: () => true
        }
    },

    data() {
        return {
            isActive: 0,
            user: localStorage.getItem("U_P") ?  JSON.parse(localStorage.getItem("U_P")) : {}
        };
    },

    mixins: [isMobile],

    watch: {
        data() {
            this.isActive = 0
        },

        $route(to) {
            for (let i = 0; i < this.newItems.length; i++) {
                const element = this.newItems[i];
                if(to.fullPath.includes(element.link)) this.isActive = i
            }
        }
    },

    computed: {
        ...mapGetters('account', {
            getUserProfile: GET_USER_PROFILE_GETTER,
        }),
        newItems() {
            return items[this.data]
        },

        check() {
            return this.isMobile() ? false : true
        }
    },

    mounted() {
        for (let i = 0; i < this.newItems.length; i++) {
            const element = this.newItems[i];
            if(element.link === this.$route.fullPath) this.isActive = i
            if(element.relatedPaths) {
                element.relatedPaths.forEach(el => {
                if (this.$route.fullPath.includes(el)) this.isActive = i
            });
            }
        }
    }

}
</script>

<style lang="scss" scoped>
section {
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 0px;
    opacity: 0;
    transition: 0.1s;

    &.opened {
        width: 300px;
        opacity: 1;
    }

        .title {
            margin-top: 15px;
            text-transform: uppercase;
            font-size: 1.5rem;
            padding: 15px 25px;
            font-weight: bold;
            position: relative;
            display: flex;
            align-items: center;
        }

        .addiMenuPan {
            text-transform: capitalize;
        }

    .fade {
        opacity: 1;
    }


    .nav-item .nav-link .menuIcon img {
        filter: grayscale(1);
    }

    .active .nav-link .menuIcon img{
        filter: grayscale(0);
    }
}
</style>