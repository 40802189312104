<template>
  <component :is="layout">
    <router-view></router-view>
  </component>
</template>

<script>
// import { mapActions } from 'vuex'
import { AUTH_AUTO_LOGIN_ACTION } from "./store/storeconstants";

export default {
  title() {
    return `Remote Assistance`;
  },
  
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },

  async created() {
    this.$store.dispatch(`auth/${AUTH_AUTO_LOGIN_ACTION}`);
  },
};
</script>