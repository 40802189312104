<template>
    <div class="color-picker" :class="{freeze}">
        <div class="predefined">
            <template v-for="clr in color" :key="clr">
                <div class="color" :class="{active: selColor === clr}" :style="{background: clr}" @click="selColor = clr"></div>
            </template>
            <div class="picker">
                <label for="color">
                    <input class="colorInput" id="color" type="color" v-model="customColor" :style="{'--bgColor': selColor}">
                    <img src="/assets/images/colorpicker.svg" alt="">
                    <span class="clr" :style="{background: selColor}"></span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import colors from '@/assets/json/colors.json'
export default {
    props: {
        initColor: {
            required: true,
            type: String,
        },
        freeze: {
            type: Boolean,
            required: false,
            default: () => false
        },
    },
    data() {
        return {
            color: colors,
            selColor: this.initColor,
            customColor: '#efefef'
        }
    },

    watch: {
        selColor(val) {
            this.$emit('newColor', val)
        },

        customColor(val) {
            this.selColor = val
        }
    }
}
</script>

<style lang="scss" scoped>
.color-picker {
    background: rgba(0,0,0,0.3);
    border-radius: 4px;
    position: absolute;
    bottom: 65px;
    left: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    z-index: 4;
    width: 40px;
    padding: 20px 5px;
    max-height: 50%;
    overflow-y: auto;
    overflow-x: hidden;

    &.freeze {
        bottom: unset;
        top: 20px;
    }

    .predefined {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
        .color {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            cursor: pointer;
            border: solid 1px transparent;
            overflow: hidden;
            transition: 0.2s;

            &.active {
                border: solid 2px #6f97f5;
            }

            &:hover {
                border: solid 2px #1f4ebd;
            }
        }
    }

    .picker {
        display: flex;
        justify-content: center;
        label {
            position: relative;
            cursor: pointer;
            input {
                opacity: 0;
                position: absolute;
            }

            img {
                width: 20px;
                height: 20px;
                cursor: pointer;
                -webkit-user-drag: none;
                position: relative;
            }

            

            .clr {
                position: absolute;
                bottom: 2px;
                right: 2px;
                width: 8px;
                height: 8px;
                border-radius: 5px;
            }
        }
    }
}
</style>