<template>
  <div class="wrapper">
    <div class="loginWrapper">
      <div class="loginInner">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
