<template>
    <div class="separate" :class="{active: ar}">
        <img src="/assets/images/ar_icon.svg" :title="`${ar ? alerts.ar.arEnabledUserName + ' has AR ON' : 'AR is OFF'}`">
    </div>
    <div class="ar-menu">
        <template v-for="item in menu" :key="item.handler">
            <div v-if="!item.if || (item.if && item.if === 'ar' && ar === true && (!alerts.draw.drawing || alerts.draw.drawingUserId === getUserProfile.id))" class="item" :class="{active: isActive === item.handler}" @click="handler(item.handler)">
                <img :src="item.image" :alt="item.title" :title="item.title">
            </div>
        </template>
    </div>
    <ar-alerts :data="alerts"/>
    <freeze-draw v-if="freezeDraw.length" :loading="freezeLoad" :image="freezeDraw" @close="handleClose" @message="handleFromFreeze"/>
    <live-draw :isDraw="liveDraw" :room="room" />
    <ar-draw :isDraw="arDraw" :main="main" :room="room" />
</template>

<script>
import ArAlerts from './Alerts.vue'
import menu from './menu.json'
import { mapActions, mapGetters } from "vuex";
import { UPLOAD_CHAT_ATTACHMENT, GET_USER_PROFILE_GETTER } from "@/store/storeconstants";
import FreezeDraw from './FreezeDraw.vue'
import LiveDraw from './LiveDraw.vue'
import ArDraw from './ArDraw.vue'
export default {
    props: {
        room: {
            required: false,
            type: String,
        },
        main: {
            type: String
        }
    },

    emits: ["isDrawing"],

    data() {
        return {
            menu,
            freezeDraw: '',
            isActive: undefined,
            liveDraw: false,
            arDraw: false,
            ar:false,
            streamData: {},
            drawData: {},
            alerts: {
                ar: {},
                draw: {}
            },
            freezeLoad: false
        }
    },

    watch: {
        isActive(val) {
            if (!val) {
                this.liveDraw = false
            }
        },

        liveDraw(val) {
            this.$emit('isDrawing', val)
        },

        arDraw(val) {
            this.$emit('isDrawing', val)
        },
    },

    components: {
		FreezeDraw,
        LiveDraw,
        ArDraw,
        ArAlerts
	},

    computed: {
        ...mapGetters('account', {
          getUserProfile: GET_USER_PROFILE_GETTER,
        }),
    },

    methods: {
        ...mapActions("chat", {
            updateChatFile: UPLOAD_CHAT_ATTACHMENT,
        }),
        handler(value) {
            if (this.isActive !== value) this.isActive = value
            else this.isActive = undefined

            const canvas = document.querySelector('.main') ? document.querySelector('.main').children[1] : document.querySelector('.default').children[1]

            if (value === 'arDraw') {
                let toSend = this.streamData
                this.arDraw = !this.arDraw
                if (this.arDraw) {
                    toSend.drawing = true
                    toSend.drawingUserId = this.getUserProfile.id
                    toSend.drawingUserName = this.getUserProfile.name
                }
                else {
                    toSend.drawing = false
                    toSend.drawingUserId = 0
                    toSend.drawingUserName = ''
                }
                this.emitter.emit('setConfigForCall', toSend)
            }
            if (value === 'liveDraw') this.liveDraw = !this.liveDraw

            if(this.isActive) {
                switch (value) {
                    case 'freezeDraw':
                        this.handleFreezeDraw()
                        break;
                    case 'delete':
                        this.emitter.emit("live-draw", {
                            room: this.room,
                            toClear: true,
                            stream_id: canvas.getAttribute('id')
                        });
                        this.isActive = undefined
                        break;

                    default:
                        break;
                }
            }
        },

        handleFreezeDraw() {
            const video = document.querySelector('.main') ? document.querySelector('.main').children[0] : document.querySelector('.default').children[0]
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = video.offsetWidth
            canvas.height = video.offsetHeight

            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            this.freezeDraw = canvas.toDataURL('image/png')
        },

        handleClose() {
            this.freezeDraw = ''
            this.isActive = undefined
        },

        async handleFromFreeze(data) {
            this.freezeLoad = true
            this.freezeDraw = ''
            this.isActive = undefined
            let params = {
                    attachment: [data.image],
                    text: data.text || '',
                    room: this.room,
            };

            await this.updateChatFile(params).finally(() => this.freezeLoad = false)
        }
    },

    mounted() {
        window.addEventListener('keyup', (e) => {
            if(e.keyCode === 27) this.isActive = undefined
        })

        this.emitter.on("receive-ar-config", (data => {
            if(data.roomId === this.room) {
                this.streamData = data
                this.ar = data.arEnable
                this.drawData = {
                    drawing: data.drawing,
                    drawingColor: data.drawingColor,
                    drawingUserId: data.drawingUserId,
                    drawingUserName: data.drawingUserName
                }

                this.alerts = {
                    ar: {
                        arEnable: data.arEnable,
                        arEnableUserId: data.arEnableUserId,
                        arEnabledUserName: data.arEnabledUserName
                    },
                    draw: this.drawData
                }
            }
        }))
    }
}
</script>

<style lang="scss" scoped>
.separate {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10%;
    left: 10px;
    background: #051524;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;

    img {
        width: 60%;
        height: 60%;
    }

    &.active {
        background: var(--primary-color);
    }
}

.ar-menu {
    position: absolute;
    top: 17%;
    height: 100%;
    left: 10px;
    z-index: 4;
    display: flex;
    flex-direction: column;
    gap: 2px;
    border-radius: 5px;
    overflow: hidden;
    transition: 0.2s;
    .item {
        min-width: 30px;
        width: 5%;
        height: 15%;
        max-height: 30px;
        background: #051524;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.2s;
        &.active {
            background: rgba(0,0,0,0.7);
            &:hover {
                background: rgba(0,0,0,0.3);
            }
        }
        &:hover {
            background: rgba(255,255,255,0.3);
        }
        img {
            width: 60%;
            height: 60%;
        }
    }
}
</style>