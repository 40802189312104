<template>
    <main>
        <section>
            <header>
                <div class="title">
                    <span>QrCode Login</span>
                </div>
                <div class="out" @click="$emit('close')">X</div>
            </header>
            <article class="qrCode">
                <template v-if="loaded">
                    <figure>
                        <img :src="qrCodeData" alt="">
                    </figure>
                    <div class="info">
                        <span>{{user.name}}</span>
                    </div>
                </template>
                <div v-else class="loading">
                    <div class="loader"></div>
                </div>
            </article>
        </section>
    </main>
</template>

<script>
import axiosInstance from '@/services/AxiosTokenInstance';

export default {
    data() {
        return {
            qrCodeData: undefined,
            loaded: false,
            user: localStorage['U_P'] ? JSON.parse(localStorage['U_P']) : {},
        }
    },

    mounted() {
        this.getQr()
    },

    methods: {
        async getQr() {
            const response = await axiosInstance.get(`generate-login-barcode`).finally(() => this.loaded = true)
            if(response.status === 200) this.qrCodeData = `data:image/webp;base64,${response.data.data.barcode}`
        }
    }
}
</script>

<style lang="scss" scoped>
main {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(100,100,100,0.4);
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;

    section {
        background: white;
        width: 95%;
        max-width: 550px;
        height: fit-content;
        max-height: 800px;
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: relative;

        header, form, footer {
            padding: 20px;
        }

        header {
            height: 10%;
            box-shadow: 0px 1px 6px #e0e0e0;
            border-bottom: 1px solid #eeeeee;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: sticky;
            top: 0;

            div {
                font-weight: 600;
                font-size: 1.3rem;
            }

            .out {
                user-select: none;
                cursor: pointer;
                transition: 0.2s;

                &:hover {
                    color: grey;
                }
            }
        }

        article {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 40px;

            figure {
                width: 100%;
                height: 50%;
                max-height: 250px;
                padding: 40px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 100%;
                    height: 100%;
                    max-width: 250px;
                    max-height: 250px;
                    object-fit: contain;
                }
            }

            .info {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: .5rem;
            }
        }
    }
}
</style>