import axiosInstance from "@/services/AxiosTokenInstance";
import {    
    CREATE_CATEGORY_ACTION,
    GET_CATEGORY_DETAILS_ACTION,
    UPDATE_CATEGORY_ACTION
} from "@/store/storeconstants";

export default{
    async [CREATE_CATEGORY_ACTION](_,payload){
        let orgId = JSON.parse(localStorage.getItem('organization')).id;        
        let bodyFormData = {
            'name': payload.name,
            'description': payload.description,
            'id_organization':orgId,
            'parent': payload.parent,
            'status':payload.status,
        };
        try{
            let response = '';
            response = await axiosInstance.post('addcategory', bodyFormData);  
            if(response.status == 200){ 
                return response.data.data.category_id;
            }
        }catch(error){
            throw 'Something went wrong, please try again later';
        }
    },

    // async [ADD_USERS_TO_SITE_ACTION](_,payload){   
    //     let arrayObj = payload.users;
    //     arrayObj = arrayObj.map(item => {
    //         return {
    //             id_user: item.id,
    //         };
    //         });
    //     let bodyFormData = {
    //         'users': [...arrayObj],
    //         'id_project': payload.project_id
    //     };
    //     try{
    //         let response = '';
    //         response = await axiosInstance.post('addprojectuser', bodyFormData);  
    //         if(response.status == 200){     
    //             //The site or project id should return here       
    //             return 'The site has been created successfully!!';
    //         }
    //     }catch(error){
    //         throw 'Something went wrong, please try again later';
    //     }
    // },
    async [UPDATE_CATEGORY_ACTION](_,payload){
        let response = '';
        //let orgId = JSON.parse(localStorage.getItem('organization')).id;        
        let bodyFormData = {
            'name': payload.name,
            'description': payload.description,
            'parent': payload.parent,
            'status':payload.status,
            'id_category':payload.id,
        };
        try{
            response = await axiosInstance.post(`editcategory`, bodyFormData);
            if(response.status == 200){
                return response.data.data;
            }
        }catch(error){
            throw error.response.status;
        }
    },
    async [GET_CATEGORY_DETAILS_ACTION](_,payload){
        let response = '';
        //let orgId = JSON.parse(localStorage.getItem('organization')).id; 
        try{
            response = await axiosInstance.get(`show-category/${payload.category_id}`);
            if(response.status == 200){
                return response.data.data;
            }
        }catch(error){
            throw error.response.status;
        }
    },
}