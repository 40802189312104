<template>
    <main  :class="{show}">
        <span class="overlay" />
        <section>
            <header>
                <h2>Disconnect Warning</h2>
            </header>
            <div class="body">
                <div class="text">
                    <span v-if="!rejectedCall">You have been alone in this call for more than <strong>3 minutes</strong>.</span>
                    <span v-else>Your call has been rejected.</span>
                    <span>Want to disconnect or keep waiting?</span>
                </div>
                <div class="timer">
                    <div class="bar">
                        <div class="inner" />
                        <span>{{seconds}}</span>
                    </div>
                </div>
            </div>
            <footer>
                <span @click="close(1)">Keep Waiting</span>
                <span class="disconnect" @click="close(0)">Disconnect</span>
            </footer>
        </section>
    </main>
</template>

<script>
export default {

    props: {
        rejectedCall: {
            type: Boolean,
            required: false,
            default: () => false
        }
    },

    data() {
        return {
            seconds: 30,
            timeout: null,
            show: false
        }
    },

    watch: {
        seconds(val) {
            if (!val) {
                clearInterval(this.timeout)
                this.close(0)
            }
        }
    },

    mounted() {
        this.timeout = setInterval(() => {
            this.seconds -= 1
        }, 1000);

        setTimeout(() => {
            this.show = true
        }, 50);
    },

    methods: {
        close(keep) {
            this.show = false
            setTimeout(() => {
                this.$emit('close', keep)
            }, 200);
            
        }
    }
}
</script>

<style lang="scss" scoped>
    main {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;

        &.show {
            .overlay {
                opacity: 1;
            }

            section {
                opacity: 1;
                transform: translateY(0px);
            }
        }

        .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.4);
            z-index: 5;
            opacity: 0;
            transition: 0.2s ease-in-out;
        }

        section {
            width: 90%;
            max-width: 30rem;
            background: white;
            border-radius: 4px;
            z-index: 10;
            opacity: 0;
            transform: translateY(50px);
            transition: 0.2s ease-in-out;

            header {
                width: 100%;
                padding: 10px;
                border-bottom: solid 1px $grey;
                h2 {
                    font-weight: 600;
                    font-size: 1.2rem;
                    margin: 0;
                }
            }

            .body {
                padding: 10px;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .text {
                    display: flex;
                    flex-direction: column;
                    gap: 0.2rem;
                }

                .timer {
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    .bar {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        border: solid 3px $grey;
                        box-shadow: 0px 0px 5px rgba(0,0,0,0.4);
                        position: relative;

                        .inner {
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            border: solid 0px;
                            border-color: var(--primary-color);
                            position: absolute;
                            z-index: -1;
                            animation: pulse 1s infinite ease-in-out;
                            animation-delay: 0.5s;
                        }

                    }
                }
            }

            footer {
                width: 100%;
                display: flex;
                
                span {
                    padding: 10px;
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    border: solid 1px;
                    border-color: transparent;
                    background: var(--primary-color);
                    color: white;
                    border-top-left-radius: 4px;
                    transition: 0.2s;
                    cursor: pointer;
                    user-select: none;

                    &:hover {
                        background: var(--primary-hover);
                    }

                    &.disconnect {
                        border-top-left-radius: 0px;
                        border-top-right-radius: 4px;
                        background: $red;
                        border-bottom: none;

                        &:hover {
                            background: rgba($red, 0.9);
                        }
                    }
                }
            }
        }
    }

    @keyframes pulse {
        0% {
            border-width: 0px;
        }

        50% {
            border-width: 6px;
        }

        100% {
            border-width: 0px;
        }
    }
</style>