<template>
    <main>
        <div class="group">
            <div class="title">congratulations!</div>
            <div class="subtitle">Registration completed successfully!</div>
        </div>
        <div class="group">
            <div class="description">
                <span>Now that you are registered,</span>
                <span>sign in to join your team</span>
            </div>
            <div class="sign-in" @click="goToLogin">Sign in</div>
        </div>
    </main>
</template>

<script>
export default {
    methods: {
        goToLogin() {
            this.$router.push('/');
        }
    }
}
</script>

<style lang="scss" scoped>
    main {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }

    main .group {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    main .group .title{
        font-weight: bold;
        font-size: 3.5rem;
        text-transform: uppercase;
    }
    main .group .subtitle{
        font-weight: 500;
        font-size: 1.2rem;
    }
    main .group .description{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }
    main .group .description span{
        font-weight: 400;
        font-size: 1.2rem;
    }
    main .group .sign-in {
        margin-top: 10px;
        background: var(--primary-color);
        color: white;
        border-radius: 30px;
        padding: 10px 80px;
        cursor: pointer;
        transition: 0.2s;
    }
    main .group .sign-in:hover{
        background: var(--primary-hover);
    }
</style>