<template>
	<video autoplay/>
</template>

<script>
export default {
	props: {
		streamManager: Object,
	},
	watch: {
		streamManager(val) {
			val.addVideoElement(this.$el);
		}
	},
	mounted () {
		this.streamManager.addVideoElement(this.$el);
		this.$emit('mounted')
	},
};
</script>

<style lang="scss" scoped>
video {
	max-height: 100%;
	max-width: 100%;
	height: 100%;
}
</style>