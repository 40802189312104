<template>
    <div class="notifications" v-click-outside="handleOutside" @scroll="handleScroll">
        <header>
            <div class="mark-as-read" @click="readAllNofis">
                <figure>
                    <img src="/assets/images/read.svg" alt="">
                </figure>
                <span>Mark All as Read</span>
            </div>
        </header>
        <section v-if="filteredNotifications.length">
            <template v-for="(notif, index) in filteredNotifications" :key="index">
                <article :class="{unread: !notif.read}" @click="handleNotif(index)">
                    <span>{{notif.title}}</span>
                </article>
            </template>
        </section>
        <div class="no-notifs" v-else>
            <span>Your notification box is clear.</span>
        </div>
    </div>
</template>

<script>
import nodeInstance from '@/services/NodeServiceinstance'
export default {
    props: {
        notifications: {
            type: Array,
            required: true
        }
    },
    
    data() {
        return {
            filteredNotifications: this.notifications,
            org: localStorage.organization ? JSON.parse(localStorage.organization) : {},
        }
    },

    methods: {
        async handleNotif(index) {
            const route = this.filteredNotifications[index].to.split('?')
            const query = route[1] ? route[1].split('=') : []
            
            this.$router.push({path:route[0], query: {
                    [query[0]]: query[1]
                }
            })

            await nodeInstance.put(`/notifications/read/${this.filteredNotifications[index].notifId}`)

            this.filteredNotifications
                .filter(el => el.roomId === this.filteredNotifications[index].roomId)
                .forEach(el => el.read = true);

            this.$emit('getUnreads')

            
        },

        async readAllNofis() {
            await nodeInstance.put(`/notifications/readAll/${this.org.id}`)

            this.$emit('getUnreads')
        },

        handleOutside() {
            this.$emit('close')
        },

        handleScroll(event) {
            const element = event.target
            const isBottom = element.scrollTop === (element.scrollHeight - 400)
            if(isBottom) this.$emit('loadMore')
        }
    }
}
</script>

<style lang="scss" scoped>
.notifications {
    position: absolute;
    right: 0;
    max-height: 25rem;
    width: 20rem;
    z-index: 5;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 1px 6px #CAD6E280;
    overflow: auto;

    header {
        border-bottom: solid 1px $grey;
        padding: 10px 16px;
        display: flex;
        // justify-content: center;

        .mark-as-read {
            display: flex;
            align-items: center;
            cursor: pointer;
            user-select: none;
            width: fit-content;
            padding: 4px;
            border-radius: 4px;
            transition: 0.2s;

            &:hover {
                background: rgba(0,0,0,0.1);
            }

            figure {
                width: 16px;
                height: 16px;

                img {
                    width: 100%;
                }
            }

            span {
                margin-left: 8px;
                font-size: 12px;
                font-weight: 600;
                color: var(--primary-color);
            }
        }
    }

    section {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;
        
        article {
            padding: 10px 20px;
            border-bottom: solid 1px #CAD6E280;
            cursor: pointer;
            transition: 0.2s;
            &:hover {
                background: #CAD6E280;
            }

            &.unread {
                background: #0072FFce;
                color: white;
                border-bottom: solid 1px white;
                transition: 0.2s;
                &:hover {
                    background: var(--primary-color);
                }
            }

            span {
                font-size: 0.8rem;
                user-select: none;
            }
        }
    }

    .no-notifs {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.7rem;
        text-align: center;
        color: #CAD6E2;
        padding: 20px;
        user-select: none;
    }
}
</style>