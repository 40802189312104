<template>
    <div class="item">
        <label>
            <span>{{item.label}}</span>
			<span v-if="item.required"> *</span>
        </label>
        <textarea  :placeholder="`Insert ${item.label}`" v-model="data" :required="item.required">
        </textarea>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object
        }
    },

    data() {
        return {
            data: ''
        }
    },

    watch: {
        data(val) {
            this.$emit('data', [this.item.name, val])
        }
    }
}
</script>

<style lang="scss" scoped>
    label {
        font-weight: 500;
        font-size: 1rem;
		color: var(--primary-color);
    }
    textarea {
        border: solid 1px #cad6e2;
        border-radius: 2px;
        padding: 10px 15px;
        resize: none;
        height: 10rem;

        &:focus {
            outline: none
        }
    }
</style>