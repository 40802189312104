<template>
    <div class="item">
        <div class="boolean">
            <label :for="item.label">
                <span>{{ item.label }}</span>
                <span v-if="item.required"> *</span>
            </label>
            <span  class="message" v-html="item.message" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object
        }
    },

    data() {
        return {
            value: 2
        }
    },

    watch: {
        value: {
            immediate: true,
            handler(val) {
                this.$emit('data', [this.item.name, val])
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    label {
        font-weight: 500;
        font-size: 1rem;
		color: var(--primary-color);
        margin: 0;
    }
    input {
        border: solid 1px #cad6e2;
        border-radius: 2px;
        padding: 10px 15px;
    }

    .item {
        gap: 1rem;
        .boolean {
            display: flex;
            flex-direction: column;
            gap: .5rem;
        }
    }
</style>