<template>
    <div class="devices">
        <main v-click-outside="handleOutside">
            <aside>
                <dl>
                    <dt>Devices</dt>
                </dl>
            </aside>
            <section>
                <div class="item">
                    <label>Video</label>
                    <select v-model="selectedVideo">
                        <option :value="undefined">Choose a Device</option>
                        <template v-for="(option, i) in devices.filter(device => device.kind === `videoinput` && device.deviceId !== 'default' && device.deviceId !== 'communications')" :key="i">
                            <option :value="option.deviceId">{{option.label}}</option>
                        </template>
                    </select>
                </div>
                <div class="item">
                    <label>Audio</label>
                    <select v-model="selectedAudio">
                        <option :value="undefined">Choose a Device</option>
                        <template v-for="(option, i) in devices.filter(device => device.kind === `audioinput` && device.deviceId !== 'default' && device.deviceId !== 'communications')" :key="i">
                            <option :value="option.deviceId">{{option.label}}</option>
                        </template>
                    </select>
                </div>
                <div class="submit" @click="submitChanges">
                    Save Changes
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import { OpenVidu } from 'openvidu-browser';
export default {
    props: {
        currentDevices: {
            type: Object,
            default: () => {}
        }
    },

    data() {
        return {
            OV: undefined,
            devices: [],
            selectedAudio: this.currentDevices?.audio,
            selectedVideo: this.currentDevices?.video,
        }
    },

    created() {
        this.OV = new OpenVidu();
        this.getDevices()
    },

    methods: {
        async getDevices() {
            this.devices = await this.OV.getDevices()
        },

        handleOutside() {
            this.$emit('close')
        },

        submitChanges() {
            this.$emit('changeDevices', {
                audio: this.selectedAudio,
                video: this.selectedVideo
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .devices {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.4);
        top: 0;
        left: 0;
        z-index: 200;
        display: flex;
        justify-content: center;
        align-items: center;

        main {
            width: 90%;
            max-width: 500px;
            background: white;
            display: flex;
            border-radius: 4px;
            overflow: hidden;

            aside {
                width: 8rem;
                border-right: solid 1px rgba(0,0,0,0.1);
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                dl {
                    display: flex;
                    flex-direction: column;

                    dt {
                        padding: 10px;
                        transition: 0.2s;
                        cursor: pointer;
                        user-select: none;
                        background: var(--primary-color);
                        color: white;
                    }
                    
                }
            }

            section {
                width: calc(100% - 8rem);
                padding: 20px;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .item {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;

                    select {
                        border: solid 2px;
                        border-color: transparent transparent transparent var(--primary-color);
                        padding: 5px;

                        &:focus {
                            outline: none;
                        }
                    }
                    select option {
                        box-shadow: none;
                        margin: 40px;
                        background: white;
                        border-radius: 0px;
                    }
                }

                .submit {
                    background: var(--primary-color);
                    color: white;
                    padding: 5px 20px;
                    width: fit-content;
                    user-select: none;
                    cursor: pointer;
                }
            }
        }
        
    }
</style>