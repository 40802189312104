import axiosInstance from '@/services/AxiosTokenInstance';

export default{
    async storeAnalytics(state, payload){
        const response = await axiosInstance.post('analytics', payload)
        return response
    },
    async getAnalytics(state, payload){
        const org = localStorage.organization ? JSON.parse(localStorage.organization) : {}
        let extra = ''
        payload.extras?.forEach(element => extra += `&${element[0]}=${element[1]}`);
        if (payload.group) extra += `&group_by=${payload.group.join(',')}`
        const response = await axiosInstance.get(`analytics?action=${payload.action}${extra}&environment=${process.env.VUE_APP_ENVIRONMENT}&org_id=${org.id}&limit=10`)
        return response
    },
}