<template>
    <main v-if="organizationList.length">
        <div class="title" v-if="!clientList.length">Switch Organisation</div>
        <div class="title" v-else>Switch Organisation/Client</div>
        <section>
            <label>Organization</label>
            <select v-model="organization_id">
                <option value="">Select an Organization</option>
                <option v-for="organization in organizationList" :key="organization.organization.id" :value="organization.organization.id">
                    {{ organization.organization.name }}
                </option>
            </select>
        </section>
        <section v-if="clientList.length && !orgRoles.includes(getUserProfile.role.id)">
            <label>Client</label>
            <select v-model="client_id">
                <option value="">Select a Client</option>
                <option v-for="client in clientList" :key="client.id" :value="client.id">
                    {{ client.name }}
                </option>
            </select>
        </section>
        <span class="confirm" @click="submitChanges">Confirm</span>
    </main>
    <main v-else>
      <div class="loading">
        <span class="loader"></span>
      </div>
    </main>
</template>

<script>
import axiosInstance from "@/services/AxiosTokenInstance";
import { mapGetters } from 'vuex'
import { GET_USER_PROFILE_GETTER } from '@/store/storeconstants'
export default {
    data() {
        return {
            organization_id: '',
            client_id: '',
            organizationList: [],
            clientList: [],
            currentOrg: JSON.parse(localStorage.getItem('organization')).id,
            orgRoles: [4,5,6,7,8]
        }
    },

    watch: {
        client_id: {
            immediate: true,
            handler() {
                if(!this.organization_id) this.client_id = JSON.parse(localStorage.getItem('client')) ? JSON.parse(localStorage.getItem('client')).id : ''
                else this.fetchClients()
            }
        },
        organization_id: {
            immediate: true,
            handler(val) {
                if(!val) this.organization_id = this.currentOrg
                else {
                    if (val !== this.currentOrg) this.client_id = ''
                    else {
                        this.client_id = JSON.parse(localStorage.getItem('client')) ? JSON.parse(localStorage.getItem('client')).id : ''
                        this.fetchClients()
                    }
                    
                }
            }
        },
    },

    computed: {
        ...mapGetters('account', {
          getUserProfile: GET_USER_PROFILE_GETTER,
        }),
    },

    methods: {
        async fetchOrgs() {
            const organizations = await axiosInstance.get('organizations-list')
            this.organizationList = organizations.data.data
        },
        async fetchClients() {
            const org = this.organization_id;
            const clients = await axiosInstance.get(`/new-org-client-list-all?org_id=${org}&user_client=1`)
            this.clientList = clients.data.data
        },

        submitChanges() {
            const org = this.organizationList.find(org => {
				return org.organization.id === this.organization_id;
			})
            localStorage.setItem('organization', JSON.stringify(org.organization));

            if (!this.client_id && this.currentOrg !== org.id) {
                this.$router.push('/data-structure')
                
                setTimeout(() => {
                    location.reload();
                }, 100);
                return
            }

            const client = this.clientList.find(client => {
                return client.id === this.client_id;
            })
            localStorage.setItem('client', JSON.stringify(client));

            if (this.currentOrg !== org.id && !this.client_id) {
                localStorage.removeItem('client');
                this.$router.push('/clients')
            }
            else {
                this.$router.push('/data-structure')
                
                setTimeout(() => {
                    location.reload();
                }, 100);
            }
        }
    },

    mounted() {
        this.fetchOrgs()
    },
}
</script>

<style lang="scss" scoped>
main {
    position: absolute;
    left: 2rem;
    width: 20rem;
    background: white;
    color: black;
    z-index: 1;
    box-shadow: 0px 1px 6px #cad6e280;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 0.8rem;
    transition: 0.2s;
    min-height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .loading {
        height: unset;
    }
    .title {
        font-size: 1rem;
        font-weight: bold;
    }
    section {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        label {
            font-weight: 500;
            margin: 0;
        }
        select {
            width: inherit;
            border: solid 1px #F2F5F8;
            border-radius: 5px;
            padding: 5px 10px;
            &:focus {
                outline: none;
            }

        }
    }
    .confirm {
        padding: 10px 20px;
        background: var(--primary-color);
        color: white;
        border-radius: 5px;
        user-select: none;
    }
}
</style>